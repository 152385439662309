import { Component, OnInit } from '@angular/core';
import { FeedMorStarApi } from '../../core/model';
import { FeedMorStarApiService } from '../../pages/custom/decide-mutual-fund/feedMorStarApis.service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {

  constructor(private helperService: HelperService) { }

  ngOnInit() {

  }
  navigateOnboarding(){

  }

}
