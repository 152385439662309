import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BseRegistration } from '../../../core/model';
import { BseRegistrationsService as service } from '../../user/bseRegistrations/bseRegistrations.service';



@Injectable({
    providedIn: 'root'
})

export class BseRegistrationsService extends service {
  draft(bseRegistration: BseRegistration) {
    return this.http.post<any>(this.apiUrl + 'Draft', bseRegistration)
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }
}
