import { Injectable } from '@angular/core';
import { ClientInvestmentPlatter } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ClientInvestmentPlattersService extends BaseService<ClientInvestmentPlatter> {
    constructor() {
        super('ClientInvestmentPlatter');
    }
}
