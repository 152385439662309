import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, DialogService } from 'primeng';
import { EdExpense } from '../../core/model/edExpense';
import { HelperService } from '../../shared/helperService';
import { ListComponent } from '../../shared/baseListComponent';

import { EdExpensesListComponent  as CustomComponent } from '../../pages/user/edExpenses/edExpenses-list/edExpenses-list.component';
@Component({
  selector: 'app-table-head',
  templateUrl: './table-head.component.html',
  styleUrls: ['./table-head.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class TableHeadComponent extends CustomComponent {

  // constructor() { }

  ngOnInit(): void {
  }

}
