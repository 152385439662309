import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';

import { FeedMorStarApi } from '../../../core/model';
import { BaseService } from '../../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class FeedMorStarApiService extends BaseService<FeedMorStarApi> {
  constructor() {
    super('FeedMorStarApi');
  }

  searchMF(search) {
    return this.http.post<FeedMorStarApi[]>(this.apiUrl + 'SearchMF', {condition : search})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  getNavHistory(search) {
    return this.http.post<FeedMorStarApi[]>(this.apiUrl + 'NavHistory', {condition : search})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

}
