import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { ExportAsModule } from 'ngx-export-as';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { EnvServiceProvider } from 'src-share';
//import { EnvServiceProvider } from 'src-share';
//import { EnvServiceProvider } from 'src-share/public-api';
import { EnvServiceProvider } from './core/env.service.provider';
import { AuthInterceptor } from './shared/auth.interceptor';
import { FaqDialogComponent } from './components/faq-dialog/faq-dialog.component';
import { SharedModule } from './shared/shared.module';
import { MessageService } from 'primeng';

import { CommonModule } from '@angular/common';
import { GoogleLoginProvider } from 'angularx-social-login';
import { HeaderComponent } from './components/header/header.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { BseRegistrationsModule } from './pages/custom/bseRegistrations/bseRegistrations.module';
import { UsersModule } from './pages/custom/users/users.module';
import { RedeemModule } from './pages/custom/redeem/redeem.module';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    ExportAsModule,
    CommonModule,
    NgxHideOnScrollModule,
    BseRegistrationsModule,
    UsersModule,
    RedeemModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  providers: [EnvServiceProvider,

    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }],
  declarations: [AppComponent, HeaderComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
