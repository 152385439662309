import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { DialogService } from 'primeng';
import { BseMFDetailsWhitelistService } from '../../pages/custom/mf-order-booking/bseMFDetails.service';
import { ActivatedRoute } from '@angular/router';
import { FeedMorStarApi } from '../../core/model';

@Component({
  selector: 'app-mf-list-gravity',
  templateUrl: './mf-list-gravity.component.html',
  styleUrls: ['./mf-list-gravity.component.scss'],
  providers: [DialogService]
})
export class MfListGravityComponent implements OnInit, OnChanges {
  isPageLoaded = false;
  ruleList: any[];
  list: FeedMorStarApi[];
  @Input() type = '';
  @Input() strategyRandomId = '';


  whiteList: FeedMorStarApi[];
  constructor(public dialogService: DialogService, private route: ActivatedRoute,
    private bseMFDetailsWhitelistService: BseMFDetailsWhitelistService) {
    this.bseMFDetailsWhitelistService.getAll().subscribe((data: FeedMorStarApi[]) => {
      this.whiteList = data;
      //console.log('bseMFDetailsWhitelistService', this.whiteList);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isPageLoaded) {
      if (changes.type) {
        this.list = this.whiteList.filter(a => a.categoryName.toLocaleLowerCase() === this.type.toLocaleLowerCase());
        this.bindData();
      }
    }

  }


  ngOnInit() {
    //const type: string = this.route.snapshot.params['type'];
    setTimeout(() => {
      this.bindData();
    }, 300);
  }

  bindData() {
    //console.log('this.type', this.type);
    // this.type = "Ultra Short Duration";
    if (this.whiteList && this.whiteList.length) {
      this.list = this.whiteList.filter(a => a.categoryName.toLocaleLowerCase() === this.type.toLocaleLowerCase());
      //console.log('this.type list', this.type, this.list);
    }else{
      setTimeout(() => {
        this.bindData();
      }, 300);
    }

  }

  showFund(searchL) {

  }

  getCategoryName(type){

  }

}
