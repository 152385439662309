import { Component, OnInit } from '@angular/core';
import { FeedMorStarApi } from '../../core/model';
import { FeedMorStarApiService } from '../../pages/custom/decide-mutual-fund/feedMorStarApis.service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-home-onboarding',
  templateUrl: './home-onboarding.component.html',
  styleUrls: ['./home-onboarding.component.scss'],
})
export class HomeOnboardingComponent implements OnInit {

  constructor(private helperService: HelperService) { }

  ngOnInit() {

  }
  navigateOnboarding() {
    this.helperService.routeNavigate('onboarding');
  }
}
