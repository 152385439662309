import { Injectable } from '@angular/core';
import { BseMFDetail } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class BseMFDetailsService extends BaseService<BseMFDetail> {
    constructor() {
        super('BseMFDetail');
    }
}
