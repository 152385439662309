import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet, MultiDataSet } from 'ng2-charts';
import { FinanceProfile, Foundation } from '../../core/model';
import { FoundationPageService } from '../../pages/custom/foundation/foundation.page.service';
import { FoundationProfilePageService } from '../../pages/custom/foundation/foundation.profile.page.service';
import { BseMFDetailsWhitelistService } from '../../pages/custom/mf-order-booking/bseMFDetails.service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-home-foundation',
  templateUrl: './home-foundation.component.html',
  styleUrls: ['./home-foundation.component.scss'],
})
export class HomeFoundationComponent implements OnInit {
  foundation: Foundation;
  foundationProfile: FinanceProfile;
  loadComplete = false;
  isTooltipVisible = true;


  constructor(private helperService: HelperService,
    public bseMFDetailsWhitelistService: BseMFDetailsWhitelistService,
    public foundationPageService: FoundationPageService,
    public foundationProfilePageService: FoundationProfilePageService) { }

  ngOnInit() {


    this.bseMFDetailsWhitelistService.getAll().subscribe((data: any[]) => {

    });

    this.getFoundationData();
    this.foundationPageService.foundationUpdate.subscribe(loginType => {
      this.getFoundationDataReload();
    });
    this.helperService.loginChange.subscribe(loginType => {
      this.getFoundationDataReload();
    });
  }

  getFoundationDataReload() {
    this.foundationProfilePageService.refreshData(true).subscribe((foundationProfile: FinanceProfile[]) => {
      this.foundationProfile = foundationProfile.length > 0 ? foundationProfile[0] : null;
      if (this.foundationProfile) {
        this.foundationPageService.refreshData(true).subscribe((foundation: Foundation[]) => {
          this.foundation = foundation.length > 0 ? foundation[0] : null;
          this.loadComplete = true;
        });
      } else {
        this.loadComplete = true;
      }
    });
  }

  getFoundationData() {
    this.foundationProfilePageService.getAll(true).subscribe((foundationProfile: FinanceProfile[]) => {
      this.foundationProfile = foundationProfile.length > 0 ? foundationProfile[0] : null;
      if (this.foundationProfile) {
        this.foundationPageService.getAll(true).subscribe((foundation: Foundation[]) => {
          this.foundation = foundation.length > 0 ? foundation[0] : null;
          this.loadComplete = true;
        });
      } else {
        this.loadComplete = true;
      }
    });
  }

  navigate(url) {
    this.helperService.routeNavigate(url);
  }

  hideTooltip():void {
    this.isTooltipVisible = false;
  }

}
