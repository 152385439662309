import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HelperService {
  searchHistory = [];
  loggedInUser = null;
  token = '';
  tokenName = 'token';
  tokenInfo = 'tokenInfo';
  routeData: any = null;
  public globalLoading = false;
  public loginChange = new Subject<string>();
  public backButtonClick = new Subject<string>();
  public backHardwareClick = new Subject<string>();
  public loggedInUserComplete = new Subject<Object>();
  urlNavigation = '';
  redirectToOnboarding = false;

  constructor(public router: Router) {
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        // Perform actions
        var backData = this.getHistoryBackDataType();
        this.backHardwareClick.next();
        if(backData){
          this.backButtonClick.next(backData);
        }
      }
    });
    router.events.subscribe((event: NavigationEnd) => {
      this.backHardwareClick.next();
    });
  }

  clearInfo() {
    localStorage.removeItem(this.tokenInfo);
    localStorage.removeItem(this.tokenName);
    this.loggedInUser = null;
  }

  logout() {
   // this.socialAuthService.signOut();
    localStorage.removeItem(this.tokenInfo);
    localStorage.removeItem(this.tokenName);
    this.loggedInUser = null;
    this.loginChange.next('logout');
    window.location.href='https://app.bonvista.in';
    //this.routeNavigate('home');

    // setTimeout(() => {
    //   window.location.reload();
    // }, 50);

  }
  getCurrentRouteUrl() {
    return this.router.url;
  }
  routeNavigate(path) {
    //path = path.replace('wbHealths', 'wealth-bag/health');
    // console.log('this.loggedInUser', this.loggedInUser.isAdmin);
if(!this.isAdminUser){
    switch (path) {
      case 'wbHealths':
        path = 'wealth-bag/health';
        break;
      case 'wbInsurances':
        path = 'wealth-bag/insurance';
        break;
      case 'wbHomes':
        path = 'wealth-bag/home';
        break;
      case 'wbLiabilities':
        path = 'wealth-bag/liability';
        break;
      case 'wbPersonalAccidents':
        path = 'wealth-bag/accident';
        break;
      case 'wbVehicles':
        path = 'wealth-bag/vehicle';
        break;
      case 'wbLifeInsurances':
        path = 'wealth-bag/life-insurance';
        break;
    }
  }
    if (this.isAdminUser) {
      if (path.indexOf('admin/') < 0) {
        this.router.navigate(['./' + 'admin/' + path]);
      } else {
        this.router.navigate(['./' + path]);
      }

    } else {
      this.router.navigate(['./' + path]);
    }

  }


  // getStorageValue(keyName) {

  //   localStorage.getItem(keyName).then((val) => {
  //     return val;
  //   });
  // }
  getLoggedInUserInfo() {
    if (this.loggedInUser) {
      this.loggedInUserComplete.next(this.loggedInUser);
     // console.log('this.loggedInUser', this.loggedInUser,this.tokenName);
      return this.loggedInUser;
    } else {
     const val=localStorage.getItem(this.tokenInfo);
        if (val) {
          this.token= localStorage.getItem(this.tokenName);
          try {

            const userInfo = JSON.parse(atob(val));
            this.loggedInUser = userInfo[0];
            this.loggedInUserComplete.next(this.loggedInUser);
            return this.loggedInUser;
          } catch (ex) {
            // localStorage.removeItem(this.tokenInfo);
            // localStorage.removeItem(this.tokenName);
            // this.storage.remove(this.tokenInfo);
            // this.storage.remove(this.tokenName);
            //this.router.navigate(['home']);
          }
        } else {
          // localStorage.removeItem(this.tokenInfo);
          // localStorage.removeItem(this.tokenName);

          //this.router.navigate(['home']);
        }

      // if (localStorage.getItem(this.tokenInfo)) {
      //   try {

      //     const userInfo = JSON.parse(atob(localStorage.getItem(this.tokenInfo)));
      //     this.loggedInUser = userInfo[0];
      //    // console.log('parsed this.loggedInUser', this.loggedInUser,this.tokenName);
      //     return this.loggedInUser;
      //     //console.log('this.loggedInUser', this.loggedInUser);
      //   } catch (ex) {
      //     localStorage.removeItem(this.tokenInfo);
      //     localStorage.removeItem(this.tokenName);
      //   }
      // } else {
      //   console.log('parsed', this.loggedInUser);
      //   localStorage.removeItem(this.tokenName);
      // }
    }
    return null;
  }

  setLoggedInUserInfo(userInfo) {
    this.loggedInUser = null;
    localStorage.setItem(this.tokenInfo, btoa(userInfo));
    this.loginChange.next('home');
  }
  setUserInfo(userInfo) {
    const data = [];
    data.push(userInfo);
    this.setLoggedInUserInfo(JSON.stringify(data));
  }

  get isUserLoggedIn() {
    return this.getLoggedInUserInfo() !== null;
  }

  get isUserHasBseRegistration() {
    return this.loggedInUser.hasBseRegistration;
  }
  set globalLoadingVal(val: boolean){
    this.globalLoading = val;
  }
  get isAdminUser() {
    const loggedInUser = this.getLoggedInUserInfo();
    return loggedInUser !== null && loggedInUser.isAdmin;
  }

  set navigationUrl(url: string) {
    this.urlNavigation = url;
  }

  get navigationUrl() {
    const url = this.urlNavigation;
    this.urlNavigation = '';
    return url || '/home';
  }

  setToken(token) {
    localStorage.setItem(this.tokenName, token);
  }

  getSearchHistory(path: any) {
    const search = this.searchHistory.filter(a => a.path === path);
    if (search && search.length > 0) {
      return { ...search[0].search };
    }
    return null;
  }

  setSearchHistory(path: any, filter: any) {
    const search = this.searchHistory.filter(a => a.path === path);
    if (search && search.length > 0) {
      search[0].search = filter;
    } else {
      this.searchHistory.push({ path: path, search: filter });
    }
  }
  clearSearchHistory() {
    this.searchHistory = [];
  }

  convertTitleCase(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  convertCamelCase(text: string) {
    return text.charAt(0).toLocaleLowerCase() + text.slice(1);
  }
  setActiveRouteData(path:string){
    const currentUrl = this.router.url;
    const routeUrl = currentUrl.split('#')[0];
    return routeUrl.toLowerCase()== path.toLowerCase();

  }

  reload() {
    setTimeout(() => {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl.replace('/', '')]);

      });
    }, 200);
  }

  setRouteData(data) {
    this.routeData = data;
  }
  getRouteData() {
    const data = { ...this.routeData };
    this.routeData = null;
    return data;
  }

  // scrollToTop(id: string): void {
  //   let elem = document.getElementById(id);
  //   let pos = elem.style.position;
  //   let top = elem.style.top;
  //   elem.style.position = 'relative';
  //   elem.style.top = '-105px';
  //   elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   elem.style.top = top;
  //   elem.style.position = pos;
  // }

  scrollToSection(id: string): void {
    id='main-page';
    let elem = document.getElementById(id);
    let pos = elem.style.position;
    let top = elem.style.top;
    elem.style.position = 'relative';
    elem.style.top = '-105px';
    elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
   elem.style.top = top;
   elem.style.position = pos;

   //console.log('top',top,elem,id);

    // elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollTop(id: string, el: ElementRef): void {
    window.scrollTo(0, 0);
    el.nativeElement.scrollIntoView
  }

  logInvalidControls(itemForm, reactiveForm = true) {
    //let valid = true;
    const formControls = reactiveForm ? itemForm?.controls : itemForm?.form.controls;
    const invalidCtrls = [];
    Object.keys(formControls).forEach(prop => {
      if(formControls[prop]["status"] == "INVALID"){
        invalidCtrls.push(prop);
      }
    });
    // const invalidCtrls = formControls.filter(a => !a.valid);
    // invalidCtrls.forEach(a => {
    //   console.log('invalidCtrls', a);
    // });

  }

  getHistoryBackDataType(){
    var historyData = window.location.href.split('#');
    if(historyData.length > 1){
      return historyData[1];
    }
    return "";
  }

  setHistoryBackDataType(type:string){
    history.pushState({id:type},'',window.location.href.split('#')[0] +`#${type}`);
  }

  getPlatterRiskProfile() {
    const userInfo = this.getLoggedInUserInfo();
    if (userInfo.riskProfile) {
      switch (userInfo.riskProfile) {
        case "Defensive":
          return "Low";
          break;
        case "Conservative":
          return "Moderately Low";
          break;
        case "Moderate":
          return "Moderate";
          break;
        case "Growth":
          return "Moderately High";
          break;
        case "Aggressive":
          return "High";
          break;
      }
    }
    return "";

  }
  checkValidControls(itemForm, controls: string[], reactiveForm = true) {
    //let valid = true;
    const formControls = reactiveForm ? itemForm?.controls : itemForm?.form.controls;
    var hasInvalid = controls.some(function (ctrl) {
      return !(formControls[ctrl] ? formControls[ctrl].valid : true);
    });
    return !hasInvalid

    // controls.forEach(ctrl => {
    //   valid = reactiveForm ? itemForm?.controls[ctrl]?.valid : itemForm?.form.controls[ctrl]?.valid;
    //   if(!valid) return false
    // });
    // return valid;
  }

  get isMobile():boolean {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true;
    }else{
      return false;
    }
  }
  public keyPressAlphaNumeric(event)  {
    // regEx = regEx || "/[A-Z0-9]+/g"
    const keyValue = event.target.value;

    var re = /[a-zA-Z0-9]+/g;
    const PanValue = (keyValue.match(re) || []).join('');
    event.target.value = PanValue;
  }
  public keyNumeric(event) {
    const keyValue = event.target.value;
    var re = /[0-9]+/g;
    const inputValue = (keyValue.match(re) || []).join('');
    event.target.value = inputValue;
  }
  public keyAlphaValue(event) {
    const keyValue = event.target.value;
    var re = /[a-zA-Z]+/g;
    const inputValue = (keyValue.match(re) || []).join('');
    event.target.value = inputValue;
  }
}
