import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from "@angular/core";
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { environment } from "./../../../environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Directory, Encoding, Filesystem, FilesystemDirectory } from "@capacitor/filesystem";
import { SecurityContext } from '@angular/core';

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() public service: any;
  @Input() public accept = "";
  @Input() public multiple = "multiple";
  @Input() public filesJson: string;
  @Input() public showImage = false;
  @Input() public editMode = false;
  @Input() public maxFileSize = 1000000;
  @Input() public minFileSize = 1000;
  @Input() public maxFileNumber = 100;
  @Input() public minFileNumber = 0;
  @Input() public controlDefault: FormControl = null;
  @Input() public fromCamera = false;
  @Input() public fileName = '';
  @Output() OnChange: EventEmitter<any> = new EventEmitter();

  photo: SafeResourceUrl;
  isDesktop: boolean;
  uploadedFiles = [];
  uploadingFiles = [];
  disabled = false;
  value = "";
  basePath = "";
  showDefault = false;
  selectedValue = '';
  apiBaseUrl = '';
  onModelChange: Function = () => { };
  onModelTouched: Function = () => { };
  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
   // this.apiBaseUrl = environment.apiBaseUrl;
    this.apiBaseUrl = environment.apiUrlBase;
    this.showDefault = !(this.controlDefault == null);
    if (this.showDefault) {
      this.selectedValue = this.controlDefault.value;
    }


  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value && this.value.length > 2) {
      this.uploadedFiles = JSON.parse(this.value);
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  fileUpload(event) {
    this.onModelTouched();
    for (const file of event.files) {
      this.uploadingFiles.push(file);
      this.service.fileUpload(file,this.fileName).subscribe((fileInfo: IFileUpload) => {

        this.uploadedFiles.push(fileInfo);
        const index: number = this.uploadingFiles.indexOf(file);
        if (index !== -1) {
          this.uploadingFiles.splice(index, 1);
        }
        this.value = JSON.stringify([...this.uploadedFiles]);
        this.onModelChange(this.value);
        this.OnChange.emit({
          originalEvent: event.originalEvent,
          value: [...this.uploadedFiles],
        });

        if (this.controlDefault && !this.controlDefault.value) {
          this.setDefaultAttachment(this.uploadedFiles[0]);
          this.selectedValue = this.uploadedFiles[0].filePath;

        }
          this.downloadFile(this.uploadedFiles[0]);
      });
    }
    // TODO: Check remove files if fails
    event.files.splice(0, event.files.length);
  }

  deleteAttachment(file: IFileUpload) {
    const index: number = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
    this.value = JSON.stringify([...this.uploadedFiles]);
    this.onModelChange(this.value);
    this.onModelTouched();
    if (file.filePath === this.selectedValue) {
      this.setDefaultAttachment(this.uploadedFiles[0]);
      this.selectedValue = this.uploadedFiles[0].filePath;
    }
    this.OnChange.emit({
      originalEvent: event.AT_TARGET,
      value: [...this.uploadedFiles],
    });
  }

 async downloadFile(file: IFileUpload) {
    await Filesystem.checkPermissions();
    await Filesystem.requestPermissions();
    this.service.fileDownload(file).subscribe((fileData) => {
      const blob = new Blob([fileData]);
      var reader = new FileReader();
      let newInstance = this.getFileReader();
      let base64 = newInstance.result as string;
      newInstance.onload = function() {
        const image =  Filesystem.appendFile({
          path: file.fileName,
          data: base64,
          directory: Directory.Documents,
          encoding:  Encoding.UTF8,
        });
      }
      // reader.readAsDataURL(blob);
      newInstance.readAsDataURL(blob);


      // this.saveExportFile(blob, file.fileName);
    });
  }

async convertoBase64(file: IFileUpload){
  this.service.fileDownload(file).subscribe((fileData) => {
    const blob = new Blob([fileData]);
    return new Promise((resolve, reject) => {
    let newInstance = this.getFileReader();
    newInstance.onerror = reject;
    newInstance.onload = () => {
      if (typeof newInstance.result === 'string') {
        resolve(newInstance.result);
      } else {
        reject('method did not return a string');
      }
    }
    newInstance.readAsDataURL(blob);
  });
  });
}
  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
}
  getImgUrl(file: IFileUpload) {
    return environment.apiUrlBase + "ImportFiles/" + file.filePath;
  }

  setDefaultAttachment(file: IFileUpload) {
    this.controlDefault.setValue(
      this.apiBaseUrl + "ImportFiles/" + file.filePath
    );
  }

  saveExportFile(blob: Blob, fileName: string) {
    const fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    const downloadSrc =this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, fileUrl);
    if (window.navigator.msSaveOrOpenBlob) { // For IE:
      navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = downloadSrc;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }

  async takeSavePhoto() {
    //     const filePermissions = await Filesystem.requestPermissions();
    // const cameraPermissions = await Camera.requestPermissions();
    const image = await Camera.getPhoto({
      quality: 100,
      width: 400,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
    });
    const file = this.DataURIToBlobFile(image.dataUrl, this.fileName)
    this.service.fileUpload(file).subscribe((fileInfo: IFileUpload) => {
      // this.downloadFile(fileInfo);
      this.uploadedFiles.push(fileInfo);
      this.value = JSON.stringify([...this.uploadedFiles]);
      this.onModelChange(this.value);
      this.OnChange.emit({
        // originalEvent: event.originalEvent,
        value: [...this.uploadedFiles],
      });

      if (this.controlDefault && !this.controlDefault.value) {
        this.setDefaultAttachment(this.uploadedFiles[0]);
        this.selectedValue = this.uploadedFiles[0].filePath;
      }
    });
    // this.photo = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   image && image.dataUrl
    // );

  }

  DataURIToBlobFile(dataURI: string, fileName) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

    const blob = new Blob([ia], { type: mimeString });
    return new File([blob], fileName + '.' +mimeString.replace("image/",""));
    }

    async download () {
      await Filesystem.checkPermissions();
      await Filesystem.requestPermissions();
      // window.open('https://api.bonvista.in/ImportFiles/EdIncome/Logo-150721-064915-932.png')
      const base64Data = await this._readAsBase64(environment.apiUrlBase +'ImportFiles/EdIncome/Logo-150721-064915-932.png');
      const fileName = `${new Date().getTime()}.jpeg`;
      const image = await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents
      });
    }
    private async _readAsBase64(url: string): Promise<string> {
      let headers = new Headers();

      // headers.append('x-requested-with','')

      const response = await fetch(url, {  mode: 'no-cors',
      method: 'get',
      headers: headers});
      const blob = await response.blob();
      return await this._convertBlobToBase64(blob);
    }
    private async _convertBlobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = () => reject();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
}
}
export interface IFileUpload {
  fileName: string;
  filePath: string;
  type: string;
}
