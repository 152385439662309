import { Directive, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { BaseModel } from '../core/model/modelInterface';
import { SubSink } from 'subsink';
import { ConfirmationService, MessageService } from 'primeng';
import { LookupService } from './lookupService';
import { Router } from '@angular/router';
import { BaseService } from './baseService';
import * as moment from 'moment';
import { AppInjector } from '../../app-injector.service';
import { Injectable } from '@angular/core';

@Directive()
export abstract class BaseTemplateEditComponent<T extends BaseModel> implements OnDestroy {

  hasUserFilter = false;
  allowNullItem = false;
  isDialog = false;
  editId = 0;
  //itemForm: FormGroup;

  item: T;
  model: T;
  subsink = new SubSink();
  mindate = new Date();
  navigatePath = '';
  messageService: MessageService;
  loading = false;
  @ViewChild('itemForm') itemForm: NgForm;
  constructor(
    public service: BaseService<T>,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService
  ) {
    const injector = AppInjector.getInjector();
    this.messageService = injector.get(MessageService);
  }

  bindDropDown() { }
  setDateCalenderValues(item: any): any {
    return item;
  }

  onSelectDate(date: moment.MomentInput, controlName: string) {
    //this.itemForm.controls[controlName].setValue((moment(date)).format());
    this.model[controlName] = (moment(date)).format();
  }

  onClearDate(date: moment.MomentInput, controlName: string) {
    //this.itemForm.controls[controlName].setValue(null);
    this.model[controlName] = null;
  }

  selectDropdown(data: any, controlName: string) {
    this.model[controlName] = data.value.id;
    //this.itemForm.get(controlName).setValue(data.value.id);
    // if (this.item.hasOwnProperty(controlName + 'Label')) {
    //   this.model[controlName + 'Label'] = data.value.name;
    // }
    this.model[controlName + 'Label'] = data.value.name;

  }

  selectDropdownEnum(data: any, controlName: string, setName = false) {
    this.model[controlName] = data.value.value;
    this.model[controlName + 'Label'] = data.value.name;
    // if (this.item.hasOwnProperty(controlName + 'Label')) {
    //   this.model[controlName + 'Label'] = data.value.name;
    // }    
    if (setName) {
      this.model[controlName] = data.value.name;
    }
  }

  selectRadioEnum(data: any, controlName: string, setName = false) {
    this.model[controlName] = data.value;
    if (this.item.hasOwnProperty(controlName + 'Label')) {
      this.model[controlName + 'Label'] = data.name;
    }
    if (setName) {
      this.model[controlName] = data.value.name;
    }
  }

  selectMultiData(data: { value: any[]; }, controlName: string) {
    this.model[controlName] = data.value.map((i: { id: any; }) => i.id).join(',');
    if (this.item.hasOwnProperty(controlName + 'Label')) {
      this.model[controlName + 'Label'] = data.value.map((i: { name: any; }) => i.name).join(',');
    }

  }

  getData(id: number) {
    this.subsink.sink = this.service.get(id, this.hasUserFilter).subscribe(item => {
      if (item) {
        this.setDateCalenderValues(item);
        this.item = item;
        //this.itemForm.(this.item);
        this.model = { ...item };
        this.bindDropDown();
      } else {
        if (!this.allowNullItem) {
          this.navigateHome();
        } else {
          this.item = {} as T;
          //this.itemForm.(this.item);
          this.model = { ...item };
        }
      }
    });
  }

  preSubmit(itemValue: T) {
    return itemValue;
  }

  formSubmit() {
    this.itemForm.form.markAllAsTouched();
    
    if (this.itemForm.valid) {
      this.submit();
    }
  }

  submit() {
   
    if (this.itemForm.valid) {
      // delete this.itemForm.value["dateCalendar"];
      let itemValue = { ...this.item, ...this.model } as T;
      itemValue = this.preSubmit(itemValue);
      if (itemValue.id) {
        this.update(itemValue);
      } else {
        this.add(itemValue);
      }
    }
  }

  add(item: T) {
    this.loading = true;
    this.subsink.sink = this.service.add(item, this.hasUserFilter).subscribe(() => {
      // this.messageService.add({ key: 'crudToast', severity: 'success', summary: 'Success', detail: 'Added successfully' });
      this.loading = false;
      this.navigateHome();
    });
  }


  delete() {

    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.subsink.sink = this.service.delete(this.item.id, this.hasUserFilter).subscribe(() => {
          // this.messageService.add({ key: 'crudToast', severity: 'success', summary: 'Success', detail: 'Removed successfully' });
          this.navigateHome();
        });
      },
      reject: () => {

      }
    });
  }

  update(item: T) {
    this.loading = true;
    this.subsink.sink = this.service.update(item, this.hasUserFilter).subscribe(() => {
      // this.messageService.add({ key: 'crudToast', severity: 'success', summary: 'Success', detail: 'Updated successfully' });
      this.loading = false;
      this.navigateHome();
    });

  }


  downloadPdf() {
    this.service.downloadPdf('' + this.editId).subscribe((fileData) => {
      const blob = new Blob([fileData as any]);
      this.saveExportFile(blob, this.item.name + '.pdf');
    });

  }

  saveExportFile(blob: Blob, fileName: string) {
    if (window.navigator.msSaveOrOpenBlob) { // For IE:
      navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }


  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }

  navigateHome() {
    this.service.helperSvc.routeNavigate(this.navigatePath);
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

}
