import { Injectable } from '@angular/core';
import { ClientInvestment } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ClientInvestmentsService extends BaseService<ClientInvestment> {
    constructor() {
        super('ClientInvestment');
    }
}
