import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet, MultiDataSet } from 'ng2-charts';
import { Platter } from '../../core/model';

@Component({
  selector: 'app-platter-list-info',
  templateUrl: './platter-list-info.component.html',
  styleUrls: ['./platter-list-info.component.scss'],
})
export class PlatterListInfoComponent implements OnInit {
  @Input() platter: Platter;
  platterDetail: any;


  constructor() { }

  ngOnInit() {
    if(this.platter && this.platter.ruleJson){
      var platterList = JSON.parse(this.platter.ruleJson);
      this.platterDetail = platterList[0];
    }
   }


}
