import { Component, OnInit, Input } from '@angular/core';
import { DialogService } from 'primeng';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  providers: [DialogService]
})
export class FaqsComponent implements OnInit {

  @Input() faqs: any[] = [];
  faq: any;
  currentIndex: number;
  constructor(public dialogService: DialogService) { }

  ngOnInit() {}

  showAnswer(answers: any) {
    this.faq = answers;
    this.currentIndex = this.currentIndex == answers.id ? null : answers.id;
  }

}
