import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  ConfigurationsService,
  MandatesService,
  MfSchemesService,
} from "../../core/service";
import { Observable } from "rxjs";
import {
  MfScheme,
  MfOrder,
  Foundation,
  Mandate,
  FeedMorStarApi,
} from "../../core/model";
import { LookupService } from "../../shared/lookupService";
import { ChartOptions, ChartType } from "chart.js";
import { Label, SingleDataSet, MultiDataSet } from "ng2-charts";
import { Router } from "@angular/router";
import { MfUserOrdersService } from "../../pages/custom/mfUserOrders/mfOrders.service";
import { ConfirmationService, DialogService } from "primeng";
import { FoundationPageService } from "../../pages/custom/foundation/foundation.page.service";
import { HelperService } from "../../shared/helperService";
import { MfPaymentComponent } from "../mf-payment/mf-payment.component";
import { FeedMorStarApisService } from "../../pages/custom/feedMorStarApis/feedMorStarApis.service";
import { MfFundInfoComponent, MfFundInfoDialogComponent } from "../mf-fund-info/mf-fund-info.component";

@Component({
  selector: 'app-emergency-fund-list',
  templateUrl: './emergency-fund-list.component.html',
  styleUrls: ['./emergency-fund-list.component.scss'],
  providers: [ConfirmationService,DialogService],
})

export class EmergencyFundListComponent implements OnInit {


  investmentAmt = null;
  selectedMFScheme: any;
  mfOrderList: any[];
  mfOrder: MfOrder;
  orderError = '';
  validOrder = false;
  isGridTotalAmountVisible = false;
  menuHeading: string  = 'Portfolio Details';

  showPayment = false;
  listMfOrderTypeEnum: any[];
  listFrequencyTypeEnum: any[];
  NoOfInstallment: number;
  isTabsVisible = false;
  currentStep = 0;
  totalStep = 2;
  riskValue = 0;
  invalidAmount = false;
  legendData: any[];

  loggedInUser: any;
  mandateType = '';
  showFundInfo = false;
  feedMorningStar:any;
  isEditMode = false;


  prevTotalAmount = 0;

  generateGrid = false;
  gridTotalAmount = 0;
  loading = false;

  prevselectedMFScheme: any;
  selectedMfOrderTypeEnum: any;

  public foundation: Foundation;
  showMobileView = false;
  prevselectedMfOrderTypeEnum: any;
  selectedFrequencyTypeEnum: any;
  sipStartDateCalendar: any;

  constructor(
    public mfOrdersService: MfUserOrdersService,
    public mandatesService: MandatesService,
    public foundationPageService: FoundationPageService,
    public lookupService: LookupService, public router: Router,
    public confirmationService: ConfirmationService,
    public feedMorStarApisService: FeedMorStarApisService,
    public helperService: HelperService,
    private configurationsService: ConfigurationsService,
    public dialogService: DialogService,
    public mfSchemesService: MfSchemesService
    //public nav: NavController
  ) {
    this.loggedInUser = this.mfOrdersService.helperSvc.getLoggedInUserInfo();
    //this.showMobileView = this.helperService.isMobile;
  }

  ngOnInit() {
    this.selectedMFScheme = null;
    this.currentStep = 1;
    this.investmentAmt = null;

    const userInfo = this.helperService.getLoggedInUserInfo();
    if (!userInfo.riskProfile) {
      this.helperService.setRouteData({ "page": "mfBooking" });
      this.helperService.routeNavigate('riskProfile');
      return;
    }
    // this.mfSchemesService.getAll().subscribe((data: any[]) => {
    //   this.selectedMFScheme = this.prevselectedMFScheme = data[1];
    //   this.bindSchems();
    // });

    this.bindDropDown();
    this.foundationPageService.getAll().subscribe((foundation: Foundation[]) => {
      this.foundation = foundation.length > 0 ? foundation[0] : null;
      if (this.foundation) {
        var investmentAmt = this.foundation.emergencyFundRequired - this.foundation.emergencyFund;
        investmentAmt = investmentAmt < 5000 ? 5000 : investmentAmt;
        this.investmentAmt = investmentAmt > 0 ? investmentAmt : 5000;
        this.bindSchems();
      }
    });

    const search = {};
    search['Name'] = 'Emergency-Fund-Schema';

    this.configurationsService.searchData(search, null, null, false).subscribe((data) => {
      this.selectedMFScheme = this.prevselectedMFScheme = {'schemeDetailsJson' : data[0].value};
      this.bindSchems();
    });
  }

  checkDisabled() {

    if (this.currentStep === 1) {
      this.invalidAmount = false;
      if (this.investmentAmt && this.investmentAmt > 0 && this.investmentAmt >= 5000) {
        return false;
      } else{
        this.invalidAmount = true;
        return true;
      }

    }
    return true;
  }

  next() {
    if (this.currentStep === 1) {
      this.prevTotalAmount = this.investmentAmt;
      this.currentStep = this.currentStep + 1;
      this.generateGrid = true;
    }

  }

  onAmountUpdate(amtVal:any):void {
    // if(this.currentStep === 2) {
      //this.prevselectedMFScheme = this.selectedMFScheme;
      this.prevTotalAmount = amtVal; //this.investmentAmt;
      //this.prevselectedMfOrderTypeEnum = this.selectedMfOrderTypeEnum;
      this.currentStep = this.currentStep;
      this.generateGrid = true;
    // }
  }


  updateChart(mfDetails) {
  }

  getMfSelectedList(data) {
    this.mfOrderList = JSON.parse(data);
    this.validateOrder();
  }

  validateOrder() {
    this.orderError = '';
    let totalAmt = 0;
    let valid = true;
    this.mfOrderList.forEach(order => {
      const amt = parseInt(order.amount, 10);
      totalAmt += amt;
    });
    let message = '';
    if (!valid) {
      message = 'Amount should not be empty or Zero';
    } else if (totalAmt - Number(this.investmentAmt) != 0) {
      message = 'Investment Amount (' + this.investmentAmt + ') not matched with total amount:' + totalAmt;
    }
    setTimeout(() => {
      this.orderError = message;
      this.gridTotalAmount = totalAmt;
    }, 50);

    // if (valid && this.investmentAmt === totalAmt) {
    // this.validOrder = valid && this.investmentAmt === totalAmt;
    //this.validOrder = valid && totalAmt > 0 && (totalAmt - this.investmentAmt == 0);
    this.validOrder = valid && totalAmt > 0 && (totalAmt -  Number(this.investmentAmt) == 0);
  }

  bindDropDown() {
    this.lookupService.getDataEnum("MFOrderType").subscribe((list) => {
      this.listMfOrderTypeEnum = list;
      this.selectedMfOrderTypeEnum = list[2];
      this.NoOfInstallment = 999;
    });
    this.lookupService.getDataEnum("FrequencyType").subscribe((list) => {
      this.listFrequencyTypeEnum = list;
      this.selectedFrequencyTypeEnum = list[0];
    });
  }

  bindSchems() {
    if (this.prevselectedMFScheme && this.investmentAmt) {
      this.prevselectedMFScheme = this.selectedMFScheme;
      this.prevTotalAmount = this.investmentAmt;
      this.prevselectedMfOrderTypeEnum = this.selectedMfOrderTypeEnum;
      this.generateGrid = true;
    }
  }


  preOrderMF() {
    this.showFundInfo = false;
    this.mandateType = 'register';
    this.showPayment = true;
    //this.isTabsVisible=true;
    this.menuHeading = 'Payment';
  }


  back(): void {

  }


  showSchemeInfo(fund): void {
    const search = { 'Isin': fund.isin };
    this.feedMorStarApisService.searchFund(null, null, search).subscribe((schemeInfoList: FeedMorStarApi[]) => {
      if (schemeInfoList && schemeInfoList.length) {
        this.feedMorningStar = schemeInfoList[0];
        this.feedMorningStar.operations = JSON.parse(this.feedMorningStar.operations);
        this.feedMorningStar.performance = JSON.parse(this.feedMorningStar.performance);
        this.feedMorningStar.top10Holdings = JSON.parse(this.feedMorningStar.top10Holdings);
        this.showFundInfo = true;
        this.openFundDetails();
      }
    });
  }
  openFundDetails() {
    const ref = this.dialogService.open(MfFundInfoDialogComponent, {

      data: {
        openSipLumpsumPopup:true,
        openFoundationFund:true,
        showSimilarFunds:false,
        isFooterVisible:false,
        FeedMorningStar: this.feedMorningStar,
        Isin:this.feedMorningStar?.isin

      },
      width: '50%'
  });
    // ref.onClose.subscribe((data: any) => {
    //   if (data) {
    //     let currentUrl = this.router.url;
    //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //       this.router.navigate([currentUrl]);
    //     });

    //   }
    // });



}
  showEditMode():void {
    this.isEditMode = true;
    this.isGridTotalAmountVisible = true;
    this.menuHeading = 'Edit Portfolio';
  }

  // scrollToTop():void {
  //   this.helperService.scrollToTop();
  // }

}
