import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../../core/model';
import { UsersService as service } from '../../user/users/users.service';



@Injectable({
    providedIn: 'root'
})

export class UsersService extends service {
  register(item: User) {
    return this.http.post(this.apiUrl + 'Register', item)
    .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  updateRiskProfile(riskProfile: string) {
    const form = {'riskProfile': riskProfile};
    return this.http.post(this.apiUrl + 'UpdateRiskProfile', form)
    .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  generateOtp(mobileNo: string, emailId: string) {

    const form = {'mobileNo': mobileNo,'emailId': emailId,};

    return this.http.post(this.apiUrl + 'GenerateOtp', form)

    .pipe(

        map(data => {

          return data;

        }),

        catchError(this.handleError)

      );

  }



  verifyOtp(mobileNo: string, emailId: string, otp: string) {
    const form = {'mobileNo': mobileNo,'emailId': emailId,'otp': otp};
    return this.http.post(this.apiUrl + 'VerifyOtp', form)
    .pipe(
        map(data => {
          return data;
        }),

        catchError(this.handleError)

      );

  }

  changePin(pin: string) {
    const form = {'pin':pin};
    return this.http.post(this.apiUrl + 'ChangePin', form)
    .pipe(
        map(data => {
          return data;
        }),

        catchError(this.handleError)

      );

  }

}
