import { Injectable } from '@angular/core';
import { FeedSipDetail } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class FeedSipDetailsService extends BaseService<FeedSipDetail> {
    constructor() {
        super('FeedSipDetail');
    }
}
