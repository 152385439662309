import { Component, OnInit, Input } from '@angular/core';
import { FaqsService } from '../../core/service';
@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss'],
})
export class CoverComponent implements OnInit {

    @Input() currentCover = 0;
    @Input() requiredCover = 0;
    @Input() currentText = 'Current';
    @Input() requiredText = 'Required';
    @Input() shortfallText = 'Shortfall';
    @Input() isMonthlyInstallment = '';
    @Input() ratio = 0;
    @Input() totalEmi = 0;
    @Input() totalEmiText = 'EMI';

  constructor(public faqService: FaqsService) { }

  ngOnInit() {}

}
