import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.scss']
})
export class UsersTabComponent implements OnInit {

  @Input() userName = '';
  @Input() spouseName = '';
  @Input() userTabSelected = '';
  @Output() userTabChange  = new EventEmitter<string>();
  @Input() includeSpouse = false;
  showMobileView = false;

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.showMobileView = this.helperService.isMobile;
  }

  onUserTabChange(data: string) {
    //console.log('onUserSelect', data);
    this.userTabChange.emit(data);
  }

}
