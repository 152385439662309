import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Blog } from '../../core/model';
import { BlogsService } from '../../core/service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-home-blog',
  templateUrl: './home-blog.component.html',
  styleUrls: ['./home-blog.component.scss'],
})
export class HomeBlogComponent implements OnInit {
  blogs: Blog[];
  loadComplete = false;
  currentStep = 0;

  constructor(private helperService: HelperService,
    public blogsService: BlogsService) {

    }

  ngOnInit() {
    const search = {};
    search['IsActive'] = true;

    this.blogsService.searchData(search, null,null,false).subscribe((blogs: Blog[]) => {
      this.blogs = blogs;
      this.loadComplete = true;
      //console.log(this.blogs);
    });
  }


  openBlog(url) {
    window.open(url);
  }

  prev():void {

  }

  next():void {

  }


}
