import {
  Component,
  OnInit,
  Injectable,
  HostListener,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event,
  RouteConfigLoadStart,
} from "@angular/router";
import { HelperService } from "./shared/helperService";
import { LookupService } from "./shared/lookupService";
import { AppInjector } from "../app-injector.service";
import { LoginsService } from "./core/service";
import { Foundation } from "./core/model";
import { Meta, Title } from "@angular/platform-browser";
import { AllService } from "./core/service/all.service";
import { HttpClient } from "@angular/common/http";
import { LoginComponent } from "./pages/auth/login/login.component";
// import { LocationStrategy } from "@angular/common";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from "../environments/environment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = 'Bonvista';
  seos: any[] = [];
  showLoadingIndicator = true;
  menuActive = true;
  newsActive = false;
  isAdmin = false;
  isUserLoggedIn = false;
  loadIntialize = false;
  isMenuVisible: boolean = false;
  isOverlayVisible: boolean = false;
  loadComponent = false;
  public foundation: Foundation;
  loggedInUser: any;
  currentRoute: string;
  activeMenu: string = "";
  isWindowScroll: boolean = false;
  isLeftMenuFixed: boolean = false;
  isRightPartFixed: boolean = false;
  showMobileView: boolean = false;
  isMobSearchVisible: boolean = false;
  isMobProfileVisible: boolean = false;
  toggleNotification: boolean = false;
  isBlockVisible: boolean = true;
  isLoaderVisible: boolean = true;
  showHeader: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private allService: AllService,
    private titleService: Title,
    private http: HttpClient,
    private metaService: Meta,
    public helperService: HelperService,
    public lookupService: LookupService,
    // private location: LocationStrategy

  ) //public loginsService: LoginsService,
  // public foundationPageService: FoundationPageService
  {

    // history.pushState(null, null, window.location.href);
    // // check if back or forward button is pressed.
    // this.location.onPopState(() => {
    //     history.pushState(null, null, window.location.href);
    //     console.log("press back");
    //     //this.stepper.previous();
    // });

    translate.setDefaultLang("en");
setTimeout(() => {
  this.showHeader=true;
}, 200);
    const injector = AppInjector.getInjector();
    //console.log('injector app Component', injector);
    this.helperService.loginChange.subscribe((loginType) => {
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    });

    this.router.events.subscribe((routerEvent: Event) => {
      this.isMobSearchVisible = false;
      this.isMobProfileVisible = false;
      this.toggleNotification = !this.toggleNotification;

      if (routerEvent instanceof RouteConfigLoadStart) {
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
      }
      if (routerEvent instanceof NavigationStart) {
        ////console.log('router.events', routerEvent.url);
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
        let currentUrl = routerEvent.url.substr(
          routerEvent.url.indexOf("/") + 1
        );
        this.currentRoute = currentUrl;
        this.activeMenu = this.getActiveMenu(routerEvent.url);
      }

      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError
      ) {
        setTimeout(() => {
          this.showLoadingIndicator = false;
        }, 500);

        setTimeout(() => {
          this.isLoaderVisible = false;
        }, 2000);

        setTimeout(() => {
          // this.showLoadingIndicator = false;
          const url = this.router.routerState.snapshot.url.toLowerCase();
          if (url.indexOf("/foundation/") >= 0) {
            this.helperService.scrollToSection("child-foundation");
          } else {
            this.helperService.scrollToSection("main-page");
          }
        }, 200);
      }
    });
  }

  getActiveMenu(url: string): string {
    if (url.toLowerCase().indexOf("platters") >= 0) return "platters";
    if (url.toLowerCase().indexOf("investment-report") >= 0)
      return "investment-report";
    if (url.toLowerCase().indexOf("mandates") >= 0) return "mandates";
    if (url.toLowerCase().indexOf("bseRegistrations".toLowerCase()) >= 0)
      return "bseRegistrations";
    if (url.toLowerCase().indexOf("mfUserOrders".toLowerCase()) >= 0)
      return "mfUserOrders";
    if (
      url.toLowerCase().indexOf("edExpenses".toLowerCase()) >= 0 ||
      url.toLowerCase().indexOf("edIncomes".toLowerCase()) >= 0
    )
      return "tracker";
    if (url.toLowerCase().indexOf("contact-us") >= 0) return "contact-us";

    return "";
  }

  ngOnInit() {


    this.http.post<any[]>(environment.apiUrlBase +'Misc/SeoData', null).subscribe((seos) => {
      this.seos = seos;
      // console.log('seos', seos);
      // console.log('this.location.pathname', this.router.url);
      this.setSeo(this.router.url);
    });
    // console.log(history.state)
    // console.log(history.state.data);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        //do something on start activity
       // console.log('event.url', event.url);
        this.setSeo(event.url);
      }
    });


    setTimeout(() => {
      this.lookupService.getDataEnum("").subscribe((list) => {
        this.loadIntialize = true;
      });
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    }, 50);

    setTimeout(() => {
      this.loadIntialize = true;
      this.loadComponent = true;
      // this.foundationPageService.getAll().subscribe((foundation: Foundation[]) => {
      //   this.foundation = foundation.length > 0 ? foundation[0] : null;
      //   //console.log('Foundation Result',this.foundation);
      // });
    }, 1000);

    this.showMobileView = this.helperService.isMobile;

    if (!this.helperService.isMobile) {
      this.isMobSearchVisible = true;
    } else {
      this.isMobSearchVisible = false;
    }

    GoogleAuth.initialize({
      clientId: '927555018564-ri84knhsc1bv29ki83ffa4msj42tt1vr.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }

  public setSeo(path: string) {
    var seoData = this.seos.filter((a) => a.name == path.replace('/', ''));
   // console.log('seoData', seoData);
    if (seoData.length) {
      var seoVal = seoData[0];
     // console.log('seoVal', seoVal);

      this.titleService.setTitle(seoVal.title);

      this.metaService.addTags([
        { name: 'keywords', content: seoVal.metaKeywords },
        { name: 'description', content: seoVal.metaDescription },
        //{ name: 'author', content: seoVal.metaAuthor },
        //{ name: 'description', content: seoVal.metaDescription },

        { property: 'og:title', content: seoVal.ogTitle },
        { property: 'og:type', content: seoVal.ogType },
        { property: 'og:url', content: seoVal.ogUrl },
        { property: 'og:image', content: seoVal.ogImageUrl },
        { property: 'og:description', content: seoVal.ogDescription },
        // { name: 'robots', content: seoVal.title }
      ]);
      if (seoVal.metaAuthor) {
        this.metaService.addTag({ name: 'author', content: seoVal.metaAuthor });
      }
    }
  }
  route(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
  }
  onMenuButtonClick(event: any) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);

    loginSvc.clearData();
    this.helperService.logout();
    //console.log('route path', 'login');
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.isOverlayVisible = !this.isOverlayVisible;
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      this.isWindowScroll = true;
    } else {
      this.isWindowScroll = false;
    }


    //console.log("Scroll Event", window.pageYOffset);

    if (
      document.body.scrollTop > 450 ||
      document.documentElement.scrollTop > 450
    ) {
      this.isLeftMenuFixed = true;
    } else {
      this.isLeftMenuFixed = false;
    }

    if (
      document.body.scrollTop > 1030 ||
      document.documentElement.scrollTop > 1030
    ) {
      this.isRightPartFixed = true;
    } else {
      this.isRightPartFixed = false;
    }
  }

  gotoTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleMobileProfile(event: any): void {
    event.stopPropagation();
    this.isMobProfileVisible = !this.isMobProfileVisible;
  }

  toggleMobileSearch(event: any): void {
    event.stopPropagation();
    this.isMobSearchVisible = !this.isMobSearchVisible;
  }
  goto(path: string): void {
    this.router.navigateByUrl(path);
  }
  @HostListener("click", ["$event"])
  onClick(event: any): void {
    this.isMobProfileVisible = false;
    this.isMobSearchVisible = false;
    this.toggleNotification = false;
    // let num=Math.floor(Math.random()*1000);
    // history.pushState({id:num},'',window.location.href.split('#')[0] +`#${num}`);
   // console.log("window.history",n);
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: any): void {
  //   // let num=Math.floor(Math.random()*1000);
  //   // let n=history.pushState({id:num},'',window.location.href.split('#')[0] +`#${num}`);
  //   // console.log("window.history",n);

  //   // const url = new URL(window.location.href);
  //   // this.router.navigate([url], { state: {data: event} });
  //   // alert(window.history.length)
  //   //  history.pushState(null, null, url.toString());
  //   // console.log('Back button pressed');
  //   // event.preventDefault();

  // }

//   onNavToOrderDetails(event: any) {
//     this.router.navigate(['routetodetailscomponent'], { state: {data: event} });
//  }

}
