import { Injectable } from '@angular/core';
import { Distributor } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class DistributorsService extends BaseService<Distributor> {
    constructor() {
        super('Distributor');
    }
}
