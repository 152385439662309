import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BseRegistration } from '../../../../core/model/bseRegistration';
import { ActivatedRoute, Router } from '@angular/router';
import { BseRegistrationsService } from '../bseRegistrations.service';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { LookupService } from '../../../../shared/lookupService';
import { BaseTemplateEditComponent } from '../../../../shared/baseTemplateEditComponent';




export class BseRegistrationsBaseEditComponent extends BaseTemplateEditComponent<BseRegistration> {
    navigatePath = 'bseRegistrations';

    
listGenderEnum: any[];
 listHoldingNatureEnum: any[];
 listTaxStatusEnum: any[];
 listSourceWealthEnum: any[];
 listIncomeSlabEnum: any[];
 listOccupationEnum: any[];
 listBirthCountryEnum: any[];
 listBankName: any[];
 listAccountTypeEnum: any[];
 listStateEnum: any[];
 listCountryEnum: any[];
 listNomineeRelationEnum: any[];
 listNomineeMinor: any[];
 listNominee2RelationEnum: any[];
 listNominee2Minor: any[];

    
selectedGenderEnum: any =null;
 selectedHoldingNatureEnum: any =null;
 selectedTaxStatusEnum: any =null;
 selectedSourceWealthEnum: any =null;
 selectedIncomeSlabEnum: any =null;
 selectedOccupationEnum: any =null;
 calendarBirthDate: Date =null;
 selectedBirthCountryEnum: any =null;
 selectedBankName: any =null;
 selectedAccountTypeEnum: any =null;
 selectedStateEnum: any =null;
 selectedCountryEnum: any =null;
 selectedNomineeRelationEnum: any =null;
 selectedNomineeMinor: any =null;
 calendarNomineeBirthDate: Date =null;
 selectedNominee2RelationEnum: any =null;
 selectedNominee2Minor: any =null;
 calendarNominee2BirthDate: Date =null;

    createForm(id: number, isDialog: boolean = false) {
        this.editId = id;
        this.isDialog = isDialog;

        if (id > 0) {
            this.getData(id);
            // this.bindDropDown() => Added in the Subscribe method.
        } else {
            this.item = {};
            this.model = { ...this.item };
            this.bindDropDown();
        }
    }

    bindDropDown() {
        
this.lookupService.getDataEnum('Gender').subscribe(list => {
      this.listGenderEnum = list;
      if (this.item && this.item.genderEnum) {
        this.selectedGenderEnum = this.item.genderEnum;
      }
    });
 this.lookupService.getDataEnum('HoldingNature').subscribe(list => {
      this.listHoldingNatureEnum = list;
      if (this.item && this.item.holdingNatureEnum) {
        const selectedList = list.filter(a => a.value === this.item.holdingNatureEnum);
        if (selectedList.length) {
            this.selectedHoldingNatureEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('TaxStatus').subscribe(list => {
      this.listTaxStatusEnum = list;
      if (this.item && this.item.taxStatusEnum) {
        const selectedList = list.filter(a => a.value === this.item.taxStatusEnum);
        if (selectedList.length) {
            this.selectedTaxStatusEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('SourceWealth').subscribe(list => {
      this.listSourceWealthEnum = list;
      if (this.item && this.item.sourceWealthEnum) {
        const selectedList = list.filter(a => a.value === this.item.sourceWealthEnum);
        if (selectedList.length) {
            this.selectedSourceWealthEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('IncomeSlab').subscribe(list => {
      this.listIncomeSlabEnum = list;
      if (this.item && this.item.incomeSlabEnum) {
        const selectedList = list.filter(a => a.value === this.item.incomeSlabEnum);
        if (selectedList.length) {
            this.selectedIncomeSlabEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('Occupation').subscribe(list => {
      this.listOccupationEnum = list;
      if (this.item && this.item.occupationEnum) {
        const selectedList = list.filter(a => a.value === this.item.occupationEnum);
        if (selectedList.length) {
            this.selectedOccupationEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('Country').subscribe(list => {
      this.listBirthCountryEnum = list;
      if (this.item && this.item.birthCountryEnum) {
        const selectedList = list.filter(a => a.value === this.item.birthCountryEnum);
        if (selectedList.length) {
            this.selectedBirthCountryEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('Bank').subscribe(list => {
      this.listBankName = list;
      if (this.item && this.item.bankName) {
        const selectedList = list.filter(a => a.value === this.item.bankName);
        if (selectedList.length) {
            this.selectedBankName = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('AccountType').subscribe(list => {
      this.listAccountTypeEnum = list;
      if (this.item && this.item.accountTypeEnum) {
        const selectedList = list.filter(a => a.value === this.item.accountTypeEnum);
        if (selectedList.length) {
            this.selectedAccountTypeEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('State').subscribe(list => {
      this.listStateEnum = list;
      if (this.item && this.item.stateEnum) {
        const selectedList = list.filter(a => a.value === this.item.stateEnum);
        if (selectedList.length) {
            this.selectedStateEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('Country').subscribe(list => {
      this.listCountryEnum = list;
      if (this.item && this.item.countryEnum) {
        const selectedList = list.filter(a => a.value === this.item.countryEnum);
        if (selectedList.length) {
            this.selectedCountryEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('Relation').subscribe(list => {
      this.listNomineeRelationEnum = list;
      if (this.item && this.item.nomineeRelationEnum) {
        const selectedList = list.filter(a => a.value === this.item.nomineeRelationEnum);
        if (selectedList.length) {
            this.selectedNomineeRelationEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('YesNo').subscribe(list => {
      this.listNomineeMinor = list;
      if (this.item && this.item.nomineeMinor) {
        this.selectedNomineeMinor = this.item.nomineeMinor;
      }
    });
 this.lookupService.getDataEnum('Relation').subscribe(list => {
      this.listNominee2RelationEnum = list;
      if (this.item && this.item.nominee2RelationEnum) {
        const selectedList = list.filter(a => a.value === this.item.nominee2RelationEnum);
        if (selectedList.length) {
            this.selectedNominee2RelationEnum = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('YesNo').subscribe(list => {
      this.listNominee2Minor = list;
      if (this.item && this.item.nominee2Minor) {
        this.selectedNominee2Minor = this.item.nominee2Minor;
      }
    });
    }

    setDateCalenderValues(item) {
        
if (item.birthDate) {
    this.calendarBirthDate = new Date(item.birthDate);
}
 if (item.nomineeBirthDate) {
    this.calendarNomineeBirthDate = new Date(item.nomineeBirthDate);
}
 if (item.nominee2BirthDate) {
    this.calendarNominee2BirthDate = new Date(item.nominee2BirthDate);
}
        return item;
    }

    preSubmit(itemValue) {
        if (typeof (itemValue.userId) !== 'undefined') {
            itemValue.userId = this.service.helperSvc.loggedInUser.id;
        }
        return itemValue;
    }
}



@Component({
    selector: 'app-bseRegistrations-edit',
    templateUrl: './bseRegistrations-edit.component.html',
    styleUrls: ['./bseRegistrations-edit.component.scss'],
    providers: [ConfirmationService]
})
export class BseRegistrationsEditComponent extends BseRegistrationsBaseEditComponent implements OnInit {
    bseRegistrationForm: FormGroup;

    constructor(private route: ActivatedRoute,
        public bseRegistrationsService: BseRegistrationsService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(bseRegistrationsService, router, formBuilder, confirmationService, lookupService);
    }

    ngOnInit() {
        this.ionViewWillEnter();
    }
    ionViewWillEnter() {

        const idText = this.route.snapshot.paramMap.get('id');

        let id = 0;
        if (idText.toLowerCase() !== 'add') {
            id = +this.route.snapshot.paramMap.get('id');
        }
        this.createForm(id);
    }
}


@Component({
    templateUrl: './bseRegistrations-edit.component.html',
    styleUrls: ['./bseRegistrations-edit.component.scss']
})
export class BseRegistrationsEditDialogComponent extends BseRegistrationsBaseEditComponent implements OnInit {
    constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
        public bseRegistrationsService: BseRegistrationsService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(bseRegistrationsService, router, formBuilder, confirmationService, lookupService);
    }
    ngOnInit() {
        this.ionViewWillEnter();
    }
    ionViewWillEnter() {

        const id = +this.config.data.id;
        this.createForm(id, true);
    }

    navigateHome() {
        this.ref.close('refresh');
    }
}



