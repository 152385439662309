import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./pages/auth/guard/authGuard.service";
import { AuthAdminGuard } from "./pages/auth/guard/authGuardAdmin.service";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "home" },
  { path: "register1", loadChildren: () => import("./pages/auth/register/register.module").then((m) => m.RegisterModule), },
  { path: "login1", loadChildren: () => import("./pages/admin/logins/logins.module").then((m) => m.LoginsModule), },
  // For Admin Route
  { path: "login", loadChildren: () => import("./pages/auth/login/login.module").then((m) => m.LoginModule) },
  { path: "verify", loadChildren: () => import("./pages/auth/verification/verification.module").then((m) => m.VerificationModule) },
  { path: 'admin/home', loadChildren: () => import('./pages/auth/home/home.module').then(m => m.HomePageModule) },
  { path: 'admin/email-verification', loadChildren: () => import('./pages/auth/email-verification/email-verification.module').then(m => m.EmailVerificationPageModule), canActivate: [AuthGuard] },
  { path: 'admin/stage', loadChildren: () => import('./pages/custom/stage/stage.module').then(m => m.StagePageModule), canActivate: [AuthGuard] },
  { path: 'admin/profile', loadChildren: () => import('./pages/custom/userprofile/userprofile.module').then(m => m.UserprofilePageModule), canActivate: [AuthGuard] },
  { path: 'admin/foundation', loadChildren: () => import('./pages/custom/foundation/foundation.module').then(m => m.FoundationPageModule), canActivate: [AuthGuard] },
  { path: 'admin/mfBooking', loadChildren: () => import('./pages/custom/mf-order-booking/mf-order-booking.module').then(m => m.MfOrderBookingModule), canActivate: [AuthGuard] },
  { path: 'admin/roles', loadChildren: () => import('./pages/admin/roles/roles.module').then(m => m.RolesModule), canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'admin/faqs', loadChildren: () => import('./pages/admin/faqs/faqs.module').then(m => m.FaqsModule), canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'admin/insurances', loadChildren: () => import('./pages/admin/insurances/insurances.module').then(m => m.InsurancesModule), canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'admin/financeProfiles', loadChildren: () => import('./pages/admin/financeProfiles/financeProfiles.module').then(m => m.FinanceProfilesModule), canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'admin/foundations', loadChildren: () => import('./pages/admin/foundations/foundations.module').then(m => m.FoundationsModule), canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'admin/users', loadChildren: () => import('./pages/admin/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard, AuthAdminGuard] },
  { path: 'admin/enumDetails', loadChildren: () => import('./pages/admin/enumDetails/enumDetails.module').then(m => m.EnumDetailsModule) },
  { path: 'admin/personalDetails', loadChildren: () => import('./pages/admin/personalDetails/personalDetails.module').then(m => m.PersonalDetailsModule) },
  { path: 'admin/bankDetails', loadChildren: () => import('./pages/admin/bankDetails/bankDetails.module').then(m => m.BankDetailsModule) },
  { path: 'admin/communicationDetails', loadChildren: () => import('./pages/admin/communicationDetails/communicationDetails.module').then(m => m.CommunicationDetailsModule) },
  { path: 'admin/bseRegistrations', loadChildren: () => import('./pages/admin/bseRegistrations/bseRegistrations.module').then(m => m.BseRegistrationsModule) },
  { path: 'admin/bseMFDetails', loadChildren: () => import('./pages/admin/bseMFDetails/bseMFDetails.module').then(m => m.BseMFDetailsModule) },
  { path: 'admin/mfSchemes', loadChildren: () => import('./pages/custom/mfSchemes/mfSchemes.module').then(m => m.MfSchemesCustomModule) },
  { path: 'admin/mfAdminOrders', loadChildren: () => import('./pages/admin/mfOrders/mfOrders.module').then(m => m.MfOrdersModule) },
  { path: 'admin/mfUserOrders', loadChildren: () => import('./pages/custom/mfUserOrders/mfOrders.module').then(m => m.MfOrdersModule) },
  { path: 'admin/mfBookCustom', loadChildren: () => import('./pages/custom/decide-mutual-fund/decide-mutual-fund.module').then(m => m.DecideMutualFundModule) },
  { path: 'admin/investment-report', loadChildren: () => import('./pages/custom/investment-report/investment-report.module').then(m => m.InvestmentReportModule) },
  { path: 'admin/documents', loadChildren: () => import('./pages/admin/documents/documents.module').then(m => m.DocumentsModule) },
  { path: 'admin/feedFolioDetails', loadChildren: () => import('./pages/admin/feedFolioDetails/feedFolioDetails.module').then(m => m.FeedFolioDetailsModule) },
  { path: 'admin/feedSipDetails', loadChildren: () => import('./pages/admin/feedSipDetails/feedSipDetails.module').then(m => m.FeedSipDetailsModule) },
  { path: 'admin/feedTransactions', loadChildren: () => import('./pages/admin/feedTransactions/feedTransactions.module').then(m => m.FeedTransactionsModule) },
  { path: 'admin/feedUploads', loadChildren: () => import('./pages/adminCustom/feedUploads/feedUploads.module').then(m => m.FeedUploadsModule) },
  { path: 'admin/configurations', loadChildren: () => import('./pages/admin/configurations/configurations.module').then(m => m.ConfigurationsModule) },
  { path: 'admin/clientInvestments', loadChildren: () => import('./pages/admin/clientInvestments/clientInvestments.module').then(m => m.ClientInvestmentsModule) },
  { path: 'admin/clientInvestmentSummaries', loadChildren: () => import('./pages/admin/clientInvestmentSummaries/clientInvestmentSummaries.module').then(m => m.ClientInvestmentSummariesModule) },
  { path: 'admin/feedNavs', loadChildren: () => import('./pages/admin/feedNavs/feedNavs.module').then(m => m.FeedNavsModule) },
  { path: 'admin/feedNavHistories', loadChildren: () => import('./pages/admin/feedNavHistories/feedNavHistories.module').then(m => m.FeedNavHistoriesModule) },
  { path: 'admin/feedMorStarApis', loadChildren: () => import('./pages/admin/feedMorStarApis/feedMorStarApis.module').then(m => m.FeedMorStarApisModule) },
  { path: 'admin/feedMfDetailMasters', loadChildren: () => import('./pages/admin/feedMfDetailMasters/feedMfDetailMasters.module').then(m => m.FeedMfDetailMastersModule) },
  { path: 'admin/mandates', loadChildren: () => import('./pages/admin/mandates/mandates.module').then(m => m.MandatesModule) },
  { path: 'admin/bucketStrategies', loadChildren: () => import('./pages/admin/bucketStrategies/bucketStrategies.module').then(m => m.BucketStrategiesModule) },
  { path: 'admin/platters', loadChildren: () => import('./pages/admin/platters/platters.module').then(m => m.PlattersModule) },
  { path: 'admin/miscs', loadChildren: () => import('./pages/admin/miscs/miscs.module').then(m => m.MiscsModule) },
  { path: 'admin/blogs', loadChildren: () => import('./pages/adminCustom/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'admin/globalNotifications', loadChildren: () => import('./pages/admin/globalNotifications/globalNotifications.module').then(m => m.GlobalNotificationsModule) },
  { path: 'admin/contactRequests', loadChildren: () => import('./pages/admin/contactRequests/contactRequests.module').then(m => m.ContactRequestsModule) },
  { path: 'admin/requestContacts', loadChildren: () => import('./pages/admin/requestContacts/requestContacts.module').then(m => m.RequestContactsModule) },
  { path: 'admin/gravytSeos', loadChildren: () => import('./pages/admin/gravytSeos/gravytSeos.module').then(m => m.GravytSeosModule) },
  { path: 'admin/magzines', loadChildren: () => import('./pages/adminCustom/magzines/magzines.module').then(m => m.MagzinesModule) },
  { path: 'admin/audits', loadChildren: () => import('./pages/admin/audits/audits.module').then(m => m.AuditsModule) },
  { path: 'admin/subscribers', loadChildren: () => import('./pages/admin/subscribers/subscribers.module').then(m => m.SubscribersModule) },
  { path: 'admin/distributorEnrollments', loadChildren: () => import('./pages/admin/distributorEnrollments/distributorEnrollments.module').then(m => m.DistributorEnrollmentsModule) },
  { path: 'admin/clientInvestmentPlatters', loadChildren: () => import('./pages/admin/clientInvestmentPlatters/clientInvestmentPlatters.module').then(m => m.ClientInvestmentPlattersModule) },
  { path: 'admin/clientInvestmentPortfolios', loadChildren: () => import('./pages/admin/clientInvestmentPortfolios/clientInvestmentPortfolios.module').then(m => m.ClientInvestmentPortfoliosModule) },
  { path: 'admin/commonDatas', loadChildren: () => import('./pages/admin/commonDatas/commonDatas.module').then(m => m.CommonDatasModule) },
  { path: 'admin/distributors', loadChildren: () => import('./pages/admin/distributors/distributors.module').then(m => m.DistributorsModule) },
  { path: 'admin/goalCalculators', loadChildren: () => import('./pages/admin/goalCalculators/goalCalculators.module').then(m => m.GoalCalculatorsModule) },
  { path: 'admin/gravytSiteMaps', loadChildren: () => import('./pages/admin/gravytSiteMaps/gravytSiteMaps.module').then(m => m.GravytSiteMapsModule) },
  { path: 'admin/kycs', loadChildren: () => import('./pages/adminCustom/kycs/kycs.module').then(m => m.KycsModule) },
  { path: 'admin/mfOrders', loadChildren: () => import('./pages/admin/mfOrders/mfOrders.module').then(m => m.MfOrdersModule) },
  { path: 'admin/otps', loadChildren: () => import('./pages/admin/otps/otps.module').then(m => m.OtpsModule) },
  { path: 'admin/clients', loadChildren: () => import('./pages/admin/clients/clients.module').then(m => m.ClientsModule) },
  { path: 'admin/dependents', loadChildren: () => import('./pages/admin/dependents/dependents.module').then(m => m.DependentsModule) },
  { path: 'admin/sipDetails', loadChildren: () => import('./pages/admin/sipDetails/sipDetails.module').then(m => m.SipDetailsModule) },
  { path: 'admin/watchLists', loadChildren: () => import('./pages/admin/watchLists/watchLists.module').then(m => m.WatchListsModule) },
  { path: 'admin/wbHomes', loadChildren: () => import('./pages/admin/wbHomes/wbHomes.module').then(m => m.WbHomesModule) },
  { path: 'admin/wbLiabilities', loadChildren: () => import('./pages/admin/wbLiabilities/wbLiabilities.module').then(m => m.WbLiabilitiesModule) },
  { path: 'admin/wbPersonalAccidents', loadChildren: () => import('./pages/admin/wbPersonalAccidents/wbPersonalAccidents.module').then(m => m.WbPersonalAccidentsModule) },
  { path: 'admin/wbVehicles', loadChildren: () => import('./pages/admin/wbVehicles/wbVehicles.module').then(m => m.WbVehiclesModule) },
  { path: 'admin/wbInsurances', loadChildren: () => import('./pages/admin/wbInsurances/wbInsurances.module').then(m => m.WbInsurancesModule) },
  { path: 'admin/wbHealths', loadChildren: () => import('./pages/admin/wbHealths/wbHealths.module').then(m => m.WbHealthsModule) },
  { path: 'admin/emailSubscriberAudits', loadChildren: () => import('./pages/admin/emailSubscriberAudits/emailSubscriberAudits.module').then(m => m.EmailSubscriberAuditsModule) },
  { path: 'admin/googleDriveBackups', loadChildren: () => import('./pages/admin/googleDriveBackups/googleDriveBackups.module').then(m => m.GoogleDriveBackupsModule) },
  { path: 'admin/unsubscribers', loadChildren: () => import('./pages/admin/unsubscribers/unsubscribers.module').then(m => m.UnsubscribersModule) },
  { path: 'admin/fundFilterDatas', loadChildren: () => import('./pages/admin/fundFilterDatas/fundFilterDatas.module').then(m => m.FundFilterDatasModule) },
  { path: 'admin/emailBulkAudits', loadChildren: () => import('./pages/admin/emailBulkAudits/emailBulkAudits.module').then(m => m.EmailBulkAuditsModule) },
  { path: 'admin/instantEmails', loadChildren: () => import('./pages/adminCustom/instantEmails/instantEmails.module').then(m => m.InstantEmailsModule) },
  { path: 'admin/pushNotificationMappers', loadChildren: () => import('./pages/admin/pushNotificationMappers/pushNotificationMappers.module').then(m => m.PushNotificationMappersModule) },
  { path: 'admin/pushNotificationMessages', loadChildren: () => import('./pages/adminCustom/pushNotificationMessages/pushNotificationMessages.module').then(m => m.PushNotificationMessagesModule) },
  { path: 'admin/razorPays', loadChildren: () => import('./pages/admin/razorPays/razorPays.module').then(m => m.RazorPaysModule) },
  { path: 'admin/distributorRiskProfiles', loadChildren: () => import('./pages/admin/distributorRiskProfiles/distributorRiskProfiles.module').then(m => m.DistributorRiskProfilesModule) },
  { path: 'admin/distributorFees', loadChildren: () => import('./pages/admin/distributorFees/distributorFees.module').then(m => m.DistributorFeesModule) },
  { path: 'admin/influencers', loadChildren: () => import('./pages/admin/influencers/influencers.module').then(m => m.InfluencersModule) },
  { path: 'admin/influencerPromoCodes', loadChildren: () => import('./pages/admin/influencerPromoCodes/influencerPromoCodes.module').then(m => m.InfluencerPromoCodesModule) },
  { path: 'admin/edExpenses', loadChildren: () => import('./pages/admin/edExpenses/edExpenses.module').then(m => m.EdExpensesModule) },
  { path: 'admin/portfolioUserInfoes', loadChildren: () => import('./pages/admin/portfolioUserInfoes/portfolioUserInfoes.module').then(m => m.PortfolioUserInfoesModule) },
  { path: 'admin/portfolioUserInvestmentInfoes', loadChildren: () => import('./pages/admin/portfolioUserInvestmentInfoes/portfolioUserInvestmentInfoes.module').then(m => m.PortfolioUserInvestmentInfoesModule) },
  { path: 'admin/allDocumentUploads', loadChildren: () => import('./pages/admin/allDocumentUploads/allDocumentUploads.module').then(m => m.AllDocumentUploadsModule) },
// <<route-path-admin>>
  // End Admin Route
  //{ path: "home", loadChildren: () => import("./pages/auth/home/home.module").then((m) => m.HomePageModule), },
  //{ path: "home", redirectTo: "platters/home" },
  //  { path: "home", loadChildren: () => import("./pages/auth/home/home.module").then((m) => m.HomePageModule), },
  { path: "email-verification", loadChildren: () => import("./pages/auth/email-verification/email-verification.module").then((m) => m.EmailVerificationPageModule), canActivate: [AuthGuard], },
  { path: "stage", loadChildren: () => import("./pages/custom/stage/stage.module").then((m) => m.StagePageModule), canActivate: [AuthGuard], },
  { path: "profile", loadChildren: () => import("./pages/custom/userprofile/userprofile.module").then((m) => m.UserprofilePageModule), canActivate: [AuthGuard], },
  { path: 'userEdit', loadChildren: () => import('./pages/custom/users/users.module').then(m => m.UsersModule) },
  { path: "foundation", loadChildren: () => import("./pages/custom/foundation/foundation.module").then((m) => m.FoundationPageModule) },
  { path: "mfBooking", loadChildren: () => import("./pages/custom/mf-order-booking/mf-order-booking.module").then((m) => m.MfOrderBookingModule), canActivate: [AuthGuard], },
  { path: "mfEmergencyBooking", loadChildren: () => import("./pages/custom/mf-emergency-order-booking/mf-order-booking.module").then((m) => m.MfOrderBookingModule), canActivate: [AuthGuard], },
  { path: "roles", loadChildren: () => import("./pages/admin/roles/roles.module").then((m) => m.RolesModule), canActivate: [AuthGuard, AuthAdminGuard], },
  { path: "faqs", loadChildren: () => import("./pages/admin/faqs/faqs.module").then((m) => m.FaqsModule), canActivate: [AuthGuard, AuthAdminGuard], },
  { path: "insurances", loadChildren: () => import("./pages/admin/insurances/insurances.module").then((m) => m.InsurancesModule), canActivate: [AuthGuard, AuthAdminGuard], },
  { path: "financeProfiles", loadChildren: () => import("./pages/admin/financeProfiles/financeProfiles.module").then((m) => m.FinanceProfilesModule), canActivate: [AuthGuard, AuthAdminGuard], },
  { path: "dependents", loadChildren: () => import("./pages/custom/dependents/dependents.module").then((m) => m.DependentsModule), canActivate: [AuthGuard], },
  { path: 'mandates', loadChildren: () => import('./pages/custom/mandates/mandates.module').then(m => m.MandatesModule) },
  { path: "home", loadChildren: () => import("./pages/custom/home/home.module").then((m) => m.HomeModule), },
  { path: "dashboard", loadChildren: () => import("./pages/custom/dashboard/home.module").then((m) => m.HomeModule), },
  { path: 'riskProfile', loadChildren: () => import('./pages/custom/riskProfile/riskProfile.module').then(m => m.RiskProfilePageModule) },
  { path: "createPlatter", loadChildren: () => import("./pages/custom/create-platter/create-platter.module").then((m) => m.CreatePlatterModule), },
  { path: "foundations", loadChildren: () => import("./pages/admin/foundations/foundations.module").then((m) => m.FoundationsModule), canActivate: [AuthGuard, AuthAdminGuard], },
  { path: "users", loadChildren: () => import("./pages/admin/users/users.module").then((m) => m.UsersModule), canActivate: [AuthGuard, AuthAdminGuard], },
  { path: "enumDetails", loadChildren: () => import("./pages/admin/enumDetails/enumDetails.module").then((m) => m.EnumDetailsModule), },
  {
    path: "personalDetails",
    loadChildren: () =>
      import("./pages/admin/personalDetails/personalDetails.module").then(
        (m) => m.PersonalDetailsModule
      ),
  },
  {
    path: "bankDetails",
    loadChildren: () =>
      import("./pages/admin/bankDetails/bankDetails.module").then(
        (m) => m.BankDetailsModule
      ),
  },
  {
    path: "communicationDetails",
    loadChildren: () =>
      import(
        "./pages/admin/communicationDetails/communicationDetails.module"
      ).then((m) => m.CommunicationDetailsModule),
  },
  {
    path: "bseMFDetails",
    loadChildren: () =>
      import("./pages/admin/bseMFDetails/bseMFDetails.module").then(
        (m) => m.BseMFDetailsModule
      ),
  },
  {
    path: "mfSchemes",
    loadChildren: () =>
      import("./pages/custom/mfSchemes/mfSchemes.module").then(
        (m) => m.MfSchemesCustomModule
      ),
  },
  {
    path: "mfAdminOrders",
    loadChildren: () =>
      import("./pages/admin/mfOrders/mfOrders.module").then(
        (m) => m.MfOrdersModule
      ),
  },
  {
    path: "mfUserOrders",
    loadChildren: () =>
      import("./pages/custom/mfUserOrders/mfOrders.module").then(
        (m) => m.MfOrdersModule
      ),
  },
  {
    path: "mfBookCustom",
    loadChildren: () =>
      import(
        "./pages/custom/decide-mutual-fund/decide-mutual-fund.module"
      ).then((m) => m.DecideMutualFundModule),
  },
  {
    path: "investment-report",
    loadChildren: () =>
      import("./pages/custom/investment-report/investment-report.module").then(
        (m) => m.InvestmentReportModule
      ),
  },
  {
    path: "wbHealths",
    loadChildren: () =>
      import("./pages/custom/wbHealths/wbHealths.module").then(
        (m) => m.WbHealthsModule
      ),
  },
  {
    path: "wbInsurances",
    loadChildren: () =>
      import("./pages/custom/wbInsurances/wbInsurances.module").then(
        (m) => m.WbInsurancesModule
      ),
  },
  {
    path: "documents",
    loadChildren: () =>
      import("./pages/user/documents/documents.module").then(
        (m) => m.DocumentsModule
      ),
  },
  {
    path: "wbHomes",
    loadChildren: () =>
      import("./pages/custom/wbHomes/wbHomes.module").then(
        (m) => m.WbHomesModule
      ),
  },
  {
    path: "wbLifeInsurances",
    loadChildren: () =>
      import("./pages/custom/wbLifeInsurances/wbLifeInsurances.module").then(
        (m) => m.WbLifeInsurancesModule
      ),
  },
  {
    path: "wbPersonalAccidents",
    loadChildren: () =>
      import(
        "./pages/custom/wbPersonalAccidents/wbPersonalAccidents.module"
      ).then((m) => m.WbPersonalAccidentsModule),
  },
  {
    path: "wbVehicles",
    loadChildren: () =>
      import("./pages/custom/wbVehicles/wbVehicles.module").then(
        (m) => m.WbVehiclesModule
      ),
  },
  {
    path: "wbLiabilities",
    loadChildren: () =>
      import("./pages/custom/wbLiabilities/wbLiabilities.module").then(
        (m) => m.WbLiabilitiesModule
      ),
  },
  {
    path: "feedFolioDetails",
    loadChildren: () =>
      import("./pages/custom/feedFolioDetails/feedFolioDetails.module").then(
        (m) => m.FeedFolioDetailsModule
      ),
  },
  {
    path: "feedSipDetails",
    loadChildren: () =>
      import("./pages/custom/feedSipDetails/feedSipDetails.module").then(
        (m) => m.FeedSipDetailsModule
      ),
  },
  {
    path: "feedTransactions",
    loadChildren: () =>
      import("./pages/custom/feedTransactions/feedTransactions.module").then(
        (m) => m.FeedTransactionsModule
      ),
  },
  {
    path: "clientInvestments",
    loadChildren: () =>
      import("./pages/custom/clientInvestments/clientInvestments.module").then(
        (m) => m.ClientInvestmentsModule
      ),
  },
  {
    path: "clientInvestmentSummaries",
    loadChildren: () =>
      import(
        "./pages/user/clientInvestmentSummaries/clientInvestmentSummaries.module"
      ).then((m) => m.ClientInvestmentSummariesModule),
  },
  {
    path: "wealth-bag",
    loadChildren: () =>
      import("./pages/custom/wealth-bag/wealth-bag.module").then(
        (m) => m.WealthBagModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/custom/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/custom/register/register.module").then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./pages/custom/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  { path: 'futureChildPlan', loadChildren: () => import('./pages/custom/future-child-plan/future-child-plan.module').then(m => m.FutureChildPlanModule) },
  { path: 'goalCalculators/:type', loadChildren: () => import('./pages/custom/goalCalculators/goalCalculators.module').then(m => m.GoalCalculatorsModule) },
  { path: 'goalCalculators', loadChildren: () => import('./pages/custom/goalCalculators/goalCalculators.module').then(m => m.GoalCalculatorsModule) },
  { path: 'bucketStrategies', loadChildren: () => import('./pages/custom/bucketStrategies/bucketStrategies.module').then(m => m.BucketStrategiesModule) },
  { path: 'fundBox', loadChildren: () => import('./pages/custom/platters/platters.module').then(m => m.PlattersModule) },
  { path: 'blogs', loadChildren: () => import('./pages/custom/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'globalNotifications', loadChildren: () => import('./pages/user/globalNotifications/globalNotifications.module').then(m => m.GlobalNotificationsModule) },
  // { path: 'mfTop/:type', loadChildren: () => import('./pages/custom/mfTopList/mfTopList.module').then(m => m.MfTopListModule) },
  { path: 'mfTop', loadChildren: () => import('./pages/custom/mfTopList/mfTopList.module').then(m => m.MfTopListModule) },
  { path: 'contact-us', loadChildren: () => import('./pages/custom/contact/contact.module').then(m => m.ContactModule) },
  { path: 'bseRegistrations', loadChildren: () => import('./pages/custom/bseRegistrations/bseRegistrations.module').then(m => m.BseRegistrationsModule) },
  { path: 'search', loadChildren: () => import('./pages/custom/mfNameSearch/mfNameSearch.module').then(m => m.MfNameSearchModule) },
  { path: 'onboarding', loadChildren: () => import('./pages/custom/onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'watchLists', loadChildren: () => import('./pages/custom/watchLists/watchLists.module').then(m => m.WatchListsModule) },

  { path: 'mfOrders', loadChildren: () => import('./pages/custom/mfOrders/mfOrders.module').then(m => m.MfOrdersModule) },
  { path: 'sipDetails', loadChildren: () => import('./pages/custom/sipDetails/sipDetails.module').then(m => m.SipDetailsModule) },
  { path: 'users', loadChildren: () => import('./pages/custom/users/users.module').then(m => m.UsersModule) },
  { path: 'kycs', loadChildren: () => import('./pages/custom/kycs/kycs.module').then(m => m.KycsModule) },
  { path: 'faq', loadChildren: () => import('./pages/custom/faq/faq.module').then(m => m.FaqModule) },
  { path: 'dashboard/faqs', loadChildren: () => import('./pages/custom/faqs/faqs.module').then(m => m.FaqsModule) },

  { path: 'distributors', loadChildren: () => import('./pages/user/distributors/distributors.module').then(m => m.DistributorsModule) },
  {
    path: 'blog/:name',
    loadChildren: () => import('./pages/custom/blogs-info/blogs-info.module').then(m => m.BlogsInfoModule),
    data: { activeTabs: 'blog/:name' }
  },
  {
    path: 'reach-us',
    loadChildren: () => import('./pages/custom/reach-us/reach-us.module').then(m => m.ReachUsModule),
    data: { activeTabs: 'reach-us' }
  },
  {
    path: 'e-magazine',
    loadChildren: () => import('./pages/custom/magazine/magazine.module').then(m => m.MagazineModule),
    data: { activeTabs: 'e-magazine' }
  },
  {
    path: 'e-magazine/:name',
    loadChildren: () => import('./pages/custom/magazine-details/magazine-details.module').then(m => m.MagazineDetailsModule),
    data: { activeTabs: 'e-magazine' }
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./pages/custom/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
    data: { activeTabs: 'terms-conditions' }
  },
  {
    path: 'refund-policy',
    loadChildren: () => import('./pages/custom/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule),
    data: { activeTabs: 'refund-policy' }
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/custom/pricing/pricing.module').then(m => m.PricingModule),
    data: { activeTabs: 'pricing' }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/custom/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    data: { activeTabs: 'privacy-policy' }
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/custom/about/about.module').then(m => m.AboutModule),
    data: { activeTabs: 'about' }
  },
  { path: 'funds', loadChildren: () => import('./pages/custom/feedMorStarApis/feedMorStarApis.module').then(m => m.FeedMorStarApisModule) },
  { path: 'feedMorStarApis', loadChildren: () => import('./pages/custom/feedMorStarApis/feedMorStarApis.module').then(m => m.FeedMorStarApisModule) },
  { path: 'requestContacts', loadChildren: () => import('./pages/custom/requestContacts/requestContacts.module').then(m => m.RequestContactsModule) },
  { path: 'edExpenses', loadChildren: () => import('./pages/user/edExpenses/edExpenses.module').then(m => m.EdExpensesModule) },
  { path: 'portfolioUserInfoes', loadChildren: () => import('./pages/custom/portfolioUserInfoes/portfolioUserInfoes.module').then(m => m.PortfolioUserInfoesModule) },
  { path: 'portfolioUserInvestmentInfoes', loadChildren: () => import('./pages/custom/portfolioUserInvestmentInfoes/portfolioUserInvestmentInfoes.module').then(m => m.PortfolioUserInvestmentInfoesModule) },
  // <<route-path-user>>
  // End User Route
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'top'
    })],
  exports: [RouterModule],
})
export class AppRoutingModule { }


