import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EdExpense } from '../../../../core/model/edExpense';
import { ActivatedRoute, Router } from '@angular/router';
import { EdExpensesService } from '../edExpenses.service';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { LookupService } from '../../../../shared/lookupService';
import { BaseTemplateEditComponent } from '../../../../shared/baseTemplateEditComponent';




export class EdExpensesBaseEditComponent extends BaseTemplateEditComponent<EdExpense> {
    navigatePath = 'edExpenses';

    
listFrequency: any[];
 listCategory: any[];

    
calendarExpenseDate: Date =null;
 selectedFrequency: any =null;
 selectedCategory: any =null;

    createForm(id: number, isDialog: boolean = false) {
        this.editId = id;
        this.isDialog = isDialog;

        if (id > 0) {
            this.getData(id);
            // this.bindDropDown() => Added in the Subscribe method.
        } else {
            this.item = {};
            this.model = { ...this.item };
            this.bindDropDown();
        }
    }

    bindDropDown() {
        
this.lookupService.getDataEnum('ExpenseFrequency').subscribe(list => {
      this.listFrequency = list;
      if (this.item && this.item.frequency) {
        const selectedList = list.filter(a => a.value === this.item.frequency);
        if (selectedList.length) {
            this.selectedFrequency = selectedList[0];
        }
      }
    });
 this.lookupService.getDataEnum('ExpenseCategory').subscribe(list => {
      this.listCategory = list;
      if (this.item && this.item.category) {
        const selectedList = list.filter(a => a.value === this.item.category);
        if (selectedList.length) {
            this.selectedCategory = selectedList[0];
        }
      }
    });
    }

    setDateCalenderValues(item) {
        
if (item.expenseDate) {
    this.calendarExpenseDate = new Date(item.expenseDate);
}
        return item;
    }

    preSubmit(itemValue) {
        if (typeof (itemValue.userId) !== 'undefined') {
            itemValue.userId = this.service.helperSvc.loggedInUser.id;
        }
        return itemValue;
    }
}



@Component({
    selector: 'app-edExpenses-edit',
    templateUrl: './edExpenses-edit.component.html',
    styleUrls: ['./edExpenses-edit.component.scss'],
    providers: [ConfirmationService]
})
export class EdExpensesEditComponent extends EdExpensesBaseEditComponent implements OnInit {
    edExpenseForm: FormGroup;

    constructor(private route: ActivatedRoute,
        public edExpensesService: EdExpensesService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(edExpensesService, router, formBuilder, confirmationService, lookupService);
    }

    ngOnInit() {
        this.ionViewWillEnter();
    }
    ionViewWillEnter() {

        const idText = this.route.snapshot.paramMap.get('id');

        let id = 0;
        if (idText.toLowerCase() !== 'add') {
            id = +this.route.snapshot.paramMap.get('id');
        }
        this.createForm(id);
    }
}


@Component({
    templateUrl: './edExpenses-edit.component.html',
    styleUrls: ['./edExpenses-edit.component.scss']
})
export class EdExpensesEditDialogComponent extends EdExpensesBaseEditComponent implements OnInit {
    constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
        public edExpensesService: EdExpensesService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(edExpensesService, router, formBuilder, confirmationService, lookupService);
    }
    ngOnInit() {
        this.ionViewWillEnter();
    }
    ionViewWillEnter() {

        const id = +this.config.data.id;
        this.createForm(id, true);
    }

    navigateHome() {
        this.ref.close('refresh');
    }
}



