import { Injectable } from '@angular/core';
import { Mandate } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class MandatesService extends BaseService<Mandate> {
    constructor() {
        super('Mandate');
    }
}
