import { HelperService } from '../../../../../../src/app/shared/helperService';

import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { BseRegistration } from "../../../../core/model/bseRegistration";
import { ActivatedRoute, Router } from "@angular/router";
import { BseRegistrationsService } from "../bseRegistrations.service";
import { ConfirmationService } from "primeng/api";
import { LookupService } from "../../../../shared/lookupService";
import { BaseEditComponent } from "../../../../shared/baseEditComponent";
import { BseRegistrationsBaseEditComponent as CustomComponent } from "../../../user/bseRegistrations/bseRegistrations-edit/bseRegistrations-edit.component";
import { KycsService } from "../../kycs/kycs.service";
import { Kyc, User } from "../../../../../../src/app/core/model";
import { UsersService } from "../../../../../../src/app/core/service";
import { MessageService } from 'primeng/api';
import { UserProfilePageService } from "../../userprofile/userprofile.page.service";
import * as moment from 'moment';
import { UsersEditDialogComponent } from '../../users/users-edit/users-edit.component';
import { DialogService } from 'primeng';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-bseRegistrations-edit',
  templateUrl: './bseRegistrations-edit.component.html',
  styleUrls: ['./bseRegistrations-edit.component.scss'],
  providers: [ConfirmationService,DialogService]
})
export class BseRegistrationsEditComponent extends CustomComponent implements OnInit {
  bseRegistration: BseRegistration;
  currentStep: number;
  isCompleted: boolean = false;
  isAddNomineeVisible = false;
  kyc: Kyc = null;
  error = '';
  stages = [
    "PAN <br/> Verification",
    "Personal <br/> Details",
    "Nominee <br/> Details",
    "Bank <br/> Details",
    "Address",
    "Complete Onboarding",
  ];
  hasUserFilter = true;
  showConfirmation: boolean;
  validNomineeAllocation = false;
  data: any;
  validChequeFile = false;
  isRepeatpPanNo=false;
  validSignFile = false;
  isAlreadyPan = false;
  userData: User;
  panNoConfirm='';
  userId: any;
  constructor(
    private route: ActivatedRoute,
    public helperService: HelperService,
    public bseRegistrationsService: BseRegistrationsService,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService,
    private kycsService: KycsService,
    private usersService: UsersService,
    public messageService: MessageService,
    public userProfilePageService: UserProfilePageService,
    public dialogService: DialogService
  ) {
    super(bseRegistrationsService, router, formBuilder, confirmationService, lookupService);
  }


  ngOnInit() {
    let id = 0;
    this.loading=true;

    console.log('this.loading',this.loading);
    this.bseRegistrationsService
      .getAll(true)
      .subscribe((bseRegistration: BseRegistration[]) => {
        this.bseRegistration =
          bseRegistration.length > 0 ? bseRegistration[0] : null;
        if (this.bseRegistration && this.bseRegistration.id > 0) {
          id = this.bseRegistration.id;
          const loggedInUser =
      this.userProfilePageService.helperSvc.getLoggedInUserInfo();
      this.userId=this.bseRegistration.userId;
        }
        this.createForm(id);
        if (id <= 0) {
          setTimeout(() => {
            const userInfo = this.bseRegistrationsService.helperSvc.getLoggedInUserInfo();
            this.item = this.item || { defaultBank: false };
            this.item.name = userInfo.name;
            this.item.panNumber = userInfo.panNo;
            this.item.emailId = userInfo.emailId;
            this.item.mobileNo = userInfo.mobile;
            this.item.countryEnum = "IN";
            this.item.countryEnumLabel = "India";
            this.item.birthCountryEnum = "IN";
            this.item.birthCountryEnumLabel = "India";
            this.item.holdingNatureEnum = "SI";
            this.item.holdingNatureEnumLabel = "Single";
            this.item.taxStatusEnum = "01";
            this.item.taxStatusEnumLabel = "Individual";
            this.item.incomeSlabEnum = "32";
            this.item.incomeSlabEnumLabel = "> 1 <=5 Lacs";
            //this.itemForm.patchValue(this.item);
            this.model = { ...this.item };
          }, 200);
        }
      });

    this.kycsService.getAll(true).subscribe((kyc: Kyc[]) => {
      if (kyc.length) {
        this.kyc = kyc[0];
        this.panNoConfirm = this.kyc.panNo;
      }
    });
    this.loading=false;
    this.currentStep = -1; //TODO reset to 0
  }

  preSubmit(itemValue) {
    if (itemValue) {
      itemValue.isCompleteAddressDetails = true;
      itemValue.ifscCode = itemValue.ifscCode.toUpperCase();
      itemValue.userId = this.service.helperSvc.loggedInUser.id;
      this.isCompleted = true;
    }
    return itemValue;
  }

  navigateHome() {
    this.error = '';
    this.showConfirmation = true;

    // this.usersService.get(this.usersService.helperSvc.loggedInUser.id).subscribe(data => {
    //   this.usersService.helperSvc.setUserInfo(data);
    //   this.showConfirmation = false;

    //   setTimeout(() => {
    //     // this.currentStep = -1;
    //     this.showConfirmation = false;
    //     // let nomineeUrl = environment.apiUrlBase + "Nominee2Fa/Index/" + data.clientCode;
    //     let nomineeUrl = "http://localhost:50572/Nominee2Fa/Index/" + data.clientCode;

    //     window.location.href=nomineeUrl;
    //   }, 10);
    // })
    this.bseRegistrationsService
      .getAll(true)
      .subscribe((bseRegistration: BseRegistration[]) => {
        this.bseRegistration =
          bseRegistration.length > 0 ? bseRegistration[0] : null;
        this.item = this.bseRegistration;
        this.model = this.item;

        //this.item.registrationStatus == "Success"

        console.log('this.model.registrationStatus',this.model.registrationStatus);

        if (this.model.registrationStatus == "Success") {
          this.showConfirmation = true;
          console.log('this.item.registrationStatus',this.model.registrationStatus, this.showConfirmation);

          this.confirmationService.confirm({

            accept: () => {
              //Actual logic to perform a confirmation

              this.usersService.get(this.usersService.helperSvc.loggedInUser.id).subscribe(data => {
                this.usersService.helperSvc.setUserInfo(data);
                this.showConfirmation = false;

                setTimeout(() => {
                  // this.currentStep = -1;
                  this.showConfirmation = false;
                  // let nomineeUrl = environment.apiUrlBase + "Nominee2Fa/Index/" + data.clientCode;
                  // window.location.href=nomineeUrl;
                  if(this.model.nominee2FaUrl){
                    let nomineeUrl = environment.apiUrlBase + "Nominee2Fa/Index/" + data.clientCode;

                    window.location.href=nomineeUrl;
                  }

                }, 10);
              })

            },

          });
        } else {
          this.error = this.item.registrationStatus;
        }
      });
  }
  goToNominee2Fa(){
    this.usersService.get(this.usersService.helperSvc.loggedInUser.id).subscribe(data => {
      this.usersService.helperSvc.setUserInfo(data);
      this.showConfirmation = false;

      setTimeout(() => {
        // this.currentStep = -1;
        this.showConfirmation = false;
        // let nomineeUrl = environment.apiUrlBase + "Nominee2Fa/Index/" + data.clientCode;
        // window.location.href=nomineeUrl;
        if(this.model.nominee2FaUrl){
          let nomineeUrl = environment.apiUrlBase + "Nominee2Fa/Index/" + data.clientCode;

          window.location.href=nomineeUrl;
        }

      }, 10);
    })
  }
  navigateTo(url) {
    this.bseRegistrationsService.helperSvc.routeNavigate(url);
  }

  registerKYC() {
    if (this.kyc.panNo == this.model.panNumber) {
      if (this.kyc.kycStatus != "Success" && this.kyc.kycRegRequestInfo) {
        this.redirectVideoKyc(this.kyc.kycRegRequestInfo);
        this.isAlreadyPan = false;
      } else {
        this.checkKycData();
      }
    } else {
      this.checkKycData();
    }



  }

  checkKycData() {
    this.kyc.panNo = this.model.panNumber;
  this.loading=true;
    this.kycsService.checkUserKycPanNo(this.kyc).subscribe((kyc) => {
      this.kyc = kyc;
      if (this.kyc.kycStatus == "Success") {
        //Click next, draft save
        this.next();
        this.isAlreadyPan = false;
        this.loading=false;
      } else if (this.kyc.kycStatus == "kyc_exist") {
        this.isAlreadyPan = true;
        this.loading=false;
      } else {
        this.loading=false;
        if (this.kyc.kycRegRequestInfo) {
          this.redirectVideoKyc(this.kyc.kycRegRequestInfo);
        } else {
          this.loading=false;
          this.kycsService.registerKyc(this.kyc).subscribe((kyc) => {
            this.kyc = kyc;
            if (this.kyc.kycRegRequestInfo) {
              this.redirectVideoKyc(this.kyc.kycRegRequestInfo);
            }
          });
        }
      }
    });
  }
  async redirectVideoKyc(kycRegRequestInfo) {
    const kycRegRequest = JSON.parse(kycRegRequestInfo);
    // window.location.href = kycRegRequest.createdObj.autoLoginUrL;
    // const browser = this.iab.create(
    //   kycRegRequest.createdObj.autoLoginUrL,
    //   "_blank",
    //   "hideurlbar=yes,hidenavigationbuttons=yes,location=yes"
    // );
   // window.open({url:kycRegRequest.createdObj.autoLoginUrL});
    //await BrowserModule.open({ url: kycRegRequest.createdObj.autoLoginUrL });


    const url = kycRegRequest.createdObj.autoLoginUrL;

    window.open(url, '_blank');
  }

  prev(): void {
    if (this.currentStep > 0) {
      this.currentStep = this.currentStep - 1;
    }
  }

  next(): void {

    this.userProfilePageService.getAll().subscribe((data: User[]) => {
      this.userData = data[0];
      if (!this.userData.emailVerified) {
        this.loading=true;
        this.messageService.add({ severity: 'error', detail: 'Email is not verified', life: 10000 });
        this.helperService.redirectToOnboarding = true;
        setTimeout(() => {
          this.loading=false;
          this.openUsersDetails();
          //this.navigateTo("/userEdit");
        }, 4000);

      }
      else if (!this.userData.mobileVerified) {
        this.loading=true;
        this.messageService.add({ severity: 'error', detail: 'Mobile is not verified', life: 10000 });
        this.helperService.redirectToOnboarding = true;
        setTimeout(() => {
          this.loading=false;
          this.openUsersDetails();
        //  this.navigateTo("/userEdit");
        }, 4000);

      }
      else {
        if (this.currentStep >= 0) {

          switch (this.currentStep) {
            case 0:
              this.model.isCompletePanDetails = true;
              break;
            case 1:
              this.model.birthDate= (moment(this.model.birthDate)).toDate(),
              this.model.isCompletePersonalDetails = true;
              break;
            case 2:
              this.model.isCompleteNomineeDetails = true;
              break;
            case 3:
              this.model.isCompleteBankDetails = true;
              break;
          }
          this.bseRegistrationsService.draft(this.model).subscribe((data) => {
            this.data = data;
          });
        }
        if (this.currentStep >= 4) {
          this.currentStep = 4;
        } else {
          this.currentStep = this.currentStep + 1;
        }
      }
    });
  }
  openUsersDetails() {
    const ref = this.dialogService.open(UsersEditDialogComponent, {
      data: {
        id:this.userId,
        type: 'user'
      },
      header: 'User Details',
      width: '50%'
  });
  ref.onClose.subscribe((data: any) => {
    if (data) {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });

    }
  });
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.itemForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  checkInvalid(): void {
    const invalidList = this.findInvalidControls();

  }

  toggleAddNominee(): void {
    this.isAddNomineeVisible = !this.isAddNomineeVisible;
  }

  validateNext() {
    let controls = [];
    switch (this.currentStep) {
      case 0:
        controls = ["panNumber"];
        break;
      case 1:
        controls = [
          "name",
          "genderEnum",
          "sourceWealthEnum",
          "occupationEnum",
          "birthPlace",
          "signatureFile",
          "birthDate"
        ];
        break;
      case 2:
        //controls = ['nomineeName', 'nomineeRelationEnum', 'nomineeAllocation', 'nomineeMinor', 'nomineeBirthDate', 'nomineeGuardianName'];
        controls = [
          "nomineeName",
          "nomineeRelationEnum",
          "nomineeAllocation",
          "nomineeMinor",
          "nomineeBirthDate",
          "nomineeGuardianName",
          "nominee2Name",
          "nominee2RelationEnum",
          "nominee2Allocation",
          "nominee2Minor",
          "nominee2BirthDate",
          "nominee2GuardianName",
        ];
        break;
      case 3:
        controls = [
          "ifscCode",
          "bankName",
          "branchName",
          "accountNo",
          "accountNoConfirm",
          "chequeFile",
        ];
        break;

      case 4:
        controls = ["address1", "address2", "pincode", "city", "stateEnum"];
        break;
    }
    let valid = this.service.helperSvc.checkValidControls(
      this.itemForm,
      controls
    );
    if (valid && this.currentStep == 0) {
      const panNoConfirm = this.panNoConfirm && this.panNoConfirm.toUpperCase()==this.model.panNumber.toUpperCase() && this.panNoConfirm.length==10;
       this.isRepeatpPanNo = panNoConfirm;
      // console.log("this.isRepeatpPanNo",this.isRepeatpPanNo,this.panNoConfirm,);
    }
    this.validChequeFile = true;
    this.validSignFile = true;
    this.validNomineeAllocation = true;
    if (valid && this.currentStep == 2) {
      valid = this.validateNomineeSection();
    }
    if (valid && this.currentStep == 3) {
      valid = this.model.chequeFile.length > 10;
      this.validChequeFile = valid;
    }
    if (valid && this.currentStep == 1) {
      valid = this.model.signatureFile.length > 10;
      this.validSignFile = valid;
    }
    //console.log('Valid', valid);
    return valid;
  }

  validateNomineeSection() {

    if (this.model.nomineeAllocation + (this.model.enableNominee2 ? this.model.nominee2Allocation : 0) == 100) {
      this.validNomineeAllocation = true;
      return true;
    } else {
      this.validNomineeAllocation = false;
      return false;
    }
  }
  confirm() {
    this.error = '';
    if(!this.model.nomineeLogoutUrl){
      //this.model.nomineeResponse= "http://localhost:50572/Nominee2Fa/Nominee2FaResponse/" + btoa(location.origin + "/bseRegistrations");
      // this.model.nomineeResponse= environment.apiUrlBase + "Nominee2Fa/Nominee2FaResponse/" + btoa(location.origin + "/bseRegistrations#" + btoa(this.model.clientCode));
      this.model.nomineeLogoutUrl= environment.apiUrlBase + "Nominee2Fa/Nominee2FaResponse/" + btoa(location.origin + "/bseRegistrations");
    }

    this.formSubmit();
  }

}
