import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, catchError, switchMap, retry, filter } from 'rxjs/operators';
import { AppInjector } from '../../app-injector.service';
import { AsyncValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

import * as services from '../core/service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor() {
  }

  getData(type: string, userFilter: boolean = false) {
    const service = this.getService(type);
    if (service != null) {
      return service.getAll(userFilter) as Observable<any[]>;
    } else {
      return of([]);
    }
  }

  getDataEnum(type: string) {
    const service = this.getService('EnumDetail');
    if (service != null) {
      // return service.getAll(); // as Observable<any[]>;
      return (service.getAll() as Observable<any[]>).pipe(
        map(a => a.filter(b => b['section'] === type))
      );

    } else {
      return of([]);
    }
  }

  getLabelEnum(type: string, value: string) {
    const service = this.getService('EnumDetail');
    if (service != null) {
      // return service.getAll(); // as Observable<any[]>;
      return (service.getAll() as Observable<any[]>).pipe(
        map(a => a.filter(b => b['section'] === type && b['value'] === value))
      );

    } else {
      return of([]);
    }
  }

  searchData(type: string, search: any) {
    const service = this.getService(type);
    if (service != null) {
      return service.searchData(search) as Observable<any[]>;
    } else {
      return of([]);
    }
  }

  private getService(type: string) {
    let service: any = null;
    const injector = AppInjector.getInjector();
    //console.log('injector', injector);
    type = type[0].toLowerCase() + type.substring(1);
    switch (type) {
      case 'login':
        service = injector.get(services.LoginsService);
        break;
      case 'role':
        service = injector.get(services.RolesService);
        break;
      case 'faq':
        service = injector.get(services.FaqsService);
        break;
      case 'insurance':
        service = injector.get(services.InsurancesService);
        break;
      case 'financeProfile':
        service = injector.get(services.FinanceProfilesService);
        break;
      case 'dependent':
        service = injector.get(services.DependentsService);
        break;
      case 'foundation':
        service = injector.get(services.FoundationsService);
        break;
      case 'user':
        service = injector.get(services.UsersService);
        break;
      case 'enumDetail':
        service = injector.get(services.EnumDetailsService);
        break;
      case 'personalDetail':
        service = injector.get(services.PersonalDetailsService);
        break;
      case 'bankDetail':
        service = injector.get(services.BankDetailsService);
        break;
      case 'communicationDetail':
        service = injector.get(services.CommunicationDetailsService);
        break;
      case 'bseRegistration':
        service = injector.get(services.BseRegistrationsService);
        break;
      case 'bseMFDetail':
        service = injector.get(services.BseMFDetailsService);
        break;
      case 'mfScheme':
        service = injector.get(services.MfSchemesService);
        break;
      case 'mfOrder':
        service = injector.get(services.MfOrdersService);
        break;
      case 'wbHealth':
        service = injector.get(services.WbHealthsService);
        break;
       case 'wbInsurance':
service = injector.get(services.WbInsurancesService);
break;
 case 'document':
service = injector.get(services.DocumentsService);
break;
 case 'wbHome':
service = injector.get(services.WbHomesService);
break;
 case 'wbLifeInsurance':
service = injector.get(services.WbLifeInsurancesService);
break;
 case 'wbPersonalAccident':
service = injector.get(services.WbPersonalAccidentsService);
break;
 case 'wbVehicle':
service = injector.get(services.WbVehiclesService);
break;
 case 'wbLiability':
service = injector.get(services.WbLiabilitiesService);
break;
 case 'edExpense':
service = injector.get(services.EdExpensesService);
break;
 case 'edIncome':
service = injector.get(services.EdIncomesService);
break;
 case 'feedFolioDetail':
service = injector.get(services.FeedFolioDetailsService);
break;
 case 'feedSipDetail':
service = injector.get(services.FeedSipDetailsService);
break;
 case 'feedTransaction':
service = injector.get(services.FeedTransactionsService);
break;
 case 'feedUpload':
service = injector.get(services.FeedUploadsService);
break;
 case 'configuration':
service = injector.get(services.ConfigurationsService);
break;
 case 'clientInvestment':
service = injector.get(services.ClientInvestmentsService);
break;
 case 'clientInvestmentSummary':
service = injector.get(services.ClientInvestmentSummariesService);
break;
 case 'feedNav':
service = injector.get(services.FeedNavsService);
break;
 case 'feedNavHistory':
service = injector.get(services.FeedNavHistoriesService);
break;
 case 'feedMorStarApi':
service = injector.get(services.FeedMorStarApisService);
break;
 case 'feedMfDetailMaster':
service = injector.get(services.FeedMfDetailMastersService);
break;
 case 'mandate':
service = injector.get(services.MandatesService);
break;
 case 'goalCalculator':
service = injector.get(services.GoalCalculatorsService);
break;
 case 'bucketStrategy':
service = injector.get(services.BucketStrategiesService);
break;
 case 'platter':
service = injector.get(services.PlattersService);
break;
 case 'bseFatca':
service = injector.get(services.BseFatcasService);
break;
 case 'misc':
service = injector.get(services.MiscsService);
break;
 case 'blog':
service = injector.get(services.BlogsService);
break;
 case 'globalNotification':
service = injector.get(services.GlobalNotificationsService);
break;
 case 'sipDetail':
service = injector.get(services.SipDetailsService);
break;
 case 'kyc':
service = injector.get(services.KycsService);
break;
 case 'contactRequest':
service = injector.get(services.ContactRequestsService);
break;
 case 'requestContact':
service = injector.get(services.RequestContactsService);
break;
 case 'gravytSeo':
service = injector.get(services.GravytSeosService);
break;
 case 'magzine':
service = injector.get(services.MagzinesService);
break;
 case 'audit':
service = injector.get(services.AuditsService);
break;
 case 'subscriber':
service = injector.get(services.SubscribersService);
break;
 case 'distributorEnrollment':
service = injector.get(services.DistributorEnrollmentsService);
break;
 case 'clientInvestmentPlatter':
service = injector.get(services.ClientInvestmentPlattersService);
break;
 case 'clientInvestmentPortfolio':
service = injector.get(services.ClientInvestmentPortfoliosService);
break;
 case 'commonData':
service = injector.get(services.CommonDatasService);
break;
 case 'distributor':
service = injector.get(services.DistributorsService);
break;
 case 'gravytSiteMap':
service = injector.get(services.GravytSiteMapsService);
break;
 case 'otp':
service = injector.get(services.OtpsService);
break;
 case 'client':
service = injector.get(services.ClientsService);
break;
 case 'watchList':
service = injector.get(services.WatchListsService);
break;
 case 'emailSubscriberAudit':
service = injector.get(services.EmailSubscriberAuditsService);
break;
 case 'googleDriveBackup':
service = injector.get(services.GoogleDriveBackupsService);
break;
 case 'unsubscriber':
service = injector.get(services.UnsubscribersService);
break;
 case 'fundFilterData':
service = injector.get(services.FundFilterDatasService);
break;
 case 'emailBulkAudit':
service = injector.get(services.EmailBulkAuditsService);
break;
 case 'instantEmail':
service = injector.get(services.InstantEmailsService);
break;
 case 'pushNotificationMapper':
service = injector.get(services.PushNotificationMappersService);
break;
 case 'pushNotificationMessage':
service = injector.get(services.PushNotificationMessagesService);
break;
 case 'razorPay':
service = injector.get(services.RazorPaysService);
break;
 case 'distributorRiskProfile':
service = injector.get(services.DistributorRiskProfilesService);
break;
 case 'distributorFee':
service = injector.get(services.DistributorFeesService);
break;
 case 'influencer':
service = injector.get(services.InfluencersService);
break;
 case 'influencerPromoCode':
service = injector.get(services.InfluencerPromoCodesService);
break;
 case 'portfolioUserInfo':
service = injector.get(services.PortfolioUserInfoesService);
break;
 case 'portfolioUserInvestmentInfo':
service = injector.get(services.PortfolioUserInvestmentInfoesService);
break;
 case 'allDocumentUpload':
service = injector.get(services.AllDocumentUploadsService);
break;
// <<add-service>>
    }
    //console.log('service', service);
    return service;
  }



  uniqueNameValidator(type: string, column: string, id: any): AsyncValidatorFn {

    const service = this.getService(type);
    // const itemUniuqeCheck:any = {id:item.id}
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      const parent = control['parent'];
      if (parent instanceof FormGroup) {
        //console.log('parent', parent);
        //console.log('control', control, control.touched, control.dirty);
      }

      if ((!control.touched) && (!control.dirty)) {
        return of(null);
      }

      if (control.value) {
        const condition = { 'Id': id };
        const columnName = column.charAt(0).toUpperCase() + column.slice(1);
        condition[columnName] = control.value;

        const newItem = { ...parent.value };
        newItem['id'] = id;
        return service.unique(columnName, newItem, condition)
          .pipe(
            map(isUnique => {
              //console.log('isUnique', isUnique);
              if (isUnique) {
                return { 'uniqueNameExists': true };
              }
            })
          );
      } else {
        return of(null);
      }
    };

  }
}
