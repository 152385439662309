import { Injectable } from '@angular/core';
import { WbHome } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class WbHomesService extends BaseService<WbHome> {
    constructor() {
        super('WbHome');
    }
}
