import { Component, HostListener, OnInit, Output} from '@angular/core';
import { AppInjector } from '../../../app-injector.service';
import { LoginsService } from '../../core/service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-home-userinfo',
  templateUrl: './home-userinfo.component.html',
  styleUrls: ['./home-userinfo.component.scss'],
})
export class HomeUserInfoComponent implements OnInit {
  loggedInUser:any;
  isProfileSettingVisible: boolean = false;

  constructor(private helperService: HelperService) { }

  ngOnInit() {
    this.getUserInfo();
    this.helperService.loginChange.subscribe(loginType => {
      this.getUserInfo();
    });
  }

  getUserInfo() {
    this.loggedInUser = this.helperService.getLoggedInUserInfo();
    //console.log('loggedInUser', this.loggedInUser);
  }

  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);

    loginSvc.clearData();
    this.helperService.logout();
    //console.log('route path', 'login');
  }

  route(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
  }

  toggleProfileSetting(event:Event):void {
    event.stopPropagation();
    this.isProfileSettingVisible = !this.isProfileSettingVisible;
  }
}
