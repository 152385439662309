import { Directive, ElementRef, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[colorCategory]'
})
export class ColorCategoryDirective implements OnInit {
  @Input() colorCategory: number;
  constructor(private _elementRef: ElementRef) {


  }
  setColor(): void {
    // console.log('this.colorCategory', this.colorCategory);
    if (this.colorCategory >= 0) {
      // this._elementRef.nativeElement.style.color = '#009d0b';
      this._elementRef.nativeElement.className = 'value status excellent';
    }
    if (this.colorCategory < 0) {
      // this._elementRef.nativeElement.style.color = '#d60000';
      this._elementRef.nativeElement.className = 'value status poor';

    }
  }
  ngOnInit() {
    setTimeout(() => {
      this.setColor();
    }, 300);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorCategory) {
      this.setColor();
    }

  }
}
