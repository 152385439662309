import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CapitalizePipe } from './capitalize.pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ValidatorComponent } from './validator/validator.component';
import { TranslateModule } from '@ngx-translate/core';
import {NgxPrintModule} from 'ngx-print';
import { TableModule } from 'primeng/table';
import {TreeTableModule} from 'primeng/treetable';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { GridRowSelectPipe } from './pipes/app.gridrow.select';
import { GridColumnShowPipe } from './pipes/app.gridcolumn.show';
import {
  MenuModule, PanelModule, ChartModule, InputTextModule, ButtonModule, InputMaskModule,
  InputTextareaModule, EditorModule, CalendarModule, RadioButtonModule, FieldsetModule, DropdownModule,
  MultiSelectModule, ListboxModule, SpinnerModule, SliderModule, RatingModule, FileUploadModule,
  ContextMenuModule, TabViewModule, DialogModule, StepsModule, TreeModule, GMapModule,
  TooltipModule, ConfirmDialogModule, DragDropModule, GalleriaModule, CheckboxModule,
   SelectButtonModule,
   KeyFilterModule
} from 'primeng';
import {ToastModule} from 'primeng/toast';
import { BankPurchaseComponent } from '../components/bank-purchase/bank-purchase.component';
import { Bar100Component } from '../components/bar100/bar100.component';
import { CoverComponent } from '../components/cover/cover.component';
import { CurrentLoanComponent } from '../components/current-loan/current-loan.component';
import { FaqsComponent } from '../components/faqs/faqs.component';
import { ChartInfoComponent } from '../components/chart-info/chart-info.component';
import { ChartsModule } from 'ng2-charts';
import { UsersTabComponent } from '../components/users-tab/users-tab.component';
import { FaqDialogComponent } from '../components/faq-dialog/faq-dialog.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GaugeChartComponent } from '../components/gauge-chart/gauge-chart.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { OrderByPipe } from './pipes/app.orderby';
import { InrCurrencyPipe } from './pipes/app.inr-currency';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { MeterComponent } from '../components/meter/meter.component';
import { EnumLabelPipe } from './pipes/app.enum-label';
import { MfCurdlistComponent } from '../components/mf-curdlist/mf-curdlist.component';
import { MfSelectDialogComponent } from '../components/mf-select-dialog/mf-select-dialog.component';
import { FilterPipe } from './pipes/app.filter';
import { FileUploadViewComponent } from '../components/fileUploadView/fileUploadView.component';
import {PasswordModule} from 'primeng/password';
import { FooterComponent } from '../components/footer/footer.component';
//import {CarouselModule} from 'primeng/carousel';
import {NgxGaugeModule} from 'ngx-gauge';
import { MfListGravityComponent } from '../components/mf-list-gravity/mf-list-gravity.component';
import { MfBucketListComponent } from '../components/mf-bucket-list/mf-bucket-list.component';
import { BucketComponent } from '../components/bucket/bucket.component';
import { MfPlatterListComponent } from '../components/mf-platter-list/mf-platter-list.component';
import {BlockUIModule} from 'primeng/blockui';
import { TrackerBtnComponent } from '../components/tracker-btn/tracker-btn.component';
import { BackButtonComponent } from '../components/back-btn/back-button.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WbButtonComponent } from '../components/wb-button/wb-button.component';
import { PlatterListInfoComponent } from '../components/platter-list-info/platter-list-info.component';
import { CarouselBtnComponent } from '../components/carousel-btn/carousel-btn.component';
import { SvgComponent } from '../components/svg/svg.component';
import { PlatterHomeInfoComponent } from '../components/platter-home-info/platter-home-info.component';
import { UploadImgPathPipe } from './pipes/app.upload-img-path';
import { ChartIndicatorComponent } from '../components/chart-indicator/chart-indicator.component';
import { UtilitiesComponent } from '../components/utilities/utilities.component';
import { HomeFoundationComponent } from '../components/home-foundation/home-foundation.component';
import { HomeBlogComponent } from '../components/home-blog/home-blog.component';
import { HomeUserInfoComponent } from '../components/home-userinfo/home-userinfo.component';
import { HomeGlobalNotificationComponent } from '../components/home-global-notification/home-global-notification.component';
import { HomeMfSearchComponent } from '../components/home-mf-search/home-mf-search.component';
import { MfExploreTypeComponent } from '../components/mf-explore-type/mf-explore-type.component';
import { ParsePipe } from './pipes/app.parse';
import { MfFundInfoComponent, MfFundInfoDialogComponent } from '../components/mf-fund-info/mf-fund-info.component';
import { SafePipe } from './pipes/app.safe.pipe';
import { MfPaymentComponent } from '../components/mf-payment/mf-payment.component';
import { SipMinPipe } from './pipes/app.sip-min';
import { AutoTabDirective } from './Directive/auto-tab.directive';
import { ScrollIntoViewDirective } from './Directive/scroll-into-view.directive';
import { LoadingComponent } from '../components/loading/loading.component';
import { OnboardingComponent } from '../components/onboarding/onboarding.component';
import { HomeOnboardingComponent } from '../components/home-onboarding/home-onboarding.component';
import { GroupByPipe } from './pipes/app.group-by';
import { ImageCropperComponent } from '../components/image-cropper/image-croppercomponent';
import { NgxEchartsModule } from 'ngx-echarts';
import { ShowImgPipe } from './pipes/show-img.pipe';
import { ColorCategoryDirective } from './Directive/colorCategory.directive';
import { SwiperModule } from 'swiper/angular';
import { SearchPipe } from './pipes/app.search';
import { WatchListComponent } from '../components/watch-list/watch-list.component';
import { PlatteramountComponent } from '../components/platteramount/platteramount.component';
import { MfRedeemComponent } from '../components/mf-redeem/mf-redeem.component';
import { MfbookingListComponent } from '../components/mfbooking-list/mfbooking-list.component';
import { EmergencyFundListComponent } from '../components/emergency-fund-list/emergency-fund-list.component';
import { MfPaymentRetryComponent } from '../components/mf-payment-retry/mf-payment.component';
import { TableHeadComponent } from '../components/table-head/table-head.component';
import { ComparisonValidator } from './customValidators/comparisonValidator';
import { YouTubePlayerModule } from '@angular/youtube-player';

export function loadEcharts() {
  return import('echarts');
}

@NgModule({
  declarations: [CapitalizePipe, ValidatorComponent, FileUploadComponent, GridRowSelectPipe, GridColumnShowPipe, OrderByPipe, FilterPipe,
    Bar100Component, CoverComponent, CurrentLoanComponent, UsersTabComponent, InrCurrencyPipe, EnumLabelPipe, UploadImgPathPipe,
    FaqsComponent, ChartInfoComponent, BankPurchaseComponent, FaqDialogComponent, GaugeChartComponent, MeterComponent,
    MfCurdlistComponent, MfSelectDialogComponent, FileUploadViewComponent, FooterComponent, MfListGravityComponent,
    MfBucketListComponent, BucketComponent, MfPlatterListComponent, TrackerBtnComponent, BackButtonComponent, WbButtonComponent,
    PlatterListInfoComponent, CarouselBtnComponent, SvgComponent, PlatterHomeInfoComponent, ChartIndicatorComponent, UtilitiesComponent,
    HomeFoundationComponent, HomeBlogComponent, HomeUserInfoComponent, HomeGlobalNotificationComponent, HomeMfSearchComponent, MfExploreTypeComponent,
    ParsePipe, SipMinPipe,ComparisonValidator, SafePipe, MfFundInfoComponent,MfFundInfoDialogComponent,MfRedeemComponent,EmergencyFundListComponent,MfbookingListComponent,PlatteramountComponent, MfPaymentComponent,MfPaymentRetryComponent,TableHeadComponent, AutoTabDirective, ScrollIntoViewDirective, LoadingComponent,
    WatchListComponent,OnboardingComponent, HomeOnboardingComponent, GroupByPipe, ImageCropperComponent,ShowImgPipe,ColorCategoryDirective,SearchPipe
  ],
  imports: [YouTubePlayerModule,CommonModule, ReactiveFormsModule, FormsModule, FilterPipeModule, TranslateModule, RadioButtonModule, FormsModule, FileUploadModule,
    ChartsModule, GaugeChartModule, ProgressSpinnerModule, SelectButtonModule, ScrollPanelModule, DropdownModule,
    TableModule,TreeTableModule, PasswordModule, CarouselModule, NgxGaugeModule, BlockUIModule, KeyFilterModule, SwiperModule,
    NgxEchartsModule.forRoot({
      echarts: loadEcharts
    })
  ],
  exports: [ReactiveFormsModule, FormsModule, CapitalizePipe, ValidatorComponent, TranslateModule, CommonModule,
    TableModule,TreeTableModule, DynamicDialogModule, FileUploadComponent, FileUploadModule,
    MenuModule, PanelModule, ChartModule, InputTextModule, ButtonModule, InputMaskModule,
    InputTextareaModule, EditorModule, CalendarModule, RadioButtonModule, FieldsetModule, DropdownModule,
    MultiSelectModule, ListboxModule, SpinnerModule, SliderModule, RatingModule,
    ContextMenuModule, TabViewModule, DialogModule, StepsModule, TreeModule, GMapModule,
    TooltipModule, ConfirmDialogModule, DragDropModule, GalleriaModule,
    GridRowSelectPipe, OrderByPipe, EnumLabelPipe, FilterPipe, UploadImgPathPipe,
    NgxPrintModule, ChartsModule, MfBucketListComponent, BucketComponent,
    Bar100Component, CoverComponent, CurrentLoanComponent, UsersTabComponent, InrCurrencyPipe,
    FaqsComponent, ChartInfoComponent, BankPurchaseComponent, CheckboxModule, GaugeChartComponent, ProgressSpinnerModule,
    GridRowSelectPipe, GridColumnShowPipe, NgxPrintModule, ChartsModule, GaugeChartModule,
    Bar100Component, CoverComponent, CurrentLoanComponent, UsersTabComponent, MfListGravityComponent,
    FaqsComponent, ChartInfoComponent, BankPurchaseComponent, CheckboxModule, GaugeChartComponent, ProgressSpinnerModule,
    Bar100Component, CoverComponent, CurrentLoanComponent, UsersTabComponent,
    FaqsComponent, ChartInfoComponent, BankPurchaseComponent, CheckboxModule, GaugeChartComponent,
    ScrollPanelModule, SelectButtonModule, ToastModule, NgxGaugeModule, MfPlatterListComponent,
    MfCurdlistComponent, MfSelectDialogComponent, FileUploadViewComponent, PasswordModule, FooterComponent, CarouselModule,
    BlockUIModule, TrackerBtnComponent, BackButtonComponent, WbButtonComponent,
    PlatterListInfoComponent, CarouselBtnComponent, SvgComponent, PlatterHomeInfoComponent, ChartIndicatorComponent,
    UtilitiesComponent, HomeFoundationComponent, HomeBlogComponent, HomeUserInfoComponent,
    HomeGlobalNotificationComponent, HomeMfSearchComponent, MfExploreTypeComponent,ParsePipe, SipMinPipe, SafePipe,
     MfFundInfoComponent, MfFundInfoDialogComponent,ComparisonValidator,MfRedeemComponent,EmergencyFundListComponent,MfbookingListComponent,PlatteramountComponent, MfPaymentComponent,MfPaymentRetryComponent,TableHeadComponent, KeyFilterModule, AutoTabDirective, ScrollIntoViewDirective, LoadingComponent,
    WatchListComponent, OnboardingComponent, HomeOnboardingComponent, GroupByPipe, ImageCropperComponent,ShowImgPipe,ColorCategoryDirective, SwiperModule,SearchPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [ FaqDialogComponent, MfSelectDialogComponent ]
})
export class SharedModule {}
