import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';

import { BseRegistration } from '../../../core/model';
import { BaseService } from '../../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class BseRegistrationsService extends BaseService<BseRegistration> {
  constructor() {
    super('BseRegistration');
  }
  //fetchUsersNew() {
  //  return this.http.get<BseRegistration[]>(this.apiUrl + 'Get')
  //    .pipe(
  //      map(dataList => {
  //        return dataList;
  //      }),
  //      catchError(this.handleError)
  //    );
  //}

}
