import { Injectable } from '@angular/core';
import { GravytSeo } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class GravytSeosService extends BaseService<GravytSeo> {
    constructor() {
        super('GravytSeo');
    }
}
