import { Injectable } from '@angular/core';
import { Subscriber } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class SubscribersService extends BaseService<Subscriber> {
    constructor() {
        super('Subscriber');
    }
}
