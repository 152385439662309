import { Injectable } from '@angular/core';
import { DistributorFee } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class DistributorFeesService extends BaseService<DistributorFee> {
    constructor() {
        super('DistributorFee');
    }
}
