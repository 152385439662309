import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'parse'
})

export class ParsePipe implements PipeTransform {

  transform(value: any, type: 'int' | 'float' | 'round' | 'fixed' | 'intAbs' | 'floatAbs' | 'cr', fixedNo = 2): any {
    //console.log('Parse pipe');

    if (type == 'int')
      return parseInt(value, 10);
    if (type == 'cr') {
      var crValue = parseFloat(value) / 10000000;
      return crValue.toFixed(fixedNo);
    }
    if (type == 'float')
      return parseFloat(value);
    if (type == 'round')
      return Math.round(parseFloat(value));
    if (type == 'intAbs')
      return Math.abs(parseInt(value,10));
    if (type == 'floatAbs')
      return Math.abs(parseFloat(value));
    if (type == 'fixed') {
      value = typeof value == "string" ? parseFloat(value) : value;
      return value.toFixed(fixedNo);
    }

    return value;
  }
}
