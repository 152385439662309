import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UsersEditComponent } from './users-edit/users-edit.component';

const routes: Routes = [
    { path: '', component: UsersEditComponent },
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class UsersRoutingModule {}
