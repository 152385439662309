import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { AppInjector } from '../../../app-injector.service';
import { GlobalNotification, Platter, User } from '../../core/model';
import { CommonDatasService, GlobalNotificationsService, LoginsService, PlattersService } from '../../core/service';
import { UsersService } from '../../pages/custom/users/users.service';
import { HelperService } from '../../shared/helperService';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  model: any = {};
  platters: Platter[];
  notifications: GlobalNotification[];
  loadComplete = false;
  showNotification = true;
  isAdmin = false;
  isUserLoggedIn = false;
  loggedInUser: any;
  userInfo: any = null;
  errorShow=false;
  //
  time: any;
  timerCounter;
  timerCounterBase = 180;
  timerHandler = null;
  mobileNumberPartial = '';
  resendOTP: Boolean = false;
  isShowPlatter = true;
  loading: Boolean;
  step = 0;
  pin = '';
  mobileNo = '';
  emailId = '';
  emailToken = '';
  emailVerify = false;
  mobileVerify = false;
  otpVerify = false;
  register = false;
  otpNum1 = '';
  otpNum2 = '';
  otpNum3 = '';
  otpNum4 = '';
  firstName = '';
  lastName = ''
  birthDate: Date;
  panNo = '';
  otp = '';
  existDetail = false;
  otpFail = false;
  isLoggedin: boolean;
  isLoginModelShow = false;
  isPlatterSearch = false;
  isPlatform = "";
  pinResponse = 'pin';
  query = '';
  funds = [];
  //
  constructor(private route: ActivatedRoute, private router: Router,
    public loginsService: LoginsService,
    public helperService: HelperService,
    private plattersService: PlattersService,
    public usersService: UsersService,
    public notificationService: GlobalNotificationsService,
    private commonDatasService: CommonDatasService
  ) {
    this.helperService.loginChange.subscribe((loginType) => {
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    });
  }

  ngOnInit(): void {

    // if(this.platform.is('ios')){
    //   this.isPlatform = "iOS";
    // }


    this.userInfo = this.helperService.getLoggedInUserInfo();
    this.loggedInUser = this.helperService.getLoggedInUserInfo();
    this.isAdmin = this.helperService.isAdminUser;
    this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    setTimeout(() => {
      this.userInfo = this.helperService.getLoggedInUserInfo();
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    }, 1000);
    setTimeout(() => {
      this.userInfo = this.helperService.getLoggedInUserInfo();
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    }, 2000);

  }

  viewPlatter(data) {
    //const data = { selectedPlatterGroup: [type], page: 'Home' };
    if (data) {
      this.plattersService.helperSvc.setRouteData(data);
      this.plattersService.helperSvc.routeNavigate('platter/personalised');
    } else {
      this.plattersService.helperSvc.setRouteData(null);
      this.plattersService.helperSvc.routeNavigate('platter');
    }

  }
  notification() {
    const search = {};
    search['IsActive'] = true;

    this.notificationService.searchData(search, null, null, false).subscribe((notifications: GlobalNotification[]) => {
      this.notifications = notifications;
      this.loadComplete = true;
      //console.log(this.notifications);
    });
    this.router.events.subscribe(() => {
      this.showNotification = false;
    });
  }
  viewRiskProfilePlatter() {
    if (this.isUserLoggedIn) {
      const riskProfile = this.helperService.getPlatterRiskProfile();
      if (riskProfile) {
        this.viewPlatter({ "selectedRiskProfile": [riskProfile], "page": "PlatterHome" });
      } else {
        this.plattersService.helperSvc.setRouteData({ "page": "platter" });
        this.plattersService.helperSvc.routeNavigate('riskProfile');
      }
    } else {
      this.plattersService.helperSvc.routeNavigate('riskProfile');
    }

  }

  goto(path: string): void {
    this.router.navigateByUrl(path);
  }

  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);

    loginSvc.clearData();
    this.helperService.logout();
    //console.log('route path', 'login');
  }
  // async googleAuth() {

  //   this.socialAuthService.authState.subscribe((user) => {
  //     this.socialUser = user;
  //     this.isLoggedin = (user != null);
  //     if(user != null){
  //       this.emailId = user.email;
  //       this.emailToken = user.idToken;
  //       this.emailVerify = true;
  //       this.mobileVerify = false;
  //        this.validateEmail();
  //     }


  //   });
  //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

  async googleSignup() {
    // GoogleAuth();
    //  GoogleAuth.initialize()
    const googleUser = await GoogleAuth.signIn() as any;
    this.isLoggedin = (googleUser != null);
    if (googleUser != null) {
      this.emailId = googleUser.email;
      this.emailToken = googleUser.idToken;
      this.emailVerify = true;
      this.mobileVerify = false;
      this.validateEmail();
    }
    // this.userInfo = googleUser;

  }

  sendOtp() {
    if (this.mobileNo.length == 10) {

      // this.otpVerify = true;
      // this.otpFail = false;

      // this.mobileNumberPartial = this.mobileNo.toString().substr(0, 4) + "xxxxxx" + this.mobileNo.toString().substr(8, 2);
      // if (this.step == 1) {
      //   this.step = this.step + 1;
      // } else if (this.step == 2) {
      //   this.resendOTP = true;
      //   if (this.timerCounterBase == 180) {
      //     clearTimeout(this.time)
      //   }
      // }
      // this.otpNum1 = this.otpNum2 = this.otpNum3 = this.otpNum4 = "";
      // this.timerCounter = this.timerCounterBase;

      // this.runTimer()

      this.loginsService.sendLoginOtp(this.mobileNo).subscribe(msg => {
        if (msg == "OTP sent") {
          this.otpVerify = true;
          this.otpFail = false;

          this.mobileNumberPartial = this.mobileNo.toString().substr(0, 4) + "xxxxxx" + this.mobileNo.toString().substr(8, 2);
          if (this.step == 1) {
            this.step = this.step + 1;
          } else if (this.step == 2) {
            this.resendOTP = true;
            if (this.timerCounterBase == 180) {
              clearTimeout(this.time)
            }
          }
          this.otpNum1 = this.otpNum2 = this.otpNum3 = this.otpNum4 = "";
          this.timerCounter = this.timerCounterBase;

          this.runTimer()
        }
      });
    }
  }
  runTimer() {
    this.time = setTimeout(() => {
      if (this.timerCounter >= 0) {
        this.timerCounter = this.timerCounter - 1;
        this.runTimer();
      }
    }, 1000);

  }


  validateOtp() {
    this.otpFail = false;
    // console.log("this.otpNum1", this.otpNum1)
    // if (this.otpNum1 == '1234') {
    //   this.otpFail = false;
    //   this.step = 3;
    // }
    // else {
    //   this.otpFail = true;
    // }
    if (this.otpNum1) {
      if (this.mobileNo.length == 10) {
        var otp = this.otpNum1;

        this.loginsService.validateOtp(this.mobileNo, otp).subscribe(msg => {
          var msgVal = msg.split(':');
          this.otp = otp;
          if (msgVal[0] == "success") {
            const isNotRegisterd = msgVal[1] == "Not Register";
            if (isNotRegisterd) {
              this.emailVerify = false;
              this.mobileVerify = true;
              this.register = true;
              this.model.mobileNo=this.mobileNo;
              this.step = 3;
            } else {
              //call for Login
              this.otpVerify = true;
              this.emailVerify = false;
              this.login();

            }
          } else {
            this.otpFail = true;
          }
        });
      }
    }

  }

  validateEmail() {
    this.loginsService.ValidateEmail(this.emailId, this.emailToken).subscribe(msg => {
      var msgVal = msg.split(':');
      if (msgVal[0] == "success") {
        const isNotRegisterd = msgVal[1] == "Not Register";
        if (isNotRegisterd) {
          this.emailVerify = true;
          this.mobileVerify = false;
          this.register = true;
          this.model.emailId=this.emailId;
          this.step = 3;

        } else {
          //call for Login
          this.otpVerify = false;
          this.emailVerify = true;
          this.login();

        }
      }
    });

  }
  // validateNext() {
  //   let controls = [];

  //   switch (this.step) {
  //     case 0:
  //       controls = ["firstName","lastName","mobileNo","emailId","pin"];
  //       break;
  //       case 1:
  //       controls =["firstName","lastName","mobileNo","emailId","pin"];
  //       break;
  //       case 2:
  //       controls =["firstName","lastName","mobileNo","emailId","pin"];
  //       break;

  //   }
  //   console.log('controls',controls,this.step);
  //   let valid = this.loginsService.helperSvc.checkValidControls(
  //     this.itemForm,
  //     controls
  //   );

  //   console.log('Valid', valid,controls,this.itemForm);
  //   return valid;
  // }
  registerUser() {
    if(this.model.firstName&&this.model.lastName&&this.model.mobileNo&&this.model.emailId&&this.model.pin){
      this.errorShow=false;
      const user: User = {
        name: this.model.firstName + ' ' + this.model.lastName,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        //birthDate: (moment(this.birthDate)).toDate(),
        panNo: this.model.panNo,
        mobile: this.model.mobileNo,
        mobileVerified: this.mobileVerify,
        emailId: this.model.emailId,
        emailVerified: this.emailVerify,
        pin: this.model.pin,
        isActive: true,
        isAdmin: false,
        hasImpersonateAccess: false
      }
     // console.log('User Register', user);
      this.usersService.register(user).subscribe(data => {
        //console.log("data", data);
        if (data == "success") {
          this.login();
        } else {
          this.existDetail = true;
        }

      })
    }else{
      this.errorShow=true;
    }



  }

  login() {
    if (this.emailVerify) {
      this.loginsService.loginEmail(this.emailId, this.emailToken).subscribe((data) => {
        this.setLoginDetals(data)
        this.isLoginModelShow = false;
      });
    }
    else {
      this.loginsService.loginEmail(this.mobileNo, this.otp).subscribe((data) => {
        this.setLoginDetals(data)
        this.isLoginModelShow = false;
      });
    }


  }

  loginPin() {
    setTimeout(() => {
      this.loading = true;
      const pin = this.otpNum1;
      this.loginsService.loginPin(this.userInfo.emailId, pin).subscribe((data) => {
        this.pinResponse = data;
        if (data) {
          this.loading = true;
          this.otpNum1 = '';
          this.isLoginModelShow = false;
          this.setLoginDetals(data)
        } else {
          this.isLoginModelShow = true;
          this.loading = false;
        }
      });
    }, 500);
  }

  setLoginDetals(data) {
       if (data) {
      this.loginsService.helperSvc.setToken(data.token);
      this.loginsService.helperSvc.setLoggedInUserInfo(JSON.stringify(data.userInfo));
      // this.loginsService.helperSvc.routeNavigate(this.loginsService.helperSvc.navigationUrl);
      //this.loginsService.helperSvc.routeNavigate('dashboard');

    }

  }

  loadPlatterData() {
    this.isPlatterSearch = true;
    this.plattersService.getAll(false).subscribe(data => {
      const platters = data.filter(a => a.isActive);
      this.platters = [...platters];
    });


    this.commonDatasService.getAll().subscribe((data) => {

      this.funds = (data as any).fund;
    });
  }
  clearSearch() {
    this.query = '';
  }
  openView(item: Platter) {
    this.isPlatterSearch = false;
    // const str = item.name.trim().replace(/ /g, '-').toLowerCase();
    const str = item.name.trim().replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":;*?<>{}!]/g, '').toLowerCase();
   console.log('ssss',str);
    this.plattersService.helperSvc.routeNavigate('fundBox/' + str.trim());
    //this.plattersService.helperSvc.routeNavigate('platter/' + item.id);
  }

  openViewFund(item){
    this.isPlatterSearch = false;
    const str = item.legalName.trim().replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":;*?<>{}!]/g, '').toLowerCase();
    this.helperService.routeNavigate('funds/'+ str.trim());
    //this.helperService.routeNavigate('funds/'+item.isin);
  }

  selectRadio(item) {
    if (item == 'Platter') {
      this.isShowPlatter = true;
    } else {
      this.isShowPlatter = false;
    }
  }

  termsBrowser(item) {
    window.location.href=item;
    //const browser = this.iab.create('https://bonvista.in/terms-conditions', '_blank', 'hidenavigationbuttons=yes,location=yes');
  }
}
