import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';

import { BseMFDetail } from '../../../core/model';
import { BaseService } from '../../../shared/baseService';

@Injectable({
  providedIn: 'root'
})

export class BseMFDetailsWhitelistService extends BaseService<BseMFDetail> {
  constructor() {
    super('FeedMorStarApi');

    this.condition = { 'IsWhitelist': true };
    const type = 'FeedMorStarApiWhitelist';
    this.EnumStoreActions.GetAll = 'get_' + type + '_List';
    this.EnumStoreActions.GetData = 'get_' + type + '_Data';
    this.stateName = type;
  }



}
