import { Injectable } from '@angular/core';
import { Faq } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class FaqsService extends BaseService<Faq> {
    constructor() {
        super('Faq');
    }
}
