import { Injectable } from '@angular/core';
import { Client } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ClientsService extends BaseService<Client> {
    constructor() {
        super('Client');
    }
}
