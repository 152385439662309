import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Platter } from '../../../core/model';
import { PlattersService as service } from '../../../core/service/platters.service';

@Injectable({
    providedIn: 'root'
})

export class PlattersService extends service {
    
    
}
