import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { Faq } from '../../core/model';

@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.scss']
})
export class FaqDialogComponent implements OnInit {
faq: Faq = null;
answer = '';
  constructor(public router: Router,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig) { }

  ngOnInit() {
    this.faq = this.config.data.faq;
  }

}
