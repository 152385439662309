import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(value: any[], filterBy: string, column: string): any[] {
      filterBy = filterBy ? filterBy.toLocaleLowerCase() : null;
      return filterBy ? value.filter((item: any) =>
          item[column].toLocaleLowerCase().indexOf(filterBy) !== -1) : value;
  }
}
