import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { MandatesService } from '../../../pages/custom/mfUserOrders/mandates.service';
import { environment } from '../../../../environments/environment';
import { BseRegistration, ClientInvestment, ClientInvestmentPlatter, Mandate, MfOrder } from '../../../core/model';
import { BseRegistrationsService, ClientInvestmentSummariesService, ClientInvestmentPlattersService, FeedTransactionsService, ClientInvestmentsService } from '../../../core/service';
import { MfUserOrdersService } from '../../../pages/custom/mfUserOrders/mfOrders.service';
import { HelperService } from '../../../shared/helperService';
import { ConfirmationService, DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { Observable, forkJoin, merge } from 'rxjs';
import { SipDetailsService } from '../../../pages/custom/sipDetails/sipDetails.service';
@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss'],
  providers: [ConfirmationService],
})
export class RedeemComponent implements OnInit {

  redeemType = "amount";
  redeemUnits: number;
  redeemAmount: number;
  orignalRedeemAmount: number;
  minPlatterRedeemAmt: number;
  loggedInUser: any;
  totalAmt = null;
  redeemStatus = '';
  displayRedeemConfirmBox = false;
  displayRedeemAmtConfirmBox = false;
  currentMethod = 'Netbanking';
  displaySellConfirmBox = false;
  displayStopSIPConfirmBox = false;
  displayModifyConfirmBox = false;

  unprocessedOrders = null;
  platterpercentage: number = null;
  maxRedeemUnits = 0;
  maxRedeemAmt: number;
  minRedeemAmt = 0;
  orderTransNo = '';
  isEditMode = false;
  isButtonVisible = false;
  isButtonUnitVisible = false;
  platterDissolve = false;
  allRedemAmount: number;
  isButtonVisibleUnits = false;
  menuHeading: string = 'Portfolio Details';
  totalProcessAmount: number;
  acceptStopSip: boolean = false;
  acceptStopSips: boolean = false;
  showActionPopup=false;
  isPlatter=false;
  investmentInfo=null;
  investmentPlatterInfo=null;
  loading=false;
  clientInvestments: any[] = null;
  constructor(
    public mfOrdersService: MfUserOrdersService,
    public dialogService: DialogService,
    private router: Router,
     private http: HttpClient,
    public sipDetailsService: SipDetailsService,
    public clientInvestmentSummariesService: ClientInvestmentSummariesService,
    public clientInvestmentService: ClientInvestmentsService,
    public feedTransactionsService: FeedTransactionsService,
    private confirmationService: ConfirmationService,
    private clientInvestmentPlattersService: ClientInvestmentPlattersService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    public helperService: HelperService
  ) {

  }

  //ngOnInit() {}
  ngOnInit() {
    this.isPlatter=this.config.data.isPlatter;
    this.investmentInfo=this.config.data.selectedMfDataRedeem;
    this.investmentPlatterInfo=this.config.data.selectedPlatter;
    this.clientInvestments=this.config.data.platterDetailDataRedeem;

    this.loading = false;
    this.loggedInUser = this.helperService.loggedInUser;
    this.getRedeemUnprocessedOrder();

  }

  getRedeemUnprocessedOrder() {
    // @Input() investmentInfo: ClientInvestment = null;
    // @Input() investmentPlatterInfo: ClientInvestmentPlatter = null;
    const platterGroup = this.isPlatter ? this.investmentPlatterInfo.platterGroup : this.investmentInfo.platterGroup;
    this.mfOrdersService.redeemUnprocessedOrder(platterGroup).subscribe((unprocessedOrders) => {
      if (this.isPlatter) {
        this.unprocessedOrders = [...unprocessedOrders];
        let totalUnitsPlatter = 0;
        let totalValuePlatter = 0;
        let totalUnprocessedValues = 0;
        this.clientInvestments.forEach(clientInvestment => {
          let unprocessedUnits = 0;

          this.unprocessedOrders.forEach(unprocessedOrder => {
            if (unprocessedOrder.isin == clientInvestment.isinNumber) {
              unprocessedUnits = unprocessedOrder.unprocessedUnits;
              totalUnprocessedValues += unprocessedOrder.unprocessedValues;
              clientInvestment.minRedeemAmt = unprocessedOrder.minimumRedeemAmount;
            }
          });
          this.totalProcessAmount = totalUnprocessedValues;
          if (unprocessedUnits > 0) {
            clientInvestment.totalUnits = clientInvestment.totalUnits - unprocessedUnits > 0 ? clientInvestment.totalUnits - unprocessedUnits : 0;
            clientInvestment.currentValue = clientInvestment.totalUnits * clientInvestment.nav;
          }
          totalUnitsPlatter = totalUnitsPlatter + clientInvestment.totalUnits;
          totalValuePlatter = totalValuePlatter + clientInvestment.currentValue;
        });
        this.maxRedeemUnits = totalUnitsPlatter;
        this.maxRedeemAmt = totalValuePlatter;
        this.checkPlatterAmount();

      } else {
        this.totalProcessAmount = unprocessedOrders[0].unprocessedValues;
       if (unprocessedOrders[0].unprocessedUnits > 0) {
          const unprocessedUnits = unprocessedOrders[0].unprocessedUnits;
          this.investmentInfo.totalUnits = this.investmentInfo.totalUnits - unprocessedUnits > 0 ? this.investmentInfo.totalUnits - unprocessedUnits : 0;
          this.investmentInfo.currentValue = this.investmentInfo.totalUnits * this.investmentInfo.nav;
          this.minRedeemAmt = unprocessedOrders[0].minimumRedeemAmount;
        }
        this.maxRedeemUnits = this.investmentInfo.totalUnits;
        this.maxRedeemAmt = this.investmentInfo.currentValue;
      }
    });

  }

  showCheckBox(){
    this.displayStopSIPConfirmBox = true;
    this.confirmation();
  }

confirmation(){
   this.showActionPopup=true;
 this.confirmationService.confirm({
  message: '',
  accept: () => {
    // this.acceptStopSips=true;this.acceptStopSip=true;this.displayStopSIPConfirmBox = false;
    //alert('Working');
  },
  reject: () => {

  },
});

}
  // back(): void {
  //   this.goBack.emit();
  // }

  redeem(): void {
    this.loading=true;
    if (!this.isPlatter) {
      this.loading = true;

      this.redeemScheme();
      // this.platterDetailData = this.clientInvestments.filter(x => x.platterGroup == this.investmentPlatterInfo.platterGroup);
      // this.selectedMfData = this.clientInvestments.find(x => x.folioNo == this.investmentPlatterInfo.folioNo && x.isinNumber == this.investmentPlatterInfo.isinNumber);

    } else {
      if (this.clientInvestments.length) {
        this.loading = true;
        this.orderTransNo = this.loggedInUser.id + '_' + moment().format("YYYYMMDDhhmmss");

        this.redeemPlatter(0);
      }
    }
  }

  redeemScheme(): void {
    let mfOrder: MfOrder;
    const valueRedeem = this.redeemType == "amount" ? this.redeemAmount : this.redeemType == "units" ? this.redeemUnits : this.investmentInfo.totalUnits;
    const approxValue = this.redeemType == "amount" ? this.redeemAmount : this.redeemType == "units" ? this.redeemUnits * this.investmentInfo.nav : this.investmentInfo.totalUnits * this.investmentInfo.nav;
    const approxUnits = this.redeemType == "amount" ? this.redeemAmount / this.investmentInfo.nav : this.redeemType == "units" ? this.redeemUnits : this.investmentInfo.totalUnits;
    this.orderTransNo = this.loggedInUser.id + '_' + moment().format("YYYYMMDDhhmmss");


    mfOrder = {
      name: null,
      transOrderCode: "",
      transCode: "New",
      mfOrderTypeEnum: "Redemption",
      refNo: this.investmentInfo.folioNo,
      transNo: "",
      orderId: null,
      schemeCd: this.investmentInfo.schemeCode,
      bseMfName: this.investmentInfo.schemeName,
      buySell: "R",
      buySellType: this.redeemType,
      value: valueRedeem,
      status: null,
      userId: this.loggedInUser.id,
      platterGroup: this.investmentInfo.platterGroup,
      platterName: this.investmentInfo.platterName,
      approxUnits: approxUnits,
      approxValue: approxValue,
      orderTransNo: this.orderTransNo
      //
    };
    this.mfOrdersService.add(mfOrder).subscribe((order) => {
      if (this.acceptStopSips) {
        this.sipDetailsService.sipFundData(this.investmentInfo.platterGroup).subscribe((sip) => {
          this.sipDetailsService.cancelSip([sip], false).subscribe(cancelItem => {

          });
        });
      }

      this.redeemStatus = 'Success';
      this.loading = false;
      this.displaySellConfirmBox = false;
      this.displayRedeemConfirmBox = true;
      this.confirmation();

    });
  }

  redeemPlatter(index): void {
    //  this.redeemType = "allUnits";
    const investmentInfo = this.clientInvestments[index];
    var mfOrder: MfOrder;
    var valueRedeem = this.redeemType == "amount" ? investmentInfo.redeemAmount :
      this.redeemType == "units" ? investmentInfo.redeemUnits : investmentInfo.totalUnits;
    const approxValue = this.redeemType == "amount" ? investmentInfo.redeemAmount : this.redeemType == "units" ? investmentInfo.redeemUnits * investmentInfo.nav : investmentInfo.totalUnits * investmentInfo.nav;
    const approxUnits = this.redeemType == "amount" ? investmentInfo.redeemAmount / investmentInfo.nav : this.redeemType == "units" ? investmentInfo.redeemUnits : investmentInfo.totalUnits;


    mfOrder = {
      name: null,
      transOrderCode: "",
      transCode: "New",
      mfOrderTypeEnum: "Redemption",
      refNo: investmentInfo.folioNo,
      transNo: "",
      orderId: null,
      schemeCd: investmentInfo.schemeCode,
      bseMfName: investmentInfo.schemeName,
      buySell: "R",
      buySellType: this.redeemType,
      value: valueRedeem,
      status: null,
      userId: this.loggedInUser.id,
      platterGroup: investmentInfo.platterGroup,
      platterName: investmentInfo.platterName,
      approxUnits: approxUnits,
      approxValue: approxValue,
      orderTransNo: this.orderTransNo
      //
    };

    if (valueRedeem > 0) {
      //console.log('Real mfOrder', mfOrder,this.platterDissolve);
      // index = index + 1;
      // if (index < this.clientInvestments.length) {
      //   this.redeemPlatter(index);
      // } else {
      //   this.redeemStatus = 'Success';
      //   this.loading = false;
      //   this.displaySellConfirmBox = false;
      //   this.displayRedeemConfirmBox = true;
      // }
      this.mfOrdersService.add(mfOrder).subscribe((order) => {
        index = index + 1;
        if (index < this.clientInvestments.length) {
          this.redeemPlatter(index);
        } else {

          if (this.acceptStopSips) {
            this.sipDetailsService.sipData(this.investmentPlatterInfo.platterGroup).subscribe((data: any) => {
              let sipRecord = [...data];
              this.sipDetailsService.cancelSip(sipRecord, true).subscribe(cancelItem => {

              });
            });
          }

          if (this.platterDissolve) {
            this.mfOrdersService.dissolvePlatter(this.clientInvestments[0].platterGroup).subscribe((data) => {

              this.refreshDataAfterPlatterDissolve();
              //Portfolio page services
              // setTimeout(() => {
              //   this.loading = false;
              //   this.displaySellConfirmBox = false;
              //   this.displayRedeemConfirmBox = true;
              // }, 1500);



            });
          } else {

            this.loading = false;
            this.displaySellConfirmBox = false;
            this.displayRedeemConfirmBox = true;
            this.confirmation();
          }


        }

      });
    } else {
      index = index + 1;
      if (index < this.clientInvestments.length) {
        this.redeemPlatter(index);
      } else {
        this.redeemStatus = 'Success';
        this.loading = false;
        this.displaySellConfirmBox = false;
        this.displayRedeemConfirmBox = true;
        this.confirmation();
      }
    }

  }

  refreshDataAfterPlatterDissolve() {
    const observable = forkJoin([
      this.mfOrdersService.refreshData(true),
      this.sipDetailsService.refreshData(true),
      this.feedTransactionsService.refreshData(true),
      this.clientInvestmentService.refreshData(true),
      this.clientInvestmentPlattersService.refreshData(true),
      this.clientInvestmentSummariesService.refreshData(true)

    ]);
    observable.subscribe({
      next: value => console.log("value", value),
      complete: () => {
        console.log('This is how it ends!');
        this.loading = false;
        this.displaySellConfirmBox = false;
        this.displayRedeemConfirmBox = true;
        this.confirmation();
      },
    });
    // this.mfOrdersService.refreshData(true).subscribe(d => { });
    // this.sipDetailsService.refreshData(true).subscribe(d => { });
    // this.feedTransactionsService.refreshData(true).subscribe(d => { });
    // this.clientInvestmentService.refreshData(true).subscribe(d => { });
    // this.clientInvestmentPlattersService.refreshData(true).subscribe(d => { });
    // this.clientInvestmentSummariesService.refreshData(true).subscribe(d => { });
  }
  redeemPlatterDetail(index): void {

  }

  showEditMode(): void {
    this.displayModifyConfirmBox = true;
    this.confirmation();
    this.isEditMode = true;
    // this.isButtonVisible = true;
    this.checkModifyPlatterAmount(this.clientInvestments);
    this.isButtonVisibleUnits = true;
    //this.isGridTotalAmountVisible = true;
    this.menuHeading = 'Edit Portfolio';
  }

  onSuccess(path) {
    this.mfOrdersService.helperSvc.routeNavigate(path);
  }
  goto(path: string): void {
    this.displayRedeemConfirmBox = false;
    this.ref.close(path);
    // setTimeout(() => {
    //   this.router.navigateByUrl(path);

    //   // this.router.navigate([path]);
    // }, 200);
    // // setTimeout(() => {
    //   this.ref.close('refresh');
    // }, 100);
  }

  gotoRedeem() {
    this.displaySellConfirmBox = false;
  }

  checkPlatterAmount() {
    var maxValue = this.maxRedeemAmt;
    let totalAmt = 0;
    let minPlatterAmount = 0;
    let platterMinAmt = 0;
    let differentAmt = (this.maxRedeemAmt / 100)
    let remainAmt = this.maxRedeemAmt - this.redeemAmount;
    this.isButtonVisible = true;
    if (differentAmt > remainAmt && this.maxRedeemAmt > this.redeemAmount) {
      this.displayRedeemAmtConfirmBox = true;
      this.confirmation();
    }
    for (let i = 0; i < this.clientInvestments.length; i++) {
      const clientInvestment = this.clientInvestments[i];
      const perc = clientInvestment.currentValue / this.maxRedeemAmt * 100;
      let setMinPlatterValue = parseFloat((platterMinAmt * perc / 100).toFixed(0));

      if (clientInvestment.minRedeemAmt > setMinPlatterValue) {

        platterMinAmt = (clientInvestment.minRedeemAmt * 100) / perc;
        platterMinAmt = parseFloat(platterMinAmt.toFixed(0));
        this.minPlatterRedeemAmt = platterMinAmt;
        minPlatterAmount = parseFloat((platterMinAmt * perc / 100).toFixed(0));

      }


      let amt = ((this.redeemAmount) * perc) / 100;
      amt = parseFloat(amt.toFixed(0));
      clientInvestment.redeemAmount = amt;


      if (i == this.clientInvestments.length - 1) {
        clientInvestment.redeemAmount = this.redeemAmount - totalAmt;
      }
      clientInvestment.orignalRedeemAmount = clientInvestment.redeemAmount;
      totalAmt += amt;
 }
    this.allRedemAmount = totalAmt;
    this.checkModifyPlatterAmount(this.clientInvestments);


    // this.clientInvestments.forEach(clientInvestment => {
    //   if (this.redeemAmount <= maxValue) {

    //     // var per=(this.redeemAmount)*100/this.maxRedeemAmt;
    //    //var amt=((this.redeemAmount)*clientInvestment.platterSharePerc)/100;
    //   const perc =clientInvestment.currentValue/ this.maxRedeemAmt * 100;
    //   var amt=((this.redeemAmount)* perc)/100;
    //   clientInvestment.redeemAmount=parseFloat(amt.toFixed(2));
    // }
    // else {
    //   this.platterpercentage = clientInvestment.currentValue;
    // }
    //});

  }



  checkModifyPlatterAmount(item) {
    let totAmt = 0;
    let validData = true;
    let desolvePlatter = false;
    item.forEach(clientInvestment => {
      totAmt += +clientInvestment.redeemAmount;
       if (clientInvestment.orignalRedeemAmount != clientInvestment.redeemAmount) {
        desolvePlatter = true;
      }

      if (clientInvestment.redeemAmount > 0) {
        if (clientInvestment.redeemAmount > clientInvestment.currentValue || clientInvestment.redeemAmount < clientInvestment.minRedeemAmt) {
          validData = false;
        }
      }
    });



    this.platterDissolve = desolvePlatter;

    if (!(totAmt == this.redeemAmount && validData)) {
      validData = false;
    }
    this.isButtonVisible = validData;
  }

  checkModifyPlatterUnits(item) {
    let totUnits = 0;
    let totRedeemUnits = 0;
    let validUnitData = true;
    // this.platterDissolve = false;
    this.platterDissolve = true;
    item.forEach(clientInvestment => {
      totUnits += +clientInvestment.totalUnits;

      // this.isButtonVisible = false;
      if (clientInvestment.redeemUnits) {
        if (clientInvestment.redeemUnits > clientInvestment.totalUnits) {
          validUnitData = false;
          // this.isButtonVisibleUnits = false;
        }

        totRedeemUnits += +clientInvestment.redeemUnits;


      }

    });
    this.allRedemAmount = totRedeemUnits;

    if (!(totUnits >= totRedeemUnits && validUnitData)) {
      validUnitData = false;
      //this.isButtonVisible = true;
    }


    this.isButtonUnitVisible = validUnitData;
  }

  clear() {
    this.redeemAmount = 0;
    this.isButtonVisible = false;
    this.platterDissolve = false;
  }

}

