import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back-btn',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Output() goBack  = new EventEmitter();

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}



  back(): void {
    this.goBack.emit();
  }

}
