import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  MandatesService,
  MfSchemesService,
} from "../../core/service";
import { Observable } from "rxjs";
import {
  MfScheme,
  MfOrder,
  Foundation,
  Mandate,
  FeedMorStarApi,
} from "../../core/model";
import { LookupService } from "../../shared/lookupService";
import { ChartOptions, ChartType } from "chart.js";
import { Label, SingleDataSet, MultiDataSet } from "ng2-charts";
import { Router } from "@angular/router";
import { MfUserOrdersService } from "../../pages/custom/mfUserOrders/mfOrders.service";
import { ConfirmationService, DialogService } from "primeng";
import { FoundationPageService } from "../../pages/custom/foundation/foundation.page.service";
import { HelperService } from "../../shared/helperService";
import { MfPaymentComponent } from "../mf-payment/mf-payment.component";
import { FeedMorStarApisService } from "../../pages/custom/feedMorStarApis/feedMorStarApis.service";
import { MfFundInfoComponent, MfFundInfoDialogComponent } from "../mf-fund-info/mf-fund-info.component";

@Component({
  selector: 'app-mfbooking-list',
  templateUrl: './mfbooking-list.component.html',
  styleUrls: ['./mfbooking-list.component.scss'],
  providers: [ConfirmationService, DialogService],

})

export class MfbookingListComponent implements OnInit {
  mfSchemes$: Observable<any[]>;
  investmentAmt = null;
  selectedMFScheme: MfScheme;
  mfOrderList: any[];
  mfOrder: MfOrder;
  orderError = "";
  validOrder = false;

  selectedMfOrderTypeEnum: any;
  sipStartDateCalendar: any;
  selectedFrequencyTypeEnum: any;

  listMfOrderTypeEnum: any[];
  listFrequencyTypeEnum: any[];
  NoOfInstallment: number;

  currentStep = 0;
  totalStep = 2;
  riskValue = 0;
  invalidAmount = false;
  legendData: any[];

  loggedInUser: any;
  mandateType = "";
  isGridTotalAmountVisible = false;
  isEditMode = false;
  showFundInfo = false;
  feedMorningStar: any;
  showPayment = false;
  menuHeading = "Monthly Saving Portfolio";
  bottomBtnText = "Invest Now";
  isTabsVisible = false;
  @ViewChild('mfpayment') mfpayment: MfPaymentComponent;

  public pieChartAssetRatioData: number[] = [0, 0];
  public pieChartEquityRatioData: number[] = [0, 0];

  prevTotalAmount = 0;
  prevselectedMFScheme: any;
  prevselectedMfOrderTypeEnum: any;
  generateGrid = false;
  gridTotalAmount = 0;
  loading = false;
  public gaugeOptions = {
    hasNeedle: true,
    needleColor: "orange",
    needleUpdateSpeed: 1000,
    rangeLabel: ["0", "100"],
    needleStartValue: 50,
    arcColors: ["#0f8e49", "#cada31", "#f4ec25", "#faa620", "#db2429"],
    arcDelimiters: [20, 40, 60, 80],
    arcPadding: 6,
    arcPaddingColor: "white",
    arcLabels: [],
    arcLabelFontSize: false,
  };

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "top",
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };
  public foundation: Foundation;

  constructor(
    public mfSchemesService: MfSchemesService,
    public mfOrdersService: MfUserOrdersService,
    public mandatesService: MandatesService,
    public foundationPageService: FoundationPageService,
    public lookupService: LookupService,
    public router: Router,
    public confirmationService: ConfirmationService,
    public feedMorStarApisService: FeedMorStarApisService,
    public dialogService: DialogService,
    public helperService: HelperService
  ) //public nav: NavController
  {
    this.loggedInUser = this.mfOrdersService.helperSvc.getLoggedInUserInfo();
  }

  ngOnInit() {
    this.viewWillEnter();

  }

  viewWillEnter() {
    this.selectedMFScheme = null;
    this.currentStep = 1;
    this.investmentAmt = null;
    this.selectedFrequencyTypeEnum = null;
    this.NoOfInstallment = null;
    this.sipStartDateCalendar = null;
    const userInfo = this.helperService.getLoggedInUserInfo();
    if (!userInfo.riskProfile) {
      this.helperService.setRouteData({ "page": "mfBooking" });
      this.helperService.routeNavigate('riskProfile');
      return;
    }



    this.mfSchemesService.getAll().subscribe((data: any[]) => {
      data.forEach(item => {
        if (item.name === userInfo.riskProfile) {
          this.selectedMFScheme = this.prevselectedMFScheme = item;
        }
      });
      this.bindSchems();
    });

    this.bindDropDown();
    this.foundationPageService
      .getAll()
      .subscribe((foundation: Foundation[]) => {
        this.foundation = foundation.length > 0 ? foundation[0] : null;
        if (this.foundation) {
          var investmentAmt =
            this.foundation.monthlySavingRequired -
            this.foundation.monthlySaving;
          investmentAmt = Math.ceil(investmentAmt / 500) * 500;
          this.investmentAmt = investmentAmt > 0 ? (investmentAmt < 2000 ? 2000 : investmentAmt) : 2000;
          this.bindSchems();
        }
      });


  }

  bindSchems() {
    if (this.prevselectedMFScheme && this.investmentAmt) {
      this.prevselectedMFScheme = this.selectedMFScheme;
      this.prevTotalAmount = this.investmentAmt;
      this.prevselectedMfOrderTypeEnum = this.selectedMfOrderTypeEnum;
      this.generateGrid = true;
    }
  }

  checkDisabled() {
    if (this.currentStep === 1) {
      this.invalidAmount = false;
      if (
        !(
          this.investmentAmt &&
          this.investmentAmt >= 2000
        )
      ) {
        this.invalidAmount = true;
        return true;
      }
      if (this.selectedMfOrderTypeEnum) {
        if (this.selectedMfOrderTypeEnum.value === "Lumpsum") {
          return !this.investmentAmt;
        } else {
          return !(
            this.investmentAmt &&
            this.selectedFrequencyTypeEnum &&
            this.NoOfInstallment
          );
        }
      }
    }
    return true;
  }

  next() {
    if (this.currentStep === 1) {
      this.prevselectedMFScheme = this.selectedMFScheme;
      this.prevTotalAmount = this.investmentAmt;
      this.prevselectedMfOrderTypeEnum = this.selectedMfOrderTypeEnum;
      this.currentStep = this.currentStep + 1;
      this.generateGrid = true;
    }

    if (this.currentStep == 2) {
      this.menuHeading = "Portfolio Detail";
    }
  }

  selectMFScheme(item: MfScheme) {
    this.selectedMFScheme = item;
    this.pieChartAssetRatioData = [item.debtRatio, item.equityRatio];
    this.pieChartEquityRatioData = [
      item.largeRatio,
      item.midRatio,
      item.smallRatio,
    ];
    this.riskValue = item.riskPerc;

  }

  updateChart(mfDetails) { }

  onAmountUpdate(amtVal: any): void {
    // if (this.currentStep === 2) {
    this.prevselectedMFScheme = this.selectedMFScheme;
    this.prevTotalAmount = amtVal; //this.investmentAmt;
    this.prevselectedMfOrderTypeEnum = this.selectedMfOrderTypeEnum;
    this.currentStep = this.currentStep;
    this.generateGrid = true;
    // }
  }

  getMfSelectedList(data) {
    this.mfOrderList = JSON.parse(data);
    this.validateOrder();
  }

  validateOrder() {
    this.orderError = "";
    let totalAmt = 0;
    let valid = true;
    this.mfOrderList.forEach((order) => {
      const amt = parseInt(order.amount, 10);
      // debugger;
      // if (amt <= 0) {
      //   valid = false;
      // } else {
      //   totalAmt += amt;
      // }

      totalAmt += amt;

    });
    let message = "";
    if (!valid) {
      message = "Amount should not be empty or Zero";
    } else if (totalAmt - this.investmentAmt != 0) {
      message =
        "Investment Amount (" +
        this.investmentAmt +
        ") not matched with total amount:" +
        totalAmt;
    }
    setTimeout(() => {
      this.orderError = message;
      this.gridTotalAmount = totalAmt;
    }, 50);
    this.validOrder = valid;
  }

  bindDropDown() {
    this.lookupService.getDataEnum("MFOrderType").subscribe((list) => {
      this.listMfOrderTypeEnum = list;
      this.selectedMfOrderTypeEnum = list[2];
      this.NoOfInstallment = 999;
    });
    this.lookupService.getDataEnum("FrequencyType").subscribe((list) => {
      this.listFrequencyTypeEnum = list;
      this.selectedFrequencyTypeEnum = list[0];
    });
  }

  preOrderMF() {
    this.showFundInfo = false;
    this.mandateType = "register";
    //this.isTabsVisible = true;
    this.showPayment = true;
    this.menuHeading = "Payment";
  }

  showEditMode(): void {
    this.isEditMode = true;
    this.isGridTotalAmountVisible = true;
    this.menuHeading = "Edit Portfolio";
  }

  back(): void { }

  showSchemeInfo(fund): void {
    const search = { Isin: fund.isin };
    this.feedMorStarApisService.searchFund(null, null, search).subscribe((schemeInfoList: FeedMorStarApi[]) => {
      if (schemeInfoList && schemeInfoList.length) {
        this.feedMorningStar = schemeInfoList[0];
        this.feedMorningStar.operations = JSON.parse(this.feedMorningStar.operations);
        this.feedMorningStar.performance = JSON.parse(this.feedMorningStar.performance);
        this.feedMorningStar.top10Holdings = JSON.parse(this.feedMorningStar.top10Holdings);
        this.showFundInfo = true;
        this.openFundDetails();
      }
    });
  }
  openFundDetails() {
    const ref = this.dialogService.open(MfFundInfoDialogComponent, {

      data: {
        openSipLumpsumPopup: true,
        openFoundationFund: true,
        showSimilarFunds: false,
        isFooterVisible: false,
        FeedMorningStar: this.feedMorningStar,
        Isin: this.feedMorningStar?.isin

      },
      width: '50%'
    });
    // ref.onClose.subscribe((data: any) => {
    //   if (data) {
    //     let currentUrl = this.router.url;
    //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //       this.router.navigate([currentUrl]);
    //     });

    //   }
    // });



  }

  makePayment() {
    this.mfpayment.registerMandate();
  }

  HardwareBack() {
    let element: HTMLElement = document.getElementById(
      "backButton"
    ) as HTMLElement;
    element.click();
  }
}
