import { Injectable } from '@angular/core';
import { WbVehicle } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class WbVehiclesService extends BaseService<WbVehicle> {
    constructor() {
        super('WbVehicle');
    }
}
