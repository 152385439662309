import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Foundation } from '../../../core/model';
import { BaseService } from '../../../shared/baseService';

@Injectable({
  providedIn: 'root'
})

export class FoundationPageService extends BaseService<Foundation> {
  public foundationUpdate = new Subject<string>();
  constructor() {
    super('Foundation');
    const loggedInUser = this.helperSvc.getLoggedInUserInfo();
    this.condition = { 'UserId': loggedInUser ? loggedInUser.id : 0 };
    const type = 'FoundationPage';
    this.EnumStoreActions.GetAll = 'get_' + type + '_List';
    this.EnumStoreActions.GetData = 'get_' + type + '_Data';
    this.stateName = type;
  }
}
