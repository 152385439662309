import { Injectable } from '@angular/core';
import { Dependent } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class DependentsService extends BaseService<Dependent> {
    constructor() {
        super('Dependent');
    }
}
