import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Kyc } from '../../../core/model';
import { KycsService as service } from '../../../core/service/kycs.service';


@Injectable({
    providedIn: 'root'
})

export class KycsService extends service {
   
}
