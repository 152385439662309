import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { SipDetail } from '../../../core/model';
import { SipDetailsService as service } from '../../../core/service/sipDetails.service';

@Injectable({
    providedIn: 'root'
})

export class SipDetailsService extends service {
    
    
}
