import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { MandatesService } from '../../pages/custom/mfUserOrders/mandates.service';
import { environment } from '../../../environments/environment';
import { BseRegistration, Mandate, MfOrder } from '../../core/model';
import { BseRegistrationsService } from '../../core/service';
import { MfUserOrdersService } from '../../pages/custom/mfUserOrders/mfOrders.service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-mf-payment-retry',
  templateUrl: './mf-payment.component.html',
  styleUrls: ['./mf-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class MfPaymentRetryComponent implements OnInit {

  @Input() type = '';
  @Input() amount: number = 0;
  @Input() minAmount: number = 0;
  @Input() mfOrderList: any[];
  @Output() goBack = new EventEmitter();
  @Output() onReload = new EventEmitter();
  @Input() bseMandateCode: any;


  minUpiAmount = 10000;
  dateGroup = '';
  totalAmt = null;
  mandateType = '';
  listSipDates: any[];
  selectedSipDate: any;
  loggedInUser: any;
  //loading = false;
  mandateRegisterType = 'Netbanking';
  mandatePaymentType = 'Netbanking';
  mandateCode = '';
  paymentGatewayHtml = '';
  paymentGatewayHtmlSanitize: any;
  mandateAuthHtml = '';
  orderIds: any[] = [];
  orderRequestStart = false;
  mfOrder: MfOrder;
  sipStartDateCalendar: any;
  frequencyType = 'Monthly';
  noOfInstallment = 999;
  fundName = '';
  confirmDialogMessage = '';

  isOrderSuccess: boolean = false;
  isOrderFail: boolean = false;
  paymentRedirectUrl = location.origin;
  upiId = '';
  upiPaymentMessage = '';
  hasUpiPaymentTimeout = false;
  madeUpiPayment = false;
  mfOrderInit = false;

  @ViewChild('element', { static: true }) public viewElement: ElementRef;
  public element: any;
  showMobileView = false;

  mandateRegType: any[];
  mandatePayType: any[];
  orderTransNo = '';
  isconfirmDialogMessage = false;

  currentMethod = 'Netbanking';
  loading=false;
  constructor(
    public mfOrdersService: MfUserOrdersService,
    public mandatesService: MandatesService,
    public confirmationService: ConfirmationService,
    private http: HttpClient, private sanitizer: DomSanitizer,
    public helperService: HelperService,
    private bseRegistrationsService: BseRegistrationsService,
    private ref: ChangeDetectorRef
  ) {
    this.dateGroup = moment().format('YYYYMMDDHHmmss')
  }

  //ngOnInit() {}
  ngOnInit() {
    this.helperService.globalLoading = false;
    this.mandateType = 'payment';
    if (this.amount) {
      this.totalAmt = this.amount;
    }

    if (this.type == 'AutoDebitCheck') {
      this.mandateCode = this.bseMandateCode;
      this.mandateType = 'AutoDebitCheck';
      this.autoDebit();
      return;
    }

    this.mandateRegType = [
      {
        name: 'Netbanking',
        value: 'Netbanking'
      },
      {
        name: 'Debit Card',
        value: 'Debit Card'
      }
    ];

    this.mandatePayType = [
      {
        name: 'Netbanking',
        value: 'Netbanking'
      },
      {
        name: 'Debit Card',
        value: 'Debit Card'
      }
    ];


    this.loggedInUser = this.mfOrdersService.helperSvc.getLoggedInUserInfo();
    if (!this.loggedInUser.hasBseRegistration) {
      this.bseRegistrationsService.getAll(true).subscribe((bseRegistration: BseRegistration[]) => {
        var bseReg = bseRegistration.length > 0 ? bseRegistration[0] : null;
        if (bseReg == null || bseReg.registrationStatus != 'Success') {
          this.mfOrdersService.helperSvc.routeNavigate('bseUserRegistrations');
        }
      });
    }

    let fundNameList = [];
    let orderIdList = [];

    this.mfOrderList.forEach((value) => {
      fundNameList.push(value.name);
      orderIdList.push("" + value.orderId)
    });
    this.orderIds = orderIdList;

    this.mandateCode = this.type == 'SIP' ? this.mfOrderList[0].refNo : "";

    //let fundsName = (!this.platter || this.platter == 'NA') ? fundNameList.join(' ,') : this.platter + ' Platter';
    //this.fundName = fundsName;

  }

  appendHTMLSnippetToDOM() {
    this.element = this.viewElement.nativeElement;
    const fragment = document.createRange().createContextualFragment(this.paymentGatewayHtml);
    this.element.appendChild(fragment);
  }

  back(): void {
    this.goBack.emit();
  }

  reload(): void {
    this.onReload.emit();
  }


  payment() {
    if (this.currentMethod == 'UPI') {
      this.loading=true;
      this.mfOrdersService.paymentUpi(this.orderIds, this.totalAmt, this.mandateCode, this.upiId).subscribe((data) => {
        if (data.message.indexOf('INVALID ORDER NO') >= 0) {

          this.confirmDialogMessage = 'Please try again after 10 minutes';
          this.isconfirmDialogMessage = true;
          this.confirmationService.confirm({
            //message: 'Please try again after few minutes',
            // header: 'Cancel Confirmation',
            icon: 'pi pi-info-circle',
            reject: () => {
              this.isconfirmDialogMessage = false;
              this.reload();
            }
          });
          this.loading=false;
        } else {
          this.loading=true;
          this.helperService.globalLoading = false;
          this.mandateType = 'paymentUpi';
          this.upiPaymentMessage = data.message;
          if (data.status == 'Success') {
            this.checkUpiPayment();
            setTimeout(() => {
              this.hasUpiPaymentTimeout = true;
            }, 3 * 60 * 1000);
          } else {

          }
          this.loading=false;
        }

      });
    } else {
      this.loading=true;
      const returnUrl = environment.apiUrlBase + "Payment/PaymentResponse/" + btoa(location.origin + "/mfUserOrders#" + btoa(this.orderIds[0]));
      this.mfOrdersService.payment(this.orderIds, this.totalAmt, returnUrl).subscribe((data) => {
        this.loading = false;
        if (data.status.indexOf('Fail') >= 0) {
          this.isconfirmDialogMessage = true;
          this.confirmDialogMessage = 'Please try again after 10 minutes';
          this.confirmationService.confirm({
            //message: 'Please try again after few minutes',
            // header: 'Cancel Confirmation',
            icon: 'pi pi-info-circle',
            reject: () => {
              this.isconfirmDialogMessage = false;
              this.reload();
            }
          });

        } else {

          if (data.status == 'Success') {
            this.makePaymentBrowser(data.response);
          } else {
            this.paymentGatewayHtml = data.response;
            this.mandateType = 'paymentGateway';
            this.appendHTMLSnippetToDOM();
          }
        }
      });
    }

  }

  checkUpiPayment() {
    if (this.madeUpiPayment) {
      this.mfOrderInit = false;
      this.helperService.globalLoading = true;
    }
    if (this.hasUpiPaymentTimeout) {
      this.mfOrdersService.refreshData(true).subscribe(data => {
        this.helperService.globalLoading = false;
        this.madeUpiPayment = false;
        //this.helperService.routeNavigate('mfUserOrders');
        this.reload();
      });
    } else {
      let counter = setTimeout(() => {
        this.mfOrdersService.checkPaymentUpi(this.orderIds, this.loggedInUser.clientCode).subscribe((data) => {
          if (data) {
            this.mfOrdersService.refreshData(true).subscribe(data => {
              this.helperService.globalLoading = false;
              this.madeUpiPayment = false;
              if (this.type == 'Lumpsum') {
                //this.helperService.routeNavigate('mfUserOrders');
                this.helperService.reload();
              } else {
                this.mandateType = 'autoDebit';
              }
            });

          } else {
            this.checkUpiPayment();
          }
        });
      }, 6 * 1000);

    }
  }

  makePaymentBrowser(url) {
    const reqUrl = environment.apiUrlBase + url;
    window.location.href=reqUrl;
  }


  // makePaymentBrowserfake() {
  //   const returnUrl = environment.apiUrlBase + "Payment/PaymentResponse";
  //   const reqUrl = returnUrl
  //   //const that = this;
  //   const browser = this.iab.create(reqUrl, '_blank', 'hideurlbar=yes,hidenavigationbuttons=yes,location=no');
  //   browser.on('loadstop').subscribe(event => {

  //     if (event.url === returnUrl) {

  //       this.mfOrdersService.refreshData(true).subscribe(data => {
  //         if (this.type == 'Lumpsum') {
  //           //this.helperService.routeNavigate('mfUserOrders');
  //           this.reload();
  //           browser.close();
  //         } else {
  //           browser.close();
  //           setTimeout(() => {
  //             this.mandateType = 'autoDebit';
  //             this.ref.detectChanges();
  //             //alert('autoDebit called');
  //           }, 100);

  //         }

  //       });


  //     }
  //   });
  // }

  autoDebit() {
    this.helperService.globalLoading = true;
    let loggedInUser = this.helperService.getLoggedInUserInfo();
    loggedInUser = this.helperService.getLoggedInUserInfo();
    const mandate: Mandate = { bseMandateCode: this.mandateCode, clientCode: loggedInUser.clientCode };
    //const mandate: Mandate = { bseMandateCode: "6709458", clientCode: this.loggedInUser.clientCode };
    //this.loading = true;

    //this.mandatesService.add(mandate).subscribe()
    this.mandatesService.mandateAuthUrl(mandate).subscribe((data) => {

      //data = 'https://app.digio.in/#/gateway/login/ENA21083022515300053EY8VPR2U1TAP/vI3atY/sandeep.b.bhoir@gmail.com?redirect_url=https%3A%2F%2Fwww.bsestarmf.in%2FENACHResponse.aspx&logo=https%3A%2F%2Fwww.bsestarmf.in%2Fimages%2Fbsestarmfnew.jpg';

      this.makeAutoDebitBrowser(data);
      setTimeout(() => {
        this.helperService.globalLoading = false;
      }, 100);
    });
  }

  // makeAutoDebitBrowser(url){
  //   const returnUrl = "https://www.bsestarmf.in/ENACHResponse.aspx";
  //   const reqUrl = url;
  //   const browser = this.iab.create(reqUrl,'_blank','hideurlbar=yes,hidenavigationbuttons=yes,location=no');

  //   browser.on('loadstop').subscribe(event =>{
  //     console.log('event',event);
  //     if(event.url.toLowerCase().indexOf(returnUrl.toLowerCase()) == 0){
  //       console.log('closed');
  //       this.helperService.routeNavigate('mfUserOrders');
  //       browser.close();
  //     }
  //   });
  //  }

  openAutoDebitUrl() {
    const url = 'https://app.digio.in/#/gateway/login/ENA21083022515300053EY8VPR2U1TAP/vI3atY/sandeep.b.bhoir@gmail.com?redirect_url=https%3A%2F%2Fwww.bsestarmf.in%2FENACHResponse.aspx&logo=https%3A%2F%2Fwww.bsestarmf.in%2Fimages%2Fbsestarmfnew.jpg';
    this.makeAutoDebitBrowser(url);
  }

  makeAutoDebitBrowser(url) {
    const returnUrl = "https://www.bsestarmf.in/ENACHResponse.aspx";
    const reqUrl = url;
    if (this.type == 'AutoDebitCheck') {
      this.back();
    } else {
      //this.helperService.routeNavigate('mfUserOrders');
      this.reload();
    }
 //window.location.href=reqUrl;
 window.open(reqUrl, '_blank');
    //const browser = this.iab.create(reqUrl,'_blank','hideurlbar=yes,hidenavigationbuttons=yes,location=no');
   // const browser = this.iab.create(reqUrl, '_system', 'hideurlbar=yes,hidenavigationbuttons=yes,location=no');

  }
  checkAmount() {
    setTimeout(() => {
      if (this.totalAmt > this.minUpiAmount) {
        this.currentMethod = 'Netbanking'
      }
    }, 50);
  }

}
