import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalNotification } from '../../core/model';
import { GlobalNotificationsService } from '../../core/service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-home-global-notification',
  templateUrl: './home-global-notification.component.html',
  styleUrls: ['./home-global-notification.component.scss'],
})
export class HomeGlobalNotificationComponent implements OnInit, OnChanges {
  notifications: GlobalNotification[];
  loadComplete = false;
  currentStep = 0;
  //showNotification = false;
  @Input() showNotification = true;
  @Output() onNotification = new EventEmitter();
  constructor(private helperService: HelperService,
    public notificationService: GlobalNotificationsService,
    private router: Router) {

    }

  ngOnInit() {
    const search = {};
    search['IsActive'] = true;

    this.notificationService.searchData(search, null,null,false).subscribe((notifications: GlobalNotification[]) => {
      this.notifications = notifications;
      this.loadComplete = true;
      //console.log(this.notifications);
    });
    this.router.events.subscribe(() => {
      this.showNotification = false;
    });
  }



  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
    this.showNotification = false;
  }

  toggleNotification(){
    this.showNotification = !this.showNotification;
    if(this.showNotification){
      this.onNotification.emit();
    }
  }

}
