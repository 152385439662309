import { HelperService } from '../../../../shared/helperService';

import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { BseRegistration } from "../../../../core/model/bseRegistration";
import { ActivatedRoute, Router } from "@angular/router";
import { BseRegistrationsService } from "../bseRegistrations.service";
import { ConfirmationService } from "primeng/api";
import { LookupService } from "../../../../shared/lookupService";
import { BaseEditComponent } from "../../../../shared/baseEditComponent";
import { BseRegistrationsBaseEditComponent as CustomComponent } from "../../../user/bseRegistrations/bseRegistrations-edit/bseRegistrations-edit.component";
import { KycsService } from "../../kycs/kycs.service";
import { Kyc, User } from "../../../../core/model";
import { UsersService } from "../../../../core/service";
import { MessageService } from 'primeng/api';
import { UserProfilePageService } from "../../userprofile/userprofile.page.service";
import * as moment from 'moment';
import { UsersEditDialogComponent } from '../../users/users-edit/users-edit.component';
import { DialogService } from 'primeng';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-bseRegistrations-edit',
  templateUrl: './bseRegistrations-edit.component.html',
  styleUrls: ['./bseRegistrations-edit.component.scss'],
  providers: [ConfirmationService,DialogService]
})
export class BseRegistrationsEditComponent extends CustomComponent implements OnInit {
  bseRegistration: BseRegistration;
  currentStep: number;
  isCompleted: boolean = false;
  isAddNomineeVisible = false;
  kyc: Kyc = null;
  error = '';
  stages = [
    "PAN <br/> Verification",
    "Personal <br/> Details",
    "Nominee <br/> Details",
    "Bank <br/> Details",
    "Address",
    "Complete Onboarding",
  ];
  hasUserFilter = true;
  showConfirmation: boolean;
  validNomineeAllocation = false;
  data: any;
  validChequeFile = false;
  isRepeatpPanNo=false;
  validSignFile = false;
  isAlreadyPan = false;
  userData: User;
  panNoConfirm='';
  userId: any;
  constructor(
    private route: ActivatedRoute,
    public helperService: HelperService,
    public bseRegistrationsService: BseRegistrationsService,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService,
    private kycsService: KycsService,
    private usersService: UsersService,
    public messageService: MessageService,
    public userProfilePageService: UserProfilePageService,
    public dialogService: DialogService
  ) {
    super(bseRegistrationsService, router, formBuilder, confirmationService, lookupService);
  }


  ngOnInit() {

  }


  NomineeValidate() {
    this.loading=true;
    this.usersService.get(this.usersService.helperSvc.loggedInUser.id).subscribe(data => {
      this.usersService.helperSvc.setUserInfo(data);
      this.showConfirmation = false;

      setTimeout(() => {
        // this.currentStep = -1;
        this.showConfirmation = false;
        // let nomineeUrl = environment.apiUrlBase + "Nominee2Fa/Index/" + data.clientCode;
        let nomineeUrl ="http://localhost:50572/Nominee2Fa/Index/" + data.clientCode;
        this.loading=false;
        window.location.href=nomineeUrl;
      }, 10);
    })

  }

  navigateTo(url) {
    this.bseRegistrationsService.helperSvc.routeNavigate(url);
  }



}
