import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { SipDetail } from '../../../core/model';
import { SipDetailsService as service } from '../../user/sipDetails/sipDetails.service';


@Injectable({
    providedIn: 'root'
})

export class SipDetailsService extends service {
  cancelSip(items: SipDetail[], cancelAllSip: boolean = false) {
    const data = {data:items, cancelAllSip: cancelAllSip};
      return this.http.post<SipDetail>(this.apiUrl + 'Cancel', data)
        .pipe(
          map(response => {
            this.fetchData(true).subscribe( a => {
              //console.log('fetchData',a);
              return response;
            })
          }),
          catchError(this.handleError)
        );
    }

    sipFundData(platterGroup: string) {
      const data = {platterGroup:platterGroup};
        return this.http.post<SipDetail>(this.apiUrl + 'SipFundData', data)
          .pipe(
            map(response => {
              //console.log('payment',response);
              return response;
            }),
            catchError(this.handleError)
          );
      }
    sipData(platterGroup: string) {
      const data = {platterGroup:platterGroup};
        return this.http.post<SipDetail>(this.apiUrl + 'SipData', data)
          .pipe(
            map(response => {
              //console.log('payment',response);
              return response;
            }),
            catchError(this.handleError)
          );
      }
    removeMandate(mandateCode: string) {
      const data = {mandateCode:mandateCode};
        return this.http.post<SipDetail>(this.apiUrl + 'RemoveMandate', data)
          .pipe(
            map(response => {
              this.fetchData(true).subscribe( a => {
                //console.log('fetchData',a);
                return response;
              })
            }),
            catchError(this.handleError)
          );
      }
}
