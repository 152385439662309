import { Injectable } from '@angular/core';
import { MfOrder } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class MfOrdersService extends BaseService<MfOrder> {
    constructor() {
        super('MfOrder');
    }
}
