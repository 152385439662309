import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../lookupService';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Pipe({
  name: 'enumlabel'
})


export class EnumLabelPipe implements PipeTransform {
  constructor(private lookup: LookupService) {
  }

  transform(value: string, type: string): Observable<string> {
    return this.lookup.getLabelEnum(type, value).pipe(
      map(data => {
        //console.log('data', data);
        return data.length > 0 ? data[0]['name'] : '';
      })
    );
  }
}
