import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng';
import { FeedMorStarApi, Platter } from '../../core/model';
import { BseMFDetailsService } from '../../core/service';
import { BseMFDetailsWhitelistService } from '../../pages/custom/mf-order-booking/bseMFDetails.service';
import { LookupService } from '../../shared/lookupService';
import { HelperService } from '../../shared/helperService';
import { EChartsOption, graphic } from 'echarts';
import * as moment from 'moment';
import { FeedMorStarApisService } from '../../pages/custom/feedMorStarApis/feedMorStarApis.service';

@Component({
  selector: 'app-mf-fund-info',
  templateUrl: './mf-fund-info.component.html',
  styleUrls: ['./mf-fund-info.component.scss'],

  providers:[DynamicDialogRef, DynamicDialogConfig]

})
export class MfFundInfoComponent implements OnInit, OnChanges {
  @Input() FeedMorningStar: any;
  @Input() Isin: any;
  @Input() HidePayInfo = false;
  @Input() platter: Platter;
  @Input() amount: number;
  @Input() payType = 'Lumpsum';
  @Output() onPayment = new EventEmitter();
  @Output() onAmount = new EventEmitter();
  // @Output() onOpenSipLumpsum = new EventEmitter();

@Input() showSimilarFunds = true;
@Input() isFooterVisible: boolean = true;
  @Input() isSipLumpsumBtnVisible = true;
  @Input() isSipLumpsumPopupVisible = false;
  @Input() openFoundationFund = false;
  @Input() openSipLumpsumPopup = false;
  showMobileView = false;
  returnTenure = 'TTR-Return5Yr';
  top10hdCurrentLength = 5;
  isViewMoreButtonVisible = true;
  platterInputValue: number = null;
  platterValue: number = null;
  minAmount: number = 0;
  isexpandsheminformation = false;
  similarScehemes: FeedMorStarApi[] = [];
  whiteList: FeedMorStarApi[];
  navList: any[] = [];
  risk = '';
  riskPerc = 0;
  selectedType = 'SIP'; //'Lumpsum' ; //Sip
  //For Charts
  chartOption: EChartsOption;
  echart = graphic;
  selectedMonths = 100;
  dateArr = [];
  //End For Charts

  fundType: any;
  fundValue: number = null;
  paymentType = '';
  paymentOrderList = [];
  showPayment = false;
  //openSipLumpsumPopup=false;

  ruleList: any[];
  list: any[];
  selectedRule = null;

  constructor(
    private bseMFDetailsWhitelistService: BseMFDetailsWhitelistService,
    private feedMorStarApiService: FeedMorStarApisService,
   public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private helperService: HelperService
  ) {
    this.bseMFDetailsWhitelistService.getAll().subscribe((data: FeedMorStarApi[]) => {
      this.whiteList = data;

      this.showSimilarSchemes();

    });
    // this.helperService.backButtonClick.subscribe(backType => {
    //   this.backClick(backType);
    // });
    if(this.config?.data?.Isin){
      this.openSipLumpsumPopup=this.config.data.openSipLumpsumPopup;
      this.openFoundationFund=this.config.data.openFoundationFund;
      this.showSimilarFunds=this.config.data.showSimilarFunds;
      this.isFooterVisible=this.config.data.isFooterVisible;
      this.FeedMorningStar=this.config.data.FeedMorningStar;
      this.Isin=this.config.data.Isin;
    }

  }

  toggleSchemeInfo(): void {
    this.isexpandsheminformation = !this.isexpandsheminformation;
  }

  showMoreTopHolding(): void {
    this.isViewMoreButtonVisible = false;
    this.top10hdCurrentLength = this.FeedMorningStar.top10Holdings['T10HV2-HoldingDetail'].length;
  }

  ngOnChanges(changes: SimpleChanges): void {
     this.FeedMorningStar=changes.FeedMorningStar.currentValue;
     this.calculateRiskProfile();
    this.showSimilarSchemes();
    if (changes.Isin) {

      this.getNavHistory(this.Isin);
    }
  }


  ngOnInit() {



// if(this.FeedMorningStar.isin && !this.showSimilarFunds && !this.openFoundationFund){

//   this.helperService.setHistoryBackDataType(this.FeedMorningStar.isin);
// }
    if (!this.ruleList) {
      this.ruleList = JSON.parse(this.platter.ruleJson);
      this.updateGrid();
    }

    this.top10hdCurrentLength = 5;
    this.showSimilarSchemes();

   this.platterInputValue = this.selectedType == 'SIP' ? this.FeedMorningStar.minimumSip : this.FeedMorningStar.minimumInitial;
   this.platterValue = this.platterInputValue;
    this.showMobileView = this.helperService.isMobile;
    setTimeout(() => {
      this.getNavHistory(this.FeedMorningStar.isin);
    }, 500);
    this.calculateRiskProfile();
  }

  setSipLumpsumAmt(val: any): void {
    this.platterInputValue = val;
    this.checkPlatterAmount();
  }

  openPopView(item){
    const search = { 'Isin': item.isin };
      // const search = { 'SchemeCode': 'F0000109I2' };
      this.feedMorStarApiService.searchFund(null, null, search).subscribe((schemeInfoList: FeedMorStarApi[]) => {
        if (schemeInfoList && schemeInfoList.length) {
          const feedMorningStar = schemeInfoList[0];
          feedMorningStar.operations = JSON.parse(feedMorningStar.operations);
          feedMorningStar.performance = JSON.parse(feedMorningStar.performance);
          feedMorningStar.top10Holdings = JSON.parse(feedMorningStar.top10Holdings);
          this.openSipLumpsumPopup = true;
          this.FeedMorningStar = feedMorningStar;
        } else {
         // this.schemeNotFound = true;
        }
      });
  }
  amounts(amt){
    this.fundValue = amt;
  }
  paymentPopup(type) {
    this.paymentType = type;
    this.minAmount = type.toLowerCase() == 'sip' ? this.FeedMorningStar.minimumSip : this.FeedMorningStar.minimumInitial;
    this.fundValue= this.platterInputValue;
    this.paymentOrderList = [];
    this.paymentOrderList.push({
      code: this.FeedMorningStar.bseSchemeCode,
      name: this.FeedMorningStar.legalName,
      mfProviderName: this.FeedMorningStar.providerCompanyName,
      amount: 0
    });

    this.showPayment = true;
  }

  payment(type) {
    if(!this.helperService.isUserLoggedIn){
      document.getElementById('bntLogin').click();
      return false;
    }
    this.onAmount.emit(this.platterValue);
    this.onPayment.emit(type);
  }
  checkPlatterAmount() {
    var minValue = this.selectedType == 'SIP' ? this.FeedMorningStar.minimumSip : this.FeedMorningStar.minimumInitial;
    if (this.platterInputValue >= minValue) {
      this.platterValue = this.platterInputValue;
    } else {
      this.platterValue = minValue;
    }
  }
  showSimilarSchemes() {
    if (this.whiteList && this.whiteList.length && this.FeedMorningStar) {
      const similarScehemes = this.whiteList.filter(w => w.categoryName == this.FeedMorningStar.categoryName && w.isin != this.FeedMorningStar.isin)
      this.platterInputValue = this.selectedType == 'SIP' ? this.FeedMorningStar.minimumSip : this.FeedMorningStar.minimumInitial;
      this.platterValue = this.platterInputValue;
      this.similarScehemes = similarScehemes;
    } else {
      setTimeout(() => {
        this.showSimilarSchemes();
      }, 1000);
    }
  }

  backClick(backType){
    const type = backType;// this.helperService.getHistoryBackDataType();// this.route.snapshot.params['type'];
         if (type) {
          this.showSchemeName(type, false);
          // if(!this.showSimilarFunds){
          //   this.currentStep.emit(0);
          // }else{
          //   this.showSchemeName(type, false);
          // }




        }
  }

  showFundInfo(item) {
    //this.FeedMorningStar = item;
    // this.platterInputValue = this.selectedType == 'SIP' ? item.minimumSip : item.minimumInitial;
    // this.platterValue=this.platterInputValue;
    const str = item.legalName.trim().replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":;*?<>{}!]/g, '').toLowerCase();
    this.helperService.routeNavigate('funds/'+ str.trim());
    // this.helperService.routeNavigate('funds/'+item.isin);
    //this.showSchemeName(item.isin);


  }
  calculateRiskProfile() {
if (this.FeedMorningStar.indianRiskLevel=="Low Risk") {
  this.risk = "Low Risk";
  this.riskPerc = 10;
}
if (this.FeedMorningStar.indianRiskLevel=="Moderate Risk") {
  this.risk = "Moderate Risk";
  this.riskPerc = 30;
}
if (this.FeedMorningStar.indianRiskLevel=="Moderately High Risk") {
  this.risk = "Moderately High Risk";
  this.riskPerc = 50;
}
if (this.FeedMorningStar.indianRiskLevel=="High Risk") {
  this.risk = "High Risk";
  this.riskPerc = 70;
}
if (this.FeedMorningStar.indianRiskLevel=="Very High Risk") {
     this.risk = "Very High Risk";
     this.riskPerc = 90;
   }


 }
  showSchemeName(isin: string,setHistory = true): void {
    this.calculateRiskProfile();
    const search = { 'Isin': isin };
    // const search = { 'SchemeCode': 'F0000109I2' };
    this.feedMorStarApiService.searchFund(null, null, search).subscribe((schemeInfoList: FeedMorStarApi[]) => {
      if (schemeInfoList && schemeInfoList.length) {
        const feedMorningStar = schemeInfoList[0];
        feedMorningStar.operations = JSON.parse(feedMorningStar.operations);
        feedMorningStar.performance = JSON.parse(feedMorningStar.performance);
        feedMorningStar.top10Holdings = JSON.parse(feedMorningStar.top10Holdings);
        this.FeedMorningStar = feedMorningStar;
        setTimeout(() => {
          //window.scrollTo({ top: 10, behavior: "smooth" });
          this.helperService.scrollToSection('topScroll');
        }, 10);
        this.showSimilarSchemes();
      }
    });
    this.getNavHistory(isin);
    if(setHistory){
      this.helperService.setHistoryBackDataType(isin);
     }
  }

  getNavHistory(isin: string): void {
    this.navList = [];
    const search = { 'Isin': isin };
    this.feedMorStarApiService.getNavHistory(search).subscribe((navs: any[]) => {
      if (navs && navs.length) {
        this.navList = navs;
        this.filterMonth(100);
      }
    });
  }

  //For Graph

  filterMonth(no) {
    this.bindChart([], []);

    setTimeout(() => {
      this.selectedMonths = no;
      var data = [...this.navList];
      const dateArr = [];
      const dataArr = [];

      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - no);
      const oneMonthBack = [
        currentDate.getFullYear(),
        ("0" + currentDate.getMonth()).slice(-2),
        ("0" + currentDate.getDate()).slice(-2),
      ].join("-");
      data.forEach((val, index) => {
        if (no >= 100 || val.navDate.split("T")[0] >= oneMonthBack.toString()) {
          dateArr.push(val.navDate.split("T")[0]);
          dataArr.push(val.nav);
        }
      });

      this.bindChart(dateArr, dataArr)
    }, 300);


  }

  bindChart(dateArr, data) {

    const min1 = Math.round(Math.min(...data));//=> 0
    const max1 = Math.round(Math.max(...data)); //=> 100
    const that = this;
    this.chartOption = {
      tooltip: {
        trigger: "axis",
        position: function (point, params, dom, rect, size) {
          // where point is the current mouse position, and size has two properties: viewSize and contentSize, which are the size of the outer div and tooltip prompt box, respectively
          var x = point[0];//
          var y = point[1];
          var viewWidth = size.viewSize[0];
          var viewHeight = size.viewSize[1];
          var boxWidth = size.contentSize[0];
          var boxHeight = size.contentSize[1];
          var posX = 0; // x coordinate position
          var posY = 0; // y coordinate position

          if (x < boxWidth) {// Can't let go on the left
            posX = 5;
          } else {// Left down
            posX = x - boxWidth;
          }

          if (y < boxHeight) {// Can't let go above
            posY = 5;
          } else {// The top can be put down
            posY = y - boxHeight;
          }

          return [posX, posY];

        },
        formatter: function (params) {
          const label = moment(params[0].axisValue).format("D MMM YYYY") + "<br />" + "₹" + params[0].data;
          return label;
        }
      },
      title: {
        left: "center",
        text: "NAV",
        show: false,
        height:0
      },
      grid: {
        top:"5%",
        left:"10%",
        right:"5%",
        bottom: "10%"
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: dateArr,
        axisLabel: {
          formatter: function (value: any) {
            var label = moment(value).format("MMM 'YY");
            if (that.selectedMonths < 12) {
              label = moment(value).format("D. MMM");
            }
            return label;
          },
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        min: min1 - 5,
        max: max1 + 5,
        maxInterval: 10,
        minInterval: 0,
        splitNumber: 3,
        axisLabel: {
          formatter: function (value: any) {
            var label = "₹" + value;
            return label;
          },
        },

      },

      series: [
        {

          name: "Index",
          showSymbol: false,
          type: "line",
          symbol: "emptyCircle",
          symbolSize: 5,
          sampling: "none",
          animation: true,
          itemStyle: {
            color: "#0F4988",
            borderWidth: 3,
            borderColor: "green"
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#96B7DC'
            }, {
              offset: 1,
              color: '#FFFFFF'
            }])
          },
          data: data,
        },
      ],
    };
  }
  //ENd for Graph


  generateGrid(selectedFunds: any[]) {
    if (!this.whiteList) {
      return;
    }
    const totalAmount = this.amount;
    const list = [];

    for (const scheme of selectedFunds) {
      const fund = this.getFundByRule(scheme, list);
      if (fund) {

        list.push({
          name: fund.legalName, code: fund.bseSchemeCode, amount: scheme.amount,
          percentage: scheme.percentage, schemeType: scheme.schemeType,
          broadCategoryGroup: fund.broadCategoryGroup, categoryName: fund.categoryName,
          indianRiskLevel: fund.indianRiskLevel, return3Yr: fund.return3Yr,
          isin: fund.isin, providerCompanyName: fund.providerCompanyName
        });
      }

    }

    this.list = list;
  }


  getFundByRule(rule, list): FeedMorStarApi {
    let selectedFund = null;
    let funds = [];
    let fundList: FeedMorStarApi[] = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);
    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }

    funds = fundList.filter(lst => lst.isin === rule.schemeISIN)
    if (funds.length) {
      selectedFund = funds[0];
    }
    return selectedFund;
  }


  updateGrid() {
    const totalAmount = this.amount;

    const minAmt = this.payType == 'SIP' ? 100 : 100;
    let selectedFunds = [];
    if (totalAmount > 0 && this.ruleList && this.ruleList.length) {
      this.ruleList.forEach(st => {

        if (totalAmount >= (this.payType == 'SIP' ? st.minSipAmt : st.minLumpsumAmt)
          && totalAmount <= (this.payType == 'SIP' ? st.maxSipAmt : st.maxLumpsumAmt)) {
            let subTotal = 0;
          this.selectedRule = st;
          const schemes = st.schemes;
          schemes.sort((a, b) => b.percentage - a.percentage);
          schemes.reverse().forEach((rule, index) => {
            let amt = rule.percentage * totalAmount / 100;
            // const ratio = Math.round(amt / minAmt);
            // amt = ratio <= 0 ? minAmt : ratio * minAmt;
            amt = amt <= minAmt ? minAmt : amt;
            if (index >= schemes.length - 1) {
              amt = totalAmount - subTotal;
            }
            rule.amount = amt;
            subTotal += amt;
          });
          selectedFunds = schemes;
          return false;
        }
      });


      this.generateGrid(selectedFunds.reverse());
    }
   // this.sendMFJson();


  }

}

@Component({
  selector: 'app-mf-fund-info-dialog',
  templateUrl: './mf-fund-info.component.html',
  styleUrls: ['./mf-fund-info.component.scss'],
  providers:[DialogService]


})
export class MfFundInfoDialogComponent extends MfFundInfoComponent{

}
