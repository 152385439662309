import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Kyc } from '../../../core/model';
import { KycsService as service } from '../../user/kycs/kycs.service';



@Injectable({
    providedIn: 'root'
})

export class KycsService extends service {
  checkKyc(item: Kyc) {
    return this.http.post<Kyc>(this.apiUrl + 'CheckKyc', item)
    .pipe(
        map(data => {
          this.fetchData(true).subscribe(a => {

          })

          return data;
        }),
        catchError(this.handleError)
      );
  }

  registerKyc(item: Kyc) {
    return this.http.post<Kyc>(this.apiUrl + 'RegisterKyc', item)
    .pipe(
        map(data => {
          this.fetchData(true).subscribe(a => {

          })

          return data;
        }),
        catchError(this.handleError)
      );
  }

  checkUserKycPanNo(item: Kyc) {
    return this.http.post<Kyc>(this.apiUrl + 'checkUserKycPanNo', item)
    .pipe(
        map(data => {
          this.fetchData(true).subscribe(a => {

          })

          return data;
        }),
        catchError(this.handleError)
      );
  }
}
