import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';

import { Foundation, User } from '../../../core/model';
import { BaseService } from '../../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class UserProfilePageService extends BaseService<User> {
  constructor() {
    super('User');
    const loggedInUser = this.helperSvc.getLoggedInUserInfo();
    this.condition = {'Id': loggedInUser ? loggedInUser.id : 0};
    const type = 'UserProfilePage';   
    this.EnumStoreActions.GetAll = 'get_' + type + '_List';
    this.EnumStoreActions.GetData = 'get_' + type + '_Data';
    this.stateName = type;
  }
}
