import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RedeemRoutingModule } from './redeem-routing.module';
import { RedeemComponent } from './redeem.component';
import { SharedModule } from '../../../shared/shared.module';
import { ConfirmDialogModule, DynamicDialogModule } from 'primeng';


@NgModule({
  declarations: [
    RedeemComponent
  ],
  imports: [
    CommonModule,
    RedeemRoutingModule,
    SharedModule,
    DynamicDialogModule, ConfirmDialogModule,
  ],
  entryComponents: [RedeemComponent]
})
export class RedeemModule { }
