import { Injectable } from '@angular/core';
import { FinanceProfile } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class FinanceProfilesService extends BaseService<FinanceProfile> {
    constructor() {
        super('FinanceProfile');
    }
}
