import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { DialogService } from 'primeng';
import { MfSelectDialogComponent } from '../mf-select-dialog/mf-select-dialog.component';
import { BseMFDetailsWhitelistService } from '../../pages/custom/mf-order-booking/bseMFDetails.service';
import { FeedMorStarApi } from '../../core/model';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-mf-curdlist',
  templateUrl: './mf-curdlist.component.html',
  styleUrls: ['./mf-curdlist.component.scss'],
  providers: [DialogService]
})
export class MfCurdlistComponent implements OnInit, OnChanges {
  isPageLoaded = false;
  ruleList: any[];
  list: any[];
  @Input() ruleJson = '[]';
  @Input() showPerc = false;
  @Input() showAmount = false;
  @Input() allowEdit = false;
  @Input() totalAmount = 0;
  @Input() payType = 'Lumpsum';
  @Output() listJsonOut = new EventEmitter();
  @Output() chartDataOut = new EventEmitter();
  @Output() onShowFundInfo = new EventEmitter();
  @Input() isHeaderVisible = true;
  showMobileView = false;


  whiteList: FeedMorStarApi[];
  constructor(
    public dialogService: DialogService,
    private bseMFDetailsWhitelistService: BseMFDetailsWhitelistService,
    private helperService:HelperService
    ) {
    // this.bseMFDetailsWhitelistService
    if (!this.ruleList) {
      this.bseMFDetailsWhitelistService.getAll().subscribe((data: FeedMorStarApi[]) => {
        this.whiteList = data;
        //console.log('bseMFDetailsWhitelistService');
        this.updateGrid();
        //console.log('mf curdlist', this.whiteList);
      });
    }
    //this.showMobileView = this.helperService.isMobile;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isPageLoaded) {
      if (changes.totalAmount) {
        if(changes.totalAmount.currentValue != changes.totalAmount.previousValue){
          this.updateGrid();
        }
      }
      if (changes.ruleJson) {
        this.ruleList = JSON.parse(this.ruleJson);
        this.updateGrid();
      }
    }

  }

  ngOnInit() {
    if (!this.ruleList) {
      this.ruleList = JSON.parse(this.ruleJson);

      //console.log('ngOnInit()');
      this.updateGrid();
    }

    this.isPageLoaded = true;
  }
  ionViewWillEnter() {
    // if (!this.ruleJson) {
    //   this.ruleJson = '[]';
    // }
    if (!this.ruleList) {
      this.ruleList = JSON.parse(this.ruleJson);

      //console.log('ionViewWillEnter()');
      this.updateGrid();
    }

    this.isPageLoaded = true;

  }

  generateGrid(selectedFunds: any[]) {
    if (!this.whiteList) {
      return;
    }
    const totalAmount = this.totalAmount;
    const list = [];

    for (const scheme of selectedFunds) {
      const fund = this.getFundByRule(scheme, list);
      if (fund) {
        list.push({
          name: fund.legalName, code: fund.bseSchemeCode, amount: scheme.amount,
          perc: scheme.perc, schemeType: scheme.schemeType,
          mfProviderName: fund.providerCompanyName,
          fundInfo: {...fund}
        });
      }
    }
    this.list = list;
  }

  getRandomFund(filterFunds, listFund) {
    let found = false;
    let fund = filterFunds[0];
    while (!found) {
      let index = Math.floor(Math.random() * filterFunds.length);
      fund = filterFunds[index];
      const fundList = listFund.filter(lst => lst.bseSchemeCode == fund.bseSchemeCode);
      found = !(fundList && fundList.length);
    }
    return fund;
  }

  getFundByRule(rule, list): FeedMorStarApi {
    let selectedFund = null;
    let funds = [];
    let fundList = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);

    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }
    // const schemeTypes = rule.schemeType.split(';');
    rule.schemeType.split(';').forEach(type => {
      funds = funds.concat(fundList.filter(lst => lst.categoryName === type));
    });
    if (funds.length) {
      if (funds.length > 1) {
        selectedFund = this.getRandomFund(funds, list);

      } else {
        selectedFund = funds[0];
      }

    }
    return selectedFund;
  }

  updateGrid() {
    // //console.log('this.totalAmount', this.totalAmount);
    const totalAmount = this.totalAmount;

    const minAmt = 500;
    let selectedFunds = [];
    let selectedScheme = null;
    if (this.totalAmount > 0 && this.ruleList && this.ruleList.length) {

      let subTotal = 0;
      const rulesLength = this.ruleList.length;
      this.ruleList.forEach(mf => {
        if (totalAmount >= mf.minAmt && totalAmount <= mf.maxAmt) {
          mf.schemes.sort((a, b) => b.perc - a.perc);
          mf.schemes.reverse().forEach((rule, index) => {
            let amt = rule.perc * totalAmount / 100;
            const ratio = Math.round(amt / minAmt);
            amt = ratio <= 0 ? minAmt : ratio * minAmt;
            if (index >= mf.schemes.length - 1) {
              amt = totalAmount - subTotal;
            }
            rule.amount = amt;
            subTotal += amt;
          });
          selectedFunds = mf.schemes;
          selectedScheme = mf;
        }
      });

      this.chartDataOut.emit(selectedScheme);
      this.generateGrid(selectedFunds.reverse());
    }
    this.sendMFJson();


  }


  AddMF() {
    let fundList = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);
    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }
    const ref = this.dialogService.open(MfSelectDialogComponent, {
      data: {
        whiteList: fundList
      },
      header: 'Select Mutual Fund',
      width: '30%',
      height: 'auto'
    });
    ref.onClose.subscribe((mfDetail: any) => {
      if (mfDetail) {
        this.list.push({ name: mfDetail.name, fundInfo: {...mfDetail},
          code: mfDetail.bseSchemeCode, amount: 0, perc: 0 });
      }
      this.sendMFJson();
    });
  }

  updateMF(item) {
    let funds = [];
    let fundList = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);
    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }
    // const schemeTypes = rule.schemeType.split(';');
    item.schemeType.split(';').forEach(type => {
      funds = funds.concat(fundList.filter(lst => lst.categoryName === type));
    });

    const ref = this.dialogService.open(MfSelectDialogComponent, {
      data: {
        whiteList: funds
      },
      header: 'Select Mutual Fund',
      width: '50%',
      height: 'auto'
    });

    ref.onClose.subscribe((mfDetail: any) => {
      if (mfDetail) {
        item.name = mfDetail.legalName;
        item.mfProviderName = mfDetail.providerCompanyName;
        item.code = mfDetail.bseSchemeCode;
        item.fundInfo= {...mfDetail}
      }
      this.sendMFJson();
    });

  }

  deleteMF(item) {
    this.list.splice(this.list.indexOf(item), 1);
    this.sendMFJson();
  }

  sendMFJson() {
    // //console.log('sendMFJson this.list', this.list);
    this.listJsonOut.emit(JSON.stringify(this.list || []));
  }

  showFundInfo(item){
    this.onShowFundInfo.emit(item.fundInfo);
  }
}
