import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BseRegistrationsEditComponent } from './bseRegistrations-edit/bseRegistrations-edit.component';
import { BseRegistrationsEditComponent as NomineeValidationComponent } from './bseRegistrations-view/bseRegistrations-edit.component';

const routes: Routes = [
    { path: '', component: BseRegistrationsEditComponent },
    { path: 'nomineeValidation', component: NomineeValidationComponent },
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class BseRegistrationsRoutingModule {}
