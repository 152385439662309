import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Platter } from '../../../../core/model/platter';
import { ActivatedRoute, Router } from '@angular/router';
import { PlattersService } from '../platters.service';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { LookupService } from '../../../../shared/lookupService';
import { BaseViewComponent } from '../../../../shared/baseViewComponent';




export class PlattersBaseViewComponent extends BaseViewComponent<Platter> {
    navigatePath = 'platters';
    hasUserFilter = true;
    createForm(id: number, isDialog: boolean = false) {
        this.viewId = id;
        this.isDialog = isDialog;

        if (id > 0) {
            this.getData(id);
        }


    }

    edit() {
        this.router.navigate(['/' + this.navigatePath, 'edit', this.item.id]);
    }

}



@Component({
    selector: 'app-platters-view',
    templateUrl: './platters-view.component.html',
    styleUrls: ['./platters-view.component.scss'],
    providers: [ConfirmationService]
})
export class PlattersViewComponent extends PlattersBaseViewComponent implements OnInit {
    platterForm: FormGroup;

    constructor(private route: ActivatedRoute,
        public plattersService: PlattersService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(plattersService, router, formBuilder, confirmationService, lookupService);
    }

    ngOnInit() {

        const idText = this.route.snapshot.paramMap.get('id');

        let id = 0;
        if (idText.toLowerCase() !== 'add') {
            id = +this.route.snapshot.paramMap.get('id');
        }
        this.createForm(id);
    }
}


@Component({
    templateUrl: './platters-view.component.html',
    styleUrls: ['./platters-view.component.scss']
})
export class PlattersViewDialogComponent extends PlattersBaseViewComponent implements OnInit {
    constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
        public plattersService: PlattersService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(plattersService, router, formBuilder, confirmationService, lookupService);
    }
    ngOnInit() {

        const id = +this.config.data.id;
        this.createForm(id, true);
    }

    navigateHome() {
        this.ref.close('refresh');
    }
}



