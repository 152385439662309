import { Injectable } from '@angular/core';
import { WbLifeInsurance } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class WbLifeInsurancesService extends BaseService<WbLifeInsurance> {
    constructor() {
        super('WbLifeInsurance');
    }
}
