import { Injectable } from '@angular/core';
import { Unsubscriber } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class UnsubscribersService extends BaseService<Unsubscriber> {
    constructor() {
        super('Unsubscriber');
    }
}
