import { Component, Input, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-carousel-btn',
  templateUrl: './carousel-btn.component.html',
  styleUrls: ['./carousel-btn.component.scss'],
})
export class CarouselBtnComponent implements OnInit {

  @Input() carouselList: any[];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['',''],
    margin: 10,
    items: 3,
    nav: false,
    startPosition:4
  }

  constructor(
    private helperSvc: HelperService
  ) { }

  ngOnInit() {}

  
  routeCarousel(type: any): void {
    this.helperSvc.routeNavigate(type.url);
  }
 

}
