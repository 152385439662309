import { Component, OnInit, Input } from '@angular/core';
import { IFileUpload } from '../../shared/file-upload/file-upload.component';

@Component({
  selector: 'app-file-upload-view',
  templateUrl: './fileUploadView.component.html',
  styleUrls: ['./fileUploadView.component.scss'],
})
export class FileUploadViewComponent implements OnInit {

  @Input() fileJson: string;
  @Input() service: any;
  uploadedFiles: [];

  constructor() { }

  ngOnInit() {
    if (this.fileJson &&  this.fileJson.length > 2) {
      this.uploadedFiles = JSON.parse(this.fileJson);
    }
  }

  saveExportFile(blob: Blob, fileName: string) {
    if (window.navigator.msSaveOrOpenBlob) { // For IE:
      navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }

  downloadFile(file: IFileUpload) {
    this.service.fileDownload(file).subscribe((fileData) => {
      const blob = new Blob([fileData]);
      this.saveExportFile(blob, file.fileName);
    });
  }

}
