import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';

import { MfOrder } from '../../../core/model';
import { BaseService } from '../../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class MfUserOrdersService extends BaseService<MfOrder> {
  constructor() {
    super('MfOrder');
    const loggedInUser = this.helperSvc.getLoggedInUserInfo();
    this.condition = {'UserId': loggedInUser ? loggedInUser.id : 0};
    const type = 'BseUserOrder';
    this.EnumStoreActions.GetAll = 'get_' + type + '_List';
    this.EnumStoreActions.GetData = 'get_' + type + '_Data';
    this.stateName = type;
  }

  addOrder(item: MfOrder, userFilter: boolean = false) {
    return this.http.post<MfOrder>(this.apiUrl + 'Add', item)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  updateOrder(item: MfOrder, userFilter: boolean = false) {
    return this.http.post<MfOrder>(this.apiUrl + 'Update', item)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getSipOrderNo(item: MfOrder, userFilter: boolean = false) {

    return this.http.post<MfOrder>(this.apiUrl + 'GetSipOrderNo', item)
      .pipe(
        map(dataList => {
          this.fetchData(userFilter);
          return dataList;
        }),
        catchError(this.handleError)
      );

  }

  payment(orders: string[], amount: number, redirectUrl: string) {
    const request = { orders: orders, amount: amount, redirectUrl: redirectUrl }
    return this.http.post<any>(this.apiUrl + 'OrderPayment', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  paymentUpi(orders: string[], amount: number, mandateId: string, upiId: string) {
    const request = { orders: orders, amount: amount, mandateId: mandateId, upiId:upiId }
    return this.http.post<any>(this.apiUrl + 'OrderPaymentUpi', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  checkPaymentUpi(orders: string[], clientCode: string) {
    const request = { orders: orders, clientCode: clientCode}
    return this.http.post<any>(this.apiUrl + 'CheckPaymentUpi', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  redeemUnprocessedOrder(platterGroup: string) {
    const request = { platterGroup: platterGroup }
    return this.http.post<any>(this.apiUrl + 'RedeemUnprocessedOrder', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  dissolvePlatter(platterGroup: string) {
    const request = { platterGroup: platterGroup }
    return this.http.post<any>(this.apiUrl + 'DissolvePlatter', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  sendSellPlatterNotification(platterName: string, amount: number, sellType: string) {
    const request = { platterName: platterName, amount: amount, sellType: sellType }
    return this.http.post<any>(this.apiUrl + 'SendSellPlatterNotification', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  sendNachMandateFile(mandateId: string) {
    const request = { mandateId: mandateId}
    return this.http.post<any>(this.apiUrl + 'SendNachMandateFile', request)
      .pipe(
        map(response => {
          //console.log('payment',response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

}
