import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { BseMFDetailsService } from '../../core/service';
import { LookupService } from '../../shared/lookupService';

@Component({
  selector: 'app-mf-select-dialog',
  templateUrl: './mf-select-dialog.component.html',
  styleUrls: ['./mf-select-dialog.component.scss']
})
export class MfSelectDialogComponent implements OnInit {
  listBroadCategoryEnum: any[];
  listFundHouseEnum: any[];
  listMFDetails: any[];
  selectedMF: any;
  currentMFId:any;

  constructor(private bseMFDetailsService: BseMFDetailsService, private lookupService: LookupService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  }



  bindDropDown() {

    this.lookupService.getDataEnum('BroadCategory').subscribe(list => {
      this.listBroadCategoryEnum = list;
    });
    this.lookupService.getDataEnum('FundHouse').subscribe(list => {
      this.listFundHouseEnum = list;

    });

//     this.lookupService.getData('BseMFDetail').subscribe(list => {
//       this.listMFDetails = list;
// //console.log('this.listMFDetails', this.listMFDetails);
//     });

  }

  ngOnInit() {
    // this.bindDropDown();
    this.listMFDetails = this.config.data.whiteList;
  }

  submitMf(){
 //debugger;
 if (this.selectedMF) {
  this.ref.close(this.selectedMF);
}

// if (this.listMFDetails[0]) {
//   this.ref.close(this.listMFDetails[0]);
// }
  }

  selectMF(mf) {
    this.selectedMF = mf;
  }
}
