import { Directive, Input } from '@angular/core';
import { AbstractControl,  ValidationErrors, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
     selector: '[compare]',
     providers: [{provide: NG_VALIDATORS, useExisting: ComparisonValidator, multi: true}]
   })
  export class ComparisonValidator implements Validator {
    @Input("compare") controlList;
     validate(control: AbstractControl): ValidationErrors|null {
      let value = control.value;
      if(value != this.controlList)
      {
        return {
          compare: {
            data: value
          }
        }
      }
      // console.log("Control list",this.controlList ,sum ,"abc",control, control['parent'])

      return null;
     }
   }
