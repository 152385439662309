import { Injectable } from '@angular/core';
import { SipDetail } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class SipDetailsService extends BaseService<SipDetail> {
    constructor() {
        super('SipDetail');
    }
}
