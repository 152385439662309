import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { BseRegistrationsRoutingModule } from './bseRegistrations-routing.module';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BseRegistrationsEditComponent } from './bseRegistrations-edit/bseRegistrations-edit.component';
import { FormsModule } from '@angular/forms';
import { BseRegistrationsEditDialogComponent } from './bseRegistrations-edit-section/bseRegistrations-edit.component';
import { BseRegistrationsEditComponent as NomineeValidationComponent } from './bseRegistrations-view/bseRegistrations-edit.component';


@NgModule({
  imports: [CommonModule, SharedModule, BseRegistrationsRoutingModule,
    TableModule, DynamicDialogModule, ConfirmDialogModule, FormsModule],
  declarations: [BseRegistrationsEditComponent, BseRegistrationsEditDialogComponent,NomineeValidationComponent],
  entryComponents: [BseRegistrationsEditDialogComponent]
})
export class BseRegistrationsModule { }
