import { Injectable } from '@angular/core';
import { WatchList } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class WatchListsService extends BaseService<WatchList> {
    constructor() {
        super('WatchList');
    }
}
