import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, DialogService } from 'primeng';
import { EdExpense } from '../../../../core/model/edExpense';
import { EdExpensesService } from '../edExpenses.service';
import { EdExpensesEditDialogComponent } from '../edExpenses-edit/edExpenses-edit.component';
import { HelperService } from '../../../../shared/helperService';
import { ListComponent } from '../../../../shared/baseListComponent';
import { EdExpensesViewDialogComponent } from '../edExpenses-view/edExpenses-view.component';

@Component({
    selector: 'app-edExpenses-list',
    templateUrl: './edExpenses-list.component.html',
    styleUrls: ['./edExpenses-list.component.scss'],
    providers: [DialogService, ConfirmationService]
})

export class EdExpensesListComponent extends ListComponent<EdExpense> {
    typeName = 'edExpense';
    constructor(public dialogService: DialogService,
        public router: Router, public confirmationService: ConfirmationService,
        public edExpensesService: EdExpensesService, public helperService: HelperService) {
        super(dialogService, router, confirmationService, edExpensesService, helperService);

        this.searchKey = this.router.url + '_edExpenses_list';

        this.cols = [
			 {field: 'id', header: 'Id' }, 
			 {field: 'name', header: 'Name' }, 
			 {field: 'year', header: 'Year' }, 
			 {field: 'month', header: 'Month' }, 
			 {field: 'expenseDate', header: 'Date' }, 
			 {field: 'frequency', header: 'Frequency' }, 
			 {field: 'frequencyLabel', header: 'Frequency' }, 
			 {field: 'category', header: 'Head' }, 
			 {field: 'categoryLabel', header: 'Head' }, 
			 {field: 'amount', header: 'Amount' }, 
			 {field: 'uploadFile', header: 'Upload File' }, 
			 {field: 'notes', header: 'Notes' }, 
			 {field: 'userId', header: 'User Id' }, 
			 {field: 'createDate', header: 'Create Date' }, 
			 {field: 'updateDate', header: 'Update Date' }, 
			 {field: 'deleteDate', header: 'Delete Date' }, 
			 {field: 'updateById', header: 'Update By Id' }, 
			 {field: 'deleteById', header: 'Delete By Id' }, 
			 {field: 'isDelete', header: 'Is Delete' }, 
 		];

        this.currentRoute = 'edExpenses';
    }

    getComponentForDialog(type: string) {
        return type === 'pop_view' ? EdExpensesViewDialogComponent : EdExpensesEditDialogComponent;
    }

    getDialogHeaderText(item: EdExpense, type: string) {
        let headerText = '';
        switch (type) {
            case 'pop_edit':
                headerText = 'Edit EdExpense';
                break;
            case 'pop_add':
                headerText = 'Add new EdExpense';
                break;
            case 'pop_view':
                headerText = 'View EdExpense';
                break;
        }
        return headerText;
    }
}

