import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, timer, throwError } from 'rxjs';
import { map, catchError, switchMap, retry } from 'rxjs/operators';
import { ObservableStore } from '@codewithdan/observable-store';

import { StoreStateNew } from '../shared/interfaces';
import { AppInjector } from '../../app-injector.service';
import { BaseModel } from '../core/model/modelInterface';
import { EnvService } from '../core/env.service';
import { Router } from '@angular/router';
import { HelperService } from './helperService';


interface IStoreActions {
  GetAll: string;
  GetData: string;
  GetSearch: string;
}


export abstract class BaseService<T extends BaseModel> extends ObservableStore<StoreStateNew<T>> {

  protected apiUrl = '';
  protected apiBaseUrl = '';
  protected http: HttpClient;
  public env: EnvService;
  public router: Router;
  public helperSvc: HelperService;
  public stateName: string;
  protected EnumStoreActions: IStoreActions = {
    GetAll: 'get_List',
    GetData: 'get_Data',
    GetSearch: 'get_SearchList',
  };
  public condition: any = null;
  constructor(public type: string) {
    super({ trackStateHistory: false });
    // this.env = new EnvService();
    // this.http = new HttpClient(this.httpHandler);
    const injector = AppInjector.getInjector();
    this.http = injector.get(HttpClient);
    this.env = injector.get(EnvService);
    this.helperSvc = injector.get(HelperService);
    this.router = injector.get(Router);
    this.apiUrl = this.env.apiUrl + type + '/';

    this.stateName = type;
    this.apiBaseUrl = this.env.apiUrl;
    this.EnumStoreActions.GetAll = 'get_' + type + '_List';
    this.EnumStoreActions.GetData = 'get_' + type + '_Data';
    this.EnumStoreActions.GetSearch = 'get_' + type + '_Search';

    this.helperSvc.loginChange.subscribe(loginType => {

      const state = this.getState();

      // this.setState(obj, this.EnumStoreActions.GetData);

      const listName = this.stateName + 'List';
      if (state && state[listName]) {
        //console.log('Exist this.stateHistory', this.stateHistory);
        //console.log('loginType:' + listName, state[listName]);
        const obj = {};
        obj[listName] = null;
        this.setState(obj, this.EnumStoreActions.GetAll);
        //console.log('Latest Exist this.stateHistory', this.stateHistory);
        //console.log('Latest loginType:' + listName, state[listName]);
        // return of(state[listName]);
      }


    });

  }

  public clearData() {
    const state = this.getState();
    //console.log('Clear Data Before', state);
    this.setState(null);
  }

  public clearSearchData() {
    const state = this.getState();
    //console.log('searchHistory', this.helperSvc.searchHistory);
    const searchValue = this.helperSvc.searchHistory;
    if (state) {
      Object.keys(state).forEach(key => {
        //console.log(key, state[key]);
        if (key.indexOf('Search_') >= 0) {
          const search = this.helperSvc.searchHistory.filter(a => a.path === key);
          if (!search || !search.length) {
            const obj = {};
            obj[key] = null;
            this.setState(obj, this.EnumStoreActions.GetSearch);
          }
        }
      });
    }
    // this.setState(null);
  }

  public fetchData(userFilter: boolean = false) {
    let listName = this.stateName + 'List';
    let condition = this.condition;
    if (userFilter) {
      let loggedInUser = this.helperSvc.getLoggedInUserInfo();
      loggedInUser = this.helperSvc.getLoggedInUserInfo();
      // const userCondition = { UserId: loggedInUser ? loggedInUser.id : 0 };
      if (this.stateName === 'User') {
        condition = { ...this.condition || {}, ...{ Id: loggedInUser ? loggedInUser.id : 0 } };
      } else {
        condition = { ...this.condition || {}, ...{ UserId: loggedInUser ? loggedInUser.id : 0 } };
      }


      listName = 'User_' + this.stateName + 'List';
    }

    //console.log('fetchData  condition', this.condition);
    return this.http.post<T[]>(this.apiUrl + 'Get', { form: null, condition: condition })
      .pipe(
        map(dataList => {
          // if (dataList.length) {

          const obj = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetAll);
          // }
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public searchData(search: any, order: string[], condition: any, userFilter: boolean = false) {
    let searchCondition = condition || this.condition;
    if (userFilter) {
      const loggedInUser = this.helperSvc.getLoggedInUserInfo();
      searchCondition = searchCondition || {};
      searchCondition.UserId = loggedInUser ? loggedInUser.id : 0;
    }
    return this.http.post<T[]>(this.apiUrl + 'Search', { form: search, condition: searchCondition, orderColumns: order })
      .pipe(
        map(dataList => {
          const listName = 'Search_' + this.stateName + 'List';
          const obj = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetSearch);
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public exportExcel(search: any, order: string[]) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'DownloadExcelFile', { form: search }, httpOptions)
      .pipe(
        map(fileData => {
          //console.log('fileExcel fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );

  }

  public downloadPdf(Ids: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    const condition = { 'Ids': Ids };
    return this.http.post(this.apiUrl + 'DownloadPdf', { 'condition': condition }, httpOptions)
      .pipe(
        map(fileData => {
          //console.log('filePDF fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );

  }

  refreshData(userFilter: boolean = false) {
    return this.fetchData(userFilter)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(userFilter: boolean = false) {
    this.clearSearchData();
    //console.log('Router', this.router.url);
    const state = this.getState();
    //console.log('state', state);
    // pull from store cache
    let listName = this.stateName + 'List';

    const searchListName = 'Search_' + listName;
    if (state && state[searchListName]) {
      return of(state[searchListName]);
    } else {
      if (userFilter) {
        listName = 'User_' + this.stateName + 'List';
      }
      if (state && state[listName]) {
        //console.log('Exist this.stateHistory', this.stateHistory);
        return of(state[listName]);
      } else {
        // doesn't exist in store so fetch from server
        //console.log('not Exist');
        return this.fetchData(userFilter)
          .pipe(
            catchError(this.handleError)
          );
      }
    }



  }

  get(id: number, userFilter: boolean = false) {
    return this.getAll(userFilter)
      .pipe(
        map(dataList => {
          const filteredData = dataList.filter((fdata: { id: any; }) => fdata.id === id);
          const data = (filteredData && filteredData.length) ? filteredData[0] : null;
          const dataName = this.stateName;
          const obj = {};
          obj[dataName] = data;
          this.setState(obj, this.EnumStoreActions.GetData);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  add(item: T, userFilter: boolean = false) {
    return this.http.post(this.apiUrl + 'Add', item)
      .pipe(
        switchMap(usr => {
          // update local store with added item data
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          return this.fetchData(userFilter);
        }),
        catchError(this.handleError)
      );
  }

  update(item: T, userFilter: boolean = false) {
    return this.http.post(this.apiUrl + 'Update', item)
      .pipe(
        switchMap(usr => {
          // update local store with updated item data
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          return this.fetchData(userFilter);
        }),
        catchError(this.handleError)
      );
  }

  delete(id: number, userFilter: boolean = false) {
    //console.log('userFilter delete', userFilter);
    const obj = { Id: id };
    return this.http.post(this.apiUrl + 'Delete', obj)
      .pipe(
        switchMap(usr => {
          // update local store since item deleted
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          return this.fetchData(userFilter);
        }),
        catchError(this.handleError)
      );
  }

  fileUpload(file: File, fileName: string) {
    const formData = new FormData();
    if(fileName){
      formData.append('file', file, fileName + '.' + file.name.split('?')[0].split('.').pop());
    } else{
      formData.append('file', file);
    }

    //formData.append('fileName', fileName);

    return this.http.post<any>(this.apiUrl + 'FileUpload', formData)
      .pipe(
        map(fileInfo => {
          //console.log('fileUpload fileInfo', fileInfo);
          return fileInfo;
        }),
        catchError(this.handleError)
      );
  }

  fileDownload(fileInfo: any) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'Download', fileInfo, httpOptions)
      .pipe(
        map(fileData => {
          //console.log('fileUpload fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );
  }

  getLookup(userFilter: boolean = false) {
    return this.getAll(userFilter);
  }

  unique(name: string, item: T, condition: any) {

    return timer(1000)
      .pipe(
        switchMap(() => {
          return this.http.post(this.apiUrl + 'CheckUnique', { 'columnName': name, 'form': item, 'condition': condition })
            .pipe(
              map(isUnique => {
                return isUnique;
              }),
              catchError(this.handleError)
            );
        })
      );
  }

  protected handleError(error: any) {
    console.error('server error:', error);
    const injector = AppInjector.getInjector();
    const errorRouter = injector.get(Router);
    const helperSvc = injector.get(HelperService);
    if (error.status === 401) {
      //console.log('router', errorRouter);
      helperSvc.navigationUrl = errorRouter.url;
      errorRouter.navigate(['/login']);
    }

    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }

}
