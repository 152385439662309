import { Injectable } from '@angular/core';
import { GoalCalculator } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class GoalCalculatorsService extends BaseService<GoalCalculator> {
    constructor() {
        super('GoalCalculator');
    }
}
