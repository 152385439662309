import { Injectable } from '@angular/core';
import { Influencer } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class InfluencersService extends BaseService<Influencer> {
    constructor() {
        super('Influencer');
    }
}
