import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Login } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
  providedIn: 'root'
})

export class LoginsService extends BaseService<Login> {
  constructor() {
    super('Login');
  }

  validate(login: Login) {
    return this.http.post<any>(this.apiUrl + 'Validate', login)
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  sendLoginOtp(mobileNo) {
    return this.http.post<any>(this.apiUrl + 'SendLoginOtp', {'mobileNo': mobileNo})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  validateOtp(mobileNo, otp) {
    return this.http.post<any>(this.apiUrl + 'ValidateOtp', {'mobileNo': mobileNo, 'otp': otp})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  ValidateEmail(emailId, token) {
    return this.http.post<any>(this.apiUrl + 'ValidateEmail', {'emailId': emailId, 'token': token})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  loginMobile(mobileNo, otp) {
    return this.http.post<any>(this.apiUrl + 'LoginMobile', {'mobileNo': mobileNo, 'otp': otp})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  loginEmail(emailId, token) {
    return this.http.post<any>(this.apiUrl + 'LoginEmail', {'emailId': emailId, 'otp': token})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  loginPin(emailId, pin) {
    return this.http.post<any>(this.apiUrl + 'LoginPin', {'emailId': emailId, 'pin': pin})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }


}
