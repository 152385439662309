import { Injectable } from '@angular/core';
import { DistributorEnrollment } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class DistributorEnrollmentsService extends BaseService<DistributorEnrollment> {
    constructor() {
        super('DistributorEnrollment');
    }
}
