import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BseRegistration } from '../../../../core/model/bseRegistration';
import { ActivatedRoute, Router } from '@angular/router';
import { BseRegistrationsService } from '../bseRegistrations.service';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig, MessageService } from 'primeng';
import { LookupService } from '../../../../shared/lookupService';
import { BaseEditComponent } from '../../../../shared/baseEditComponent';
import { BseRegistrationsBaseEditComponent as CustomComponent } from '../../../user/bseRegistrations/bseRegistrations-edit/bseRegistrations-edit.component';


@Component({
  selector: 'app-bseRegistrations-edit',
  templateUrl: './bseRegistrations-edit.component.html',
  styleUrls: ['./bseRegistrations-edit.component.scss'],
  providers: [ConfirmationService]
})
export class BseRegistrationsEditDialogComponent extends CustomComponent implements OnInit {
  hasUserFilter = true;
  bseRegistration: BseRegistration;
  currentStep: number;
  isCompleted: boolean = false;
  isAddNomineeVisible = false;
  stages = ["PAN <br/> Verification", "Personal <br/> Details", "Nominee <br/> Details", "Bank <br/> Details", "Address"]
  showConfirmation: boolean;
  validNomineeAllocation = false;
  validChequeFile = false;
  validSignFile = false;
  editMode=true;
  constructor(
    private route: ActivatedRoute,
    public bseRegistrationsService: BseRegistrationsService,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService,
    public messageService: MessageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    super(
      bseRegistrationsService,
      router,
      formBuilder,
      confirmationService,
      lookupService,
    );
  }
  ngOnInit() {

    let id = 0;
    this.bseRegistrationsService.getAll(true).subscribe((bseRegistration: BseRegistration[]) => {
      this.bseRegistration = bseRegistration.length > 0 ? bseRegistration[0] : null;
      if (this.bseRegistration && this.bseRegistration.id > 0) {
        id = this.bseRegistration.id;
      }
      this.createForm(id);
      if (id <= 0) {
        setTimeout(() => {
          const userInfo = this.bseRegistrationsService.helperSvc.getLoggedInUserInfo();
          this.item = this.item || { defaultBank: false };
          this.item.name = userInfo.name;
          this.item.panNumber = userInfo.panNo;
          this.item.emailId = userInfo.emailId;
          this.item.mobileNo = userInfo.mobile;
          this.item.countryEnum = 'IN';
          this.item.countryEnumLabel = 'India';
          this.item.birthCountryEnum = 'IN';
          this.item.birthCountryEnumLabel = 'India';
          this.item.holdingNatureEnum = 'SI';
          this.item.holdingNatureEnumLabel = 'Single';
          this.item.taxStatusEnum = '01';
          this.item.taxStatusEnumLabel = 'Individual';
          this.item.incomeSlabEnum = '32';
          this.item.incomeSlabEnumLabel = '> 1 <=5 Lacs';
          //this.itemForm.patchValue(this.item);
          this.model = { ...this.item };
        }, 200);

      }
    });
     // const url = this.bseRegistrationsService.helperSvc.getCurrentRouteUrl();

if(this.config.data){
  if (this.config.data.type == 'bank') {
      this.currentStep = 3; //TODO reset to 0
    }

    if (this.config.data.type == 'nominee') {
      this.currentStep = 2; //TODO reset to 0
    }
}

  }

  preSubmit(itemValue) {
    if (itemValue) {
      itemValue.userId = this.service.helperSvc.loggedInUser.id;
      this.isCompleted = true;
    }
    return itemValue;
  }

  navigateHome() {

    this.bseRegistrationsService.getAll(true).subscribe((bseRegistration: BseRegistration[]) => {
      this.bseRegistration = bseRegistration.length > 0 ? bseRegistration[0] : null;
      this.item = this.bseRegistration;
      this.showConfirmation = true;
      this.loading=false;
      this.confirmationService.confirm({
        message: '',
        accept: () => {
          //Actual logic to perform a confirmation
          setTimeout(() => {
            this.ref.close('refresh');
          }, 100);
        }
      });
    });
  }

  navigateTo(url) {
    this.bseRegistrationsService.helperSvc.routeNavigate(url);
  }

  prev(): void {
    if (this.currentStep > 0) {
      this.currentStep = this.currentStep - 1;
    }
  }


  next(): void {
    if (this.currentStep >= 4) {
      this.currentStep = 4;
    } else {
      this.currentStep = this.currentStep + 1;
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.itemForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  checkInvalid(): void {
    const invalidList = this.findInvalidControls();

  }

  toggleAddNominee(): void {
    this.isAddNomineeVisible = !this.isAddNomineeVisible;
  }

  validateNext() {
    let controls = [];
    switch (this.currentStep) {
      case 0:
        controls = ["panNumber"];
        break;
      case 1:
        controls = [
          "name",
          "genderEnum",
          "sourceWealthEnum",
          "occupationEnum",
          "birthPlace",
          "signatureFile",
          "birthDate"
        ];
        break;
      case 2:
        //controls = ['nomineeName', 'nomineeRelationEnum', 'nomineeAllocation', 'nomineeMinor', 'nomineeBirthDate', 'nomineeGuardianName'];
        controls = [
          "nomineeName",
          "nomineeRelationEnum",
          "nomineeAllocation",
          "nomineeMinor",
          "nomineeBirthDate",
          "nomineeGuardianName",
          "nominee2Name",
          "nominee2RelationEnum",
          "nominee2Allocation",
          "nominee2Minor",
          "nominee2BirthDate",
          "nominee2GuardianName",
        ];
        break;
      case 3:
        controls = [
          "ifscCode",
          "bankName",
          "branchName",
          "accountNo",
          "accountNoConfirm",
          "chequeFile",
        ];
        break;

      case 4:
        controls = ["address1", "address2", "pincode", "city", "stateEnum"];
        break;
    }
    let valid = this.service.helperSvc.checkValidControls(
      this.itemForm,
      controls
    );

    this.validChequeFile = true;
    this.validSignFile = true;
    this.validNomineeAllocation = true;
    if (valid && this.currentStep == 2) {
      valid = this.validateNomineeSection();
    }
    if (valid && this.currentStep == 3) {
      valid = this.model.chequeFile.length > 10;
      this.validChequeFile = valid;
    }
    if (valid && this.currentStep == 1) {
      valid = this.model.signatureFile.length > 10;
      this.validSignFile = valid;
    }
    //console.log('Valid', valid);
    return valid;
  }


    validateNomineeSection(){
      if (this.model.nomineeAllocation + (this.model.enableNominee2 ? this.model.nominee2Allocation : 0) == 100) {
        this.validNomineeAllocation = true;
        return true;
      } else {
        this.validNomineeAllocation =false;
        return false;
      }
    }
  confirm() {
    this.loading=true;
   // this.error = '';
      this.formSubmit()
  }
  backToProfile() {
    this.ref.close('refresh');
  }
}




