import { Injectable } from '@angular/core';
import { PortfolioUserInvestmentInfo } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class PortfolioUserInvestmentInfoesService extends BaseService<PortfolioUserInvestmentInfo> {
    constructor() {
        super('PortfolioUserInvestmentInfo');
    }
}
