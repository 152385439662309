import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-bar100',
  templateUrl: './bar100.component.html',
  styleUrls: ['./bar100.component.scss'],
})
export class Bar100Component implements OnInit {
 
  @Input() color = '';
  @Input() percent = 0;
  @Input() text = '';
  @Input() imgUrl: string;
  
  constructor() { }

  ngOnInit() {
  }

  getRoundValue(value){
    return value > 0 ? Math.round(value) : 0;
  }

}
