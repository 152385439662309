import { Injectable } from '@angular/core';
import { GoogleDriveBackup } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class GoogleDriveBackupsService extends BaseService<GoogleDriveBackup> {
    constructor() {
        super('GoogleDriveBackup');
    }
}
