import { Component, HostListener, Input, OnInit, Output} from '@angular/core';
import { WatchList } from '../../core/model';
import { WatchListsService } from '../../pages/custom/watchLists/watchLists.service';
import { AppInjector } from '../../../app-injector.service';
import { HelperService } from '../../shared/helperService';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.scss'],
})
export class WatchListComponent implements OnInit {
  @Input() type;
  @Input() referenceNo;
  @Input() name;
  listData: WatchList[]=[];
  watchObj:WatchList = null;

  constructor(private helperService: HelperService, private watchListsService: WatchListsService, private messageService: MessageService) { }

  ngOnInit() {
    if(this.helperService.isUserLoggedIn){
this.bindWatchList();
    }
  }

  bindWatchList(){
    this.watchListsService.getAll(true).subscribe(data => {
      this.listData = [...data];
      this.checkWatchList();
    });
  }

  checkWatchList(){
    this.watchObj = this.listData.find(data => {
      return data.type == this.type && data.referenceNo == this.referenceNo
    });
  }

  addRemove(){
    if(!this.watchObj){
      if(!this.helperService.isUserLoggedIn){
        document.getElementById('bntLogin').click();
        return false;
      }
      const watchList = {
        name:this.name,
        referenceNo:this.referenceNo,
        type:this.type,
        userId:this.helperService.loggedInUser.id
      }
      this.watchObj = watchList;
      this.watchListsService.add(watchList, true).subscribe(data => {
        this.bindWatchList();
        this.messageService.add({severity:'success', detail:'Added to Watchlist', life: 2000});

      })
    } else{

      const watchDelObj = {... this.watchObj};
      this.watchObj = null;
      this.watchListsService.deleteWatchlist(watchDelObj).subscribe(data => {
        this.bindWatchList();
        this.messageService.add({severity:'error', detail:'Removed from Watchlist', life: 2000});
      })
    }
  }



}
