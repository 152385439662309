import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { DialogService } from 'primeng';
import { MfSelectDialogComponent } from '../mf-select-dialog/mf-select-dialog.component';
import { BseMFDetailsWhitelistService } from '../../pages/custom/mf-order-booking/bseMFDetails.service';
import { BucketStrategy, FeedMorStarApi } from '../../core/model';

@Component({
  selector: 'app-mf-bucket-list',
  templateUrl: './mf-bucket-list.component.html',
  styleUrls: ['./mf-bucket-list.component.scss'],
  providers: [DialogService]
})
export class MfBucketListComponent implements OnInit, OnChanges {
  isPageLoaded = false;
  ruleList: any[];
  list: any[];
  @Input() strategy: BucketStrategy;
  @Input() payType = 'Lumpsum';
  @Input() strategyRandomId = '';
  // @Input() ruleJson = '[]';
  // @Input() showPerc = false;
  // @Input() showAmount = false;
  // @Input() allowEdit = false;
  // @Input() totalAmount = 0;
  // @Input() payType = 'Lumpsum';
  // @Output() listJsonOut = new EventEmitter();
  // @Output() chartDataOut = new EventEmitter();


  whiteList: FeedMorStarApi[];
  constructor(public dialogService: DialogService,
    private bseMFDetailsWhitelistService: BseMFDetailsWhitelistService) {
      this.bseMFDetailsWhitelistService.getAll().subscribe((data: FeedMorStarApi[]) => {
        this.whiteList = data;
        //console.log('bseMFDetailsWhitelistService', this.whiteList);
        this.updateGrid();
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log('changes', changes);
    if (this.isPageLoaded) {
      if (changes.strategyRandomId) {
        this.ruleList = JSON.parse(this.strategy.ruleJson);
        this.updateGrid();
      }
    }

  }




  ngOnInit() {
    if (!this.ruleList) {
      this.ruleList = JSON.parse(this.strategy.ruleJson);
      //console.log('ngOnInit()');
      this.updateGrid();
    }

    this.isPageLoaded = true;
  }


  generateGrid(selectedFunds: any[]) {
    if (!this.whiteList) {
      return;
    }
    const totalAmount = this.strategy.amount;
    const list = [];

    for (const scheme of selectedFunds) {
      const fund = this.getFundByRule(scheme, list);
      if (fund) {
        list.push({
          name: fund.legalName, code: fund.bseSchemeCode, amount: scheme.amount,
          perc: scheme.perc, schemeType: scheme.schemeType,
          broadCategoryGroup:fund.broadCategoryGroup, categoryName: fund.categoryName,
          indianRiskLevel: fund.indianRiskLevel
        });
      }
    }


    // this.ruleList.forEach(function (mf) {
    //   if ( totalAmount >= mf.minAmt && totalAmount <= mf.minAmt) {
    //     mf.schemes.forEach(function (scheme) {
    //       const fund = this.getFundByRule(scheme);
    //       if (fund) {
    //         list.push({ name: fund.name, code: fund.bseSchemeCode, amount: scheme.amount,
    //           perc: scheme.perc, schemeType: scheme.schemeType });
    //       }
    //     });
    //   }
    // });

    this.list = list;
  }

  getRandomFund(filterFunds, listFund) {
    let found = false;
    let fund = filterFunds[0];
    while (!found) {
      let index = Math.floor(Math.random() * filterFunds.length);
      fund = filterFunds[index];
      const fundList = listFund.filter(lst => lst.bseSchemeCode == fund.bseSchemeCode);
      found = !(fundList && fundList.length);
    }
    return fund;
  }

  getFundByRule(rule, list): FeedMorStarApi {
    let selectedFund = null;
    let funds = [];
    let fundList = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);

    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }
    // const schemeTypes = rule.schemeType.split(';');
    rule.schemeType.split(';').forEach(type => {
      funds = funds.concat(fundList.filter(lst => lst.categoryName === type));
    });
    if (funds.length) {
      if (funds.length > 1) {
        selectedFund = this.getRandomFund(funds, list);

      } else {
        selectedFund = funds[0];
      }

    }
    return selectedFund;
  }

  getFundSchemeInfo(bucketPerc, rangeList){
    let schemes = [];
    rangeList.forEach(range => {
      if (bucketPerc >= range.minPerc && bucketPerc <= range.maxPerc) {
        for(let i=0; i< range.schemeNo; i++){
          const ratio = +range.ratio.split(';')[i];
          const schemeType = range.schemeType.split(';')[i].split('|').join(';');
          const perc = bucketPerc * ratio / 100;
          schemes.push({"schemeType":schemeType, "perc": perc});
        }
        return false;
      }
    });
    return schemes;
  }

  updateGrid() {
    // //console.log('this.totalAmount', this.totalAmount);
    const totalAmount = this.strategy.amount;
    let subTotal = 0;
    const minAmt = 5000;
    let selectedFunds = [];
    if (totalAmount > 0 && this.ruleList && this.ruleList.length) {
      const rulesLength = this.ruleList.length;
      this.ruleList.forEach(st => {
        if (totalAmount >= st.minAmt && totalAmount <= st.maxAmt) {

          //For Bucket 1
          const bucketSchemes1 = this.getFundSchemeInfo(this.strategy.bucket1, st.strategy.bucket1.range);
          const bucketSchemes2 = this.getFundSchemeInfo(this.strategy.bucket2, st.strategy.bucket2.range);
          const bucketSchemes3 = this.getFundSchemeInfo(this.strategy.bucket3, st.strategy.bucket3.range);

          const schemes = [...bucketSchemes1,...bucketSchemes2,...bucketSchemes3];
          //console.log('Bucketschemes List',schemes)
          schemes.sort((a, b) => b.perc - a.perc);
          schemes.reverse().forEach((rule, index) => {
            let amt = rule.perc * totalAmount / 100;
            // const ratio = Math.round(amt / minAmt);
            // amt = ratio <= 0 ? minAmt : ratio * minAmt;
            amt = amt <= minAmt ? minAmt : amt;
            if (index >= schemes.length - 1) {
              amt = totalAmount - subTotal;
            }
            rule.amount = amt;
            subTotal += amt;
          });
          selectedFunds = schemes;
          return false;
        }
      });


      this.generateGrid(selectedFunds.reverse());
    }
    this.sendMFJson();


  }

  updateMF(item) {
    let funds = [];
    let fundList = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);
    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }
    // const schemeTypes = rule.schemeType.split(';');
    item.schemeType.split(';').forEach(type => {
      funds = funds.concat(fundList.filter(lst => lst.categoryName === type));
    });
    const ref = this.dialogService.open(MfSelectDialogComponent, {
      data: {
        whiteList: funds
      },
      header: 'Select Mutual Fund',
      width: '90%',
      height: 'auto'
    });
    ref.onClose.subscribe((mfDetail: any) => {
      if (mfDetail) {
        item.name = mfDetail.legalName;
        item.code = mfDetail.bseSchemeCode;
      }
      this.sendMFJson();
    });
  }


  sendMFJson() {
    // //console.log('sendMFJson this.list', this.list);
    // this.listJsonOut.emit(JSON.stringify(this.list || []));
  }
}
