import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-meter',
  templateUrl: './meter.component.html',
  styleUrls: ['./meter.component.scss'],
})
export class MeterComponent implements OnInit {

  @Input() meterText: any;

  constructor() { }

  ngOnInit() { }

}
