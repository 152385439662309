import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { DialogService } from 'primeng';
import { MfSelectDialogComponent } from '../mf-select-dialog/mf-select-dialog.component';
import { BseMFDetailsWhitelistService } from '../../pages/custom/mf-order-booking/bseMFDetails.service';
import { FeedMorStarApi, Platter } from '../../core/model';
import { HelperService } from '../../shared/helperService';
// import { FeedMorStarApisService } from 'src/app/pages/custom/feedMorStarApis/feedMorStarApis.service';
import { FeedMorStarApisService } from '../../pages/custom/feedMorStarApis/feedMorStarApis.service';
import { MfFundInfoComponent, MfFundInfoDialogComponent } from '../mf-fund-info/mf-fund-info.component';

@Component({
  selector: 'app-mf-platter-list',
  templateUrl: './mf-platter-list.component.html',
  styleUrls: ['./mf-platter-list.component.scss'],
  providers: [DialogService]
})
export class MfPlatterListComponent implements OnInit, OnChanges {
  isPageLoaded = false;
  ruleList: any[];
  list: any[];
  @Input() platter: Platter;
  @Input() amount: number;
  @Input() payType = 'Lumpsum';
  @Output() onFundInfo = new EventEmitter();
  @Output() onPaymentInfo = new EventEmitter();
  @Input() isInvestButtonVisible = true;
  @Input() showPlatterSchemePopup = false;
  selectedRule = null;
  whiteList: FeedMorStarApi[];
  showMobileView = false;
  isBlurScheme = '';

  showFundsInfo = false;
  feedMorningStar: any;

  constructor(public dialogService: DialogService,
    private bseMFDetailsWhitelistService: BseMFDetailsWhitelistService,
    private helperService: HelperService,

    private feedMorStarApiService: FeedMorStarApisService) {
    this.bseMFDetailsWhitelistService.getAll().subscribe((data: FeedMorStarApi[]) => {
      this.whiteList = data;
    console.log('this.white1', this.whiteList);

      this.updateGrid();
    });
    this.helperService.loginChange.subscribe((loginType) => {
      if (!this.helperService.isUserLoggedIn) {
        this.isBlurScheme = 'ifLoggedIn';
      }else{
        this.isBlurScheme = '';
      }

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.helperService.isUserLoggedIn) {
      this.isBlurScheme = 'ifLoggedIn';
    }else{
      this.isBlurScheme = '';
    }
    if (this.isPageLoaded) {
      if (changes.amount) {
        this.ruleList = JSON.parse(this.platter.ruleJson);
        this.updateGrid();
      }
    }

  }




  ngOnInit() {
    if (!this.ruleList) {
      this.ruleList = JSON.parse(this.platter.ruleJson);
      this.updateGrid();
    }

    this.isPageLoaded = true;
    //this.showMobileView = this.helperService.isMobile;
  }


  generateGrid(selectedFunds: any[]) {
    if (!this.whiteList) {
      return;
    }
    const totalAmount = this.amount;
    const list = [];

    for (const scheme of selectedFunds) {
      const fund = this.getFundByRule(scheme, list);
      if (fund) {

        list.push({
          name: fund.legalName, code: fund.bseSchemeCode, amount: scheme.amount,
          percentage: scheme.percentage, schemeType: scheme.schemeType,
          broadCategoryGroup: fund.broadCategoryGroup, categoryName: fund.categoryName,
          indianRiskLevel: fund.indianRiskLevel, return3Yr: fund.return3Yr,
          isin: fund.isin, providerCompanyName: fund.providerCompanyName
        });
      }

    }

    this.list = list;
    console.log('this.white',this.list);
  }


  getFundByRule(rule, list): FeedMorStarApi {
    let selectedFund = null;
    let funds = [];
    let fundList: FeedMorStarApi[] = [];
    if (this.payType === 'Lumpsum') {
      fundList = this.whiteList.filter(lst => !lst.isCloseToInvestors);
    } else {
      fundList = this.whiteList.filter(lst => lst.isSipAvailable && !lst.isCloseToInvestors);
    }

    funds = fundList.filter(lst => lst.isin === rule.schemeISIN)
    if (funds.length) {
      selectedFund = funds[0];
    }
    return selectedFund;
  }


  updateGrid() {
    if (!this.helperService.isUserLoggedIn) {
      this.isBlurScheme = 'ifLoggedIn';
    }else{
      this.isBlurScheme = '';
    }
    const totalAmount = this.amount;

    const minAmt = this.payType == 'SIP' ? 100 : 100;
    let selectedFunds = [];
    if (totalAmount > 0 && this.ruleList && this.ruleList.length) {
      this.ruleList.forEach(st => {

        if (totalAmount >= (this.payType == 'SIP' ? st.minSipAmt : st.minLumpsumAmt)
          && totalAmount <= (this.payType == 'SIP' ? st.maxSipAmt : st.maxLumpsumAmt)) {
          let subTotal = 0;
          this.selectedRule = st;
          const schemes = st.schemes;
          schemes.sort((a, b) => b.percentage - a.percentage);
          schemes.reverse().forEach((rule, index) => {
            let amt = rule.percentage * totalAmount / 100;
            // const ratio = Math.round(amt / minAmt);
            // amt = ratio <= 0 ? minAmt : ratio * minAmt;
            amt = amt <= minAmt ? minAmt : amt;
            if (index >= schemes.length - 1) {
              amt = totalAmount - subTotal;
            }
            rule.amount = amt;
            subTotal += amt;
          });
          selectedFunds = schemes;
          return false;
        }
      });


      this.generateGrid(selectedFunds.reverse());
    }
    this.sendMFJson();


  }


  sendMFJson() {
    // //console.log('sendMFJson this.list', this.list);
    // this.listJsonOut.emit(JSON.stringify(this.list || []));
  }
  showFundInfo(item) {
    if (this.helperService.isUserLoggedIn) {
    const search = { Isin: item.isin };
    this.feedMorStarApiService.searchFund(null, null, search).subscribe((schemeInfoList: FeedMorStarApi[]) => {
      if (schemeInfoList && schemeInfoList.length) {
        this.feedMorningStar = schemeInfoList[0];
        this.feedMorningStar.operations = JSON.parse(this.feedMorningStar.operations);
        this.feedMorningStar.performance = JSON.parse(this.feedMorningStar.performance);
        this.feedMorningStar.top10Holdings = JSON.parse(this.feedMorningStar.top10Holdings);
        this.showFundsInfo = true;

        if (this.showFundsInfo && this.showPlatterSchemePopup) {
          this.openUsersDetails();
        }
      }
    });
  }
    // const search = { 'Isin': item.isin };
    // // const search = { 'SchemeCode': 'F0000109I2' };
    // this.feedMorStarApiService.searchFund(null, null, search).subscribe((schemeInfoList: FeedMorStarApi[]) => {
    //   if (schemeInfoList && schemeInfoList.length) {
    //     this.onFundInfo.emit({ ...schemeInfoList[0] });
    //   }
    // });
  }

  openUsersDetails() {
    const ref = this.dialogService.open(MfFundInfoDialogComponent, {

      data: {
        openSipLumpsumPopup: true,
        openFoundationFund: true,
        showSimilarFunds: false,
        isFooterVisible: false,
        FeedMorningStar: this.feedMorningStar,
        Isin: this.feedMorningStar?.isin

      },
      width: '50%'
    });
    // ref.onClose.subscribe((data: any) => {
    //   if (data) {
    //     let currentUrl = this.router.url;
    //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //       this.router.navigate([currentUrl]);
    //     });

    //   }
    // });



  }
  getPaymentInfo() {
    this.onPaymentInfo.emit([...this.list]);
  }
}
