import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { MandatesService } from '../../pages/custom/mfUserOrders/mandates.service';
import { environment } from '../../../environments/environment';
import { BseRegistration, Mandate, MfOrder } from '../../core/model';
import { BseRegistrationsService } from '../../core/service';
import { MfUserOrdersService } from '../../pages/custom/mfUserOrders/mfOrders.service';
import { HelperService } from '../../shared/helperService';
import { SipDetailsService } from '../../pages/custom/sipDetails/sipDetails.service';

@Component({
  selector: 'app-mf-payment',
  templateUrl: './mf-payment.component.html',
  styleUrls: ['./mf-payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MfPaymentComponent implements OnInit {

  @Input() type = '';
  @Input() isMonthlySaving = false;
  @Input() isemergencyFund = false;
  @Input() isMutualFund = false;
  @Input() amount: number = 0;
  @Input() bseMandateCode='';
  @Input() minAmount: number = 0;
  @Input() mfOrderList: any[];
  @Input() platter = 'NA';
  @Input() platterIcon = null;
  @Input() investmentInfo: any = null;
  @Output() goBack = new EventEmitter();

  minUpiAmount = 10000;
  dateGroup = '';
  totalAmt = null;
  mandateType = '';
  listSipDates: any[];
  selectedSipDate: any;
  loggedInUser: any;
  showPayment=true;
  //loading = false;
  mandateRegisterType = 'Netbanking';
  mandatePaymentType = 'Netbanking';
  mandateCode = '';
  paymentGatewayHtml = '';
  paymentGatewayHtmlSanitize: any;
  mandateAuthHtml = '';
  orderIds: any[] = [];
  orderRequestStart = false;
  mfOrder: MfOrder;
  sipStartDateCalendar: any;
  frequencyType = 'Monthly';
  noOfInstallment = 999;
  fundName = '';

  isOrderSuccess: boolean = false;
  isOrderFail: boolean = false;
  paymentRedirectUrl = location.origin;
  upiId = '';
  upiPaymentMessage = '';
  hasUpiPaymentTimeout = false;
  madeUpiPayment = false;
  mfOrderInit = false;

  @ViewChild('element', { static: true }) public viewElement: ElementRef;
  public element: any;
  showMobileView = false;

  mandateRegType: any[];
  mandatePayType: any[];
  orderTransNo = '';
  loading = false;
  currentMethod = 'Netbanking';
  constructor(
    public mfOrdersService: MfUserOrdersService,
    public mandatesService: MandatesService,
    public sipDetailsService: SipDetailsService,
    private http: HttpClient, private sanitizer: DomSanitizer,
    public helperService: HelperService,
    private bseRegistrationsService: BseRegistrationsService,
    // private iab: InAppBrowser,
    private ref: ChangeDetectorRef
  ) {
    this.dateGroup = moment().format('YYYYMMDDHHmmss');

  }

  //ngOnInit() {}
  ngOnInit() {

    this.loading = false;
    this.mandateType = this.type == 'SIP' ? 'register' : 'payment';
    if (this.amount) {
      this.totalAmt = this.amount;
    }

    if (this.type == 'Payment') {
      this.mandateType = 'paymentGateway';
      this.orderIds = this.mfOrderList;
      this.totalAmt = this.amount;
      this.payment();
    }

    this.mandateRegType = [
      {
        name: 'Netbanking',
        value: 'Netbanking'
      },
      {
        name: 'Debit Card',
        value: 'Debit Card'
      }
    ];

    this.mandatePayType = [
      {
        name: 'Netbanking',
        value: 'Netbanking'
      },
      {
        name: 'Debit Card',
        value: 'Debit Card'
      }
    ];


    this.loggedInUser = this.mfOrdersService.helperSvc.getLoggedInUserInfo();
    if (!this.loggedInUser.hasBseRegistration) {

      this.bseRegistrationsService.getAll(true).subscribe((bseRegistration: BseRegistration[]) => {
        var bseReg = bseRegistration.length > 0 ? bseRegistration[0] : null;
        if (bseReg == null || bseReg.registrationStatus != 'Success') {

          this.helperService.routeNavigate('bseRegistrations');

        }
      });
    }

    let fundNameList = [];

    this.mfOrderList.forEach((value) => {
      fundNameList.push(value.name)
    });

    let fundsName = (!this.platter || this.platter == 'NA') ? fundNameList.join(' ,') : this.platter + ' Platter';
    this.fundName = fundsName;
    const listSipDates = [];
    for (let i = 1; i <= 27; i++) {
      var num = i < 9 ? "0" + i : "" + i;
      if (i <= 3 || i == 21 || i == 22 || i == 23) {
        if (i == 1) {
          listSipDates.push({ "name": "1st of every month", "value": num });
        }
        if (i == 2) {
          listSipDates.push({ "name": "2nd of every month", "value": num });
        }
        if (i == 3) {
          listSipDates.push({ "name": "3rd of every month", "value": num });
        }
        if (i == 21) {
          listSipDates.push({ "name": "21st of every month", "value": num });
        }
        if (i == 22) {
          listSipDates.push({ "name": "22nd of every month", "value": num });
        }
        if (i == 23) {
          listSipDates.push({ "name": "23rd of every month", "value": num });
        }
      }
      else {
        listSipDates.push({ "name": i + "th of every month", "value": num });
      }
    }
    this.listSipDates = listSipDates;
    this.selectedSipDate = listSipDates[9];
    this.calcNextSipDate();

  }

  appendHTMLSnippetToDOM() {
    this.element = this.viewElement.nativeElement;
    const fragment = document.createRange().createContextualFragment(this.paymentGatewayHtml);
    this.element.appendChild(fragment);
  }

  back(): void {
    this.goBack.emit();
  }

  calcNextSipDate() {
    const checkDate = moment().add(1, 'months').add(-1, 'days');
    let startDateStr = checkDate.format("YYYY-MM-" + this.selectedSipDate.value);
    let startDateMan = moment(startDateStr, "YYYY-MM-DD");

    if (startDateMan < checkDate) {
      startDateMan.add(1, 'months');
    }
    this.sipStartDateCalendar = startDateMan.toDate();
  }

  registerMandate() {

    const checkDate = moment().add(1, 'months').add(-1, 'days');
    let startDateStr = checkDate.format("YYYY-MM-" + this.selectedSipDate.value);
    let startDateMan = moment(startDateStr, "YYYY-MM-DD");

    if (startDateMan < checkDate) {
      startDateMan.add(1, 'months');
    }
    this.sipStartDateCalendar = startDateMan.toDate();


    //this.mandateType = 'payment';

    let fundNameList = [];

    this.mfOrderList.forEach((value) => {
      fundNameList.push(value.name)
    });

    let fundName = (!this.platter || this.platter == 'NA') ? fundNameList.join(' ,') : this.platter + ' Platter';
    const mandate: Mandate = { name: fundName, startDate: this.sipStartDateCalendar, amount: this.totalAmt };
    //this.loading = true;
    this.loading = true;
    //this.mandatesService.add(mandate).subscribe()
    this.mandatesService.insert(mandate, true).subscribe((data) => {

      //this.loading = false;
      this.loading = false;

      if (data.bseStatus == "Success") {
        this.mandateCode = data.bseMandateCode;
        if(data.mandateType=="Nach Mandate" && this.type != 'Lumpsum'){

          this.mandatesService.sendNachMandateFile(data.bseMandateCode).subscribe((data) => {
          });
        }
        // this.mandateAuthHtml = data.bseStatus;
        //this.mandateType = 'payment';
        this.orderMF();
      } else {
        alert('Mandate fail, try again');
      }

    });

  }

  orderMF() {
    this.mfOrderInit = true;
    //this.loading = true;
    this.loading = true;

    this.orderTransNo = this.loggedInUser.id + '_'+ moment().format("YYYYMMDDhhmmss");
    let totalReqSuccess = 0;
    this.createOrder(this.mfOrderList, 0);
  }

  createOrder(orderList, index) {
    if (orderList.length > index) {
      const value = orderList[index];
      setTimeout(() => {
        this.orderRequestStart = true;
        const mfOrder: MfOrder = {
          name: null,
          transOrderCode: value.folio ? value.folio : null,
          transCode: 'New',
          refNo: this.type != 'Lumpsum' ? this.mandateCode : null,
          transNo: '',
          orderId: null,
          schemeCd: value.code,
          bseMfName: value.name,
          buySell: 'P',
          buySellType: 'FRESH',
          value: value.amount <= 0 ? this.totalAmt : value.amount,
          status: null,
          userId: this.loggedInUser.id,
          mfOrderTypeEnum: this.type,
          sipStartDate: this.type != 'Lumpsum' ? this.sipStartDateCalendar : null,
          frequencyTypeEnum: this.type != 'Lumpsum' ? this.frequencyType : null,
          noOfInstallment: this.type != 'Lumpsum' ? this.noOfInstallment : null,
          platterGroup: value.platterGroup ? value.platterGroup : (this.platter + '_' + this.dateGroup),
          platterName: this.platter,
          paymentStatus: '',
          orderTransNo: this.orderTransNo
          //
        };

        this.mfOrdersService.addOrder(mfOrder).subscribe(order => {

          if (this.type == 'Lumpsum') {
            this.updateOrderId(mfOrder, order, orderList, index, 0);
          } else {
            //For SIP
            setTimeout(() => {
              this.mfOrdersService.getSipOrderNo(order).subscribe(order => {
                this.updateOrderId(mfOrder, order, orderList, index, 0)
              });
            }, 1000);
          }

        });
      }, 1000);
    } else {
      this.payment();
    }
  }

  updateOrderId(mfOrder, order, orderList, index, attempt) {
    mfOrder.orderId = order.orderId;
    if (order.orderId) {
      this.orderIds.push("" + order.orderId);
      this.createOrder(orderList, ++index);
    } else {
      if (attempt < 2) {
        this.reOrder(order, orderList, index, ++attempt);
      }
      else {
        this.createOrder(orderList, ++index);
      }
    }
  }

  reOrder(mfOrder, orderList, index, attempt) {
    this.mfOrdersService.updateOrder(mfOrder).subscribe(order => {

      if (this.type == 'Lumpsum') {
        this.updateOrderId(mfOrder, order, orderList, index, attempt);
      } else {
        //For SIP
        setTimeout(() => {
          this.mfOrdersService.getSipOrderNo(mfOrder).subscribe(order => {
            this.updateOrderId(mfOrder, order, orderList, index, attempt)
          });
        }, 1000);
      }




      // mfOrder.orderId = order.orderId;
      // if (order.orderId) {
      //   this.orderIds.push("" + order.orderId);
      //   this.createOrder(orderList, ++index);
      // } else {

      // }
    });
  }

  payment() {
    if (this.currentMethod == 'UPI') {
      this.mfOrdersService.paymentUpi(this.orderIds, this.totalAmt, this.mandateCode, this.upiId).subscribe((data) => {
        //this.loading = false;
        this.loading = false;
        this.mandateType = 'paymentUpi';
        this.upiPaymentMessage = data.message;
        if (data.status == 'Success') {
          this.checkUpiPayment();
          setTimeout(() => {
            this.hasUpiPaymentTimeout = true;
          }, 3*60*1000);
        } else {

        }
      });
    } else {

      const returnUrl = environment.apiUrlBase + "Payment/PaymentResponse/" + btoa(location.origin + "/mfUserOrders#" + btoa(this.orderIds[0]));
      this.mfOrdersService.payment(this.orderIds, this.totalAmt, returnUrl).subscribe((data) => {
        //this.loading = false;
        this.loading = false;
        // this.paymentGatewayHtml = data.response;
        // this.mandateType = 'paymentGateway';
        // this.appendHTMLSnippetToDOM();
        if (data.status == 'Success') {
          this.makePaymentBrowser(data.response);
        } else {
          this.paymentGatewayHtml = data.response;
          this.mandateType = 'paymentGateway';
          this.appendHTMLSnippetToDOM();
        }
      });
    }
  }

  checkUpiPayment() {
    if(this.madeUpiPayment){
      this.mfOrderInit = false;
      this.loading = true;
    }
    if (this.hasUpiPaymentTimeout) {
      this.mfOrdersService.refreshData(true).subscribe(data => {
        this.loading = false;
        this.madeUpiPayment = false;
        this.helperService.routeNavigate('mfUserOrders');
      });
    } else {
      let counter = setTimeout(() => {
        this.mfOrdersService.checkPaymentUpi(this.orderIds, this.loggedInUser.clientCode).subscribe((data) => {
          if (data) {
            this.mfOrdersService.refreshData(true).subscribe(data => {
              this.loading = false;
              this.madeUpiPayment = false;
              if (this.type == 'Lumpsum') {
                this.helperService.routeNavigate('mfUserOrders');
              } else {
                this.mandateType = 'autoDebit';
                this.removeMandate();
              }
            });

          } else {
            this.checkUpiPayment();
          }
        });
      }, 6 * 1000);

    }
  }

  removeMandate(){
    this.sipDetailsService.removeMandate(this.bseMandateCode).subscribe((data) => {
     });
  }

  makePaymentBrowser(url) {
    // const returnUrl = environment.apiUrlBase + "Payment/PaymentResponse/" + btoa(location.origin + "/mfUserOrders#" + btoa(this.orderIds[0]));
    const reqUrl = environment.apiUrlBase + url;
    window.location.href=reqUrl;
    // window.location.href = returnUrl;
    // const returnUrl = environment.apiUrlBase + "Payment/PaymentResponse";
    // const reqUrl = environment.apiUrlBase + url;
    // const that = this;
    //window.open(reqUrl, '_blank', 'hideurlbar=yes,hidenavigationbuttons=yes,location=no');
    //window.location.href=reqUrl;

  }

  // makePaymentBrowserfake() {
  //   const returnUrl = environment.apiUrlBase + "Payment/PaymentResponse";
  //   const reqUrl = returnUrl
  //   //const that = this;
  //   const browser = this.iab.create(reqUrl, '_blank', 'hideurlbar=yes,hidenavigationbuttons=yes,location=no');
  //   browser.on('loadstop').subscribe(event => {

  //     if (event.url === returnUrl) {

  //       this.mfOrdersService.refreshData(true).subscribe(data => {
  //         if (this.type == 'Lumpsum') {
  //           this.helperService.routeNavigate('mfUserOrders');
  //           browser.close();
  //         } else {
  //           browser.close();
  //           setTimeout(() => {
  //             this.mandateType = 'autoDebit';
  //             this.removeMandate();
  //             this.ref.detectChanges();
  //             //alert('autoDebit called');
  //           }, 100);

  //         }

  //       });


  //     }
  //   });
  // }

  autoDebit() {
    this.loading = true;
    const loggedInUser = this.helperService.getLoggedInUserInfo();
    const mandate: Mandate = { bseMandateCode: this.mandateCode, clientCode: this.loggedInUser.clientCode };
    //const mandate: Mandate = { bseMandateCode: "6709458", clientCode: this.loggedInUser.clientCode };
    //this.loading = true;

    //this.mandatesService.add(mandate).subscribe()
    this.mandatesService.mandateAuthUrl(mandate).subscribe((data) => {

      //data = 'https://app.digio.in/#/gateway/login/ENA21083022515300053EY8VPR2U1TAP/vI3atY/sandeep.b.bhoir@gmail.com?redirect_url=https%3A%2F%2Fwww.bsestarmf.in%2FENACHResponse.aspx&logo=https%3A%2F%2Fwww.bsestarmf.in%2Fimages%2Fbsestarmfnew.jpg';

      this.makeAutoDebitBrowser(data);
      setTimeout(() => {
        this.loading = false;
      }, 100);
    });
  }

  // makeAutoDebitBrowser(url){
  //   const returnUrl = "https://www.bsestarmf.in/ENACHResponse.aspx";
  //   const reqUrl = url;
  //   const browser = this.iab.create(reqUrl,'_blank','hideurlbar=yes,hidenavigationbuttons=yes,location=no');

  //   browser.on('loadstop').subscribe(event =>{
  //     console.log('event',event);
  //     if(event.url.toLowerCase().indexOf(returnUrl.toLowerCase()) == 0){
  //       console.log('closed');
  //       this.helperService.routeNavigate('mfUserOrders');
  //       browser.close();
  //     }
  //   });
  //  }

  openAutoDebitUrl() {
    const url = 'https://app.digio.in/#/gateway/login/ENA21083022515300053EY8VPR2U1TAP/vI3atY/sandeep.b.bhoir@gmail.com?redirect_url=https%3A%2F%2Fwww.bsestarmf.in%2FENACHResponse.aspx&logo=https%3A%2F%2Fwww.bsestarmf.in%2Fimages%2Fbsestarmfnew.jpg';
    this.makeAutoDebitBrowser(url);
  }

  makeAutoDebitBrowser(url) {
    const returnUrl = "https://www.bsestarmf.in/ENACHResponse.aspx";
    const reqUrl = url;
    this.helperService.routeNavigate('mfUserOrders');
    //const browser = this.iab.create(reqUrl,'_blank','hideurlbar=yes,hidenavigationbuttons=yes,location=no');
    const browser = window.open(reqUrl, '_system', 'hideurlbar=yes,hidenavigationbuttons=yes,location=no');

  }
  checkAmount(){
    setTimeout(() => {
      if(this.totalAmt > this.minUpiAmount){
        this.currentMethod = 'Netbanking'
      }
    }, 50);
  }

}
