import { Injectable } from '@angular/core';
import { FeedTransaction } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class FeedTransactionsService extends BaseService<FeedTransaction> {
    constructor() {
        super('FeedTransaction');
    }
}
