import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { User } from '../../../../core/model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../users.service';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { LookupService } from '../../../../shared/lookupService';
import { BaseTemplateEditComponent } from '../../../../shared/baseTemplateEditComponent';




export class UsersBaseEditComponent extends BaseTemplateEditComponent<User> {
    navigatePath = 'users';

    

    
calendarBirthDate: Date =null;
 calendarLastLogin: Date =null;

    createForm(id: number, isDialog: boolean = false) {
        this.editId = id;
        this.isDialog = isDialog;

        if (id > 0) {
            this.getData(id);
            // this.bindDropDown() => Added in the Subscribe method.
        } else {
            this.item = {};
            this.model = { ...this.item };
            this.bindDropDown();
        }
    }

    bindDropDown() {
        

    }

    setDateCalenderValues(item) {
        
if (item.birthDate) {
    this.calendarBirthDate = new Date(item.birthDate);
}
 if (item.lastLogin) {
    this.calendarLastLogin = new Date(item.lastLogin);
}
        return item;
    }

    preSubmit(itemValue) {
        if (typeof (itemValue.userId) !== 'undefined') {
            itemValue.userId = this.service.helperSvc.loggedInUser.id;
        }
        return itemValue;
    }
}



@Component({
    selector: 'app-users-edit',
    templateUrl: './users-edit.component.html',
    styleUrls: ['./users-edit.component.scss'],
    providers: [ConfirmationService]
})
export class UsersEditComponent extends UsersBaseEditComponent implements OnInit {
    userForm: FormGroup;

    constructor(private route: ActivatedRoute,
        public usersService: UsersService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(usersService, router, formBuilder, confirmationService, lookupService);
    }

    ngOnInit() {
        this.ionViewWillEnter();
    }
    ionViewWillEnter() {

        const idText = this.route.snapshot.paramMap.get('id');

        let id = 0;
        if (idText.toLowerCase() !== 'add') {
            id = +this.route.snapshot.paramMap.get('id');
        }
        this.createForm(id);
    }
}


@Component({
    templateUrl: './users-edit.component.html',
    styleUrls: ['./users-edit.component.scss']
})
export class UsersEditDialogComponent extends UsersBaseEditComponent implements OnInit {
    constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
        public usersService: UsersService,
        public router: Router,
        public formBuilder: FormBuilder,
        public confirmationService: ConfirmationService,
        public lookupService: LookupService
    ) {
        super(usersService, router, formBuilder, confirmationService, lookupService);
    }
    ngOnInit() {
        this.ionViewWillEnter();
    }
    ionViewWillEnter() {

        const id = +this.config.data.id;
        this.createForm(id, true);
    }

    navigateHome() {
        this.ref.close('refresh');
    }
}



