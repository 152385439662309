import { Injectable } from '@angular/core';
import { ContactRequest } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ContactRequestsService extends BaseService<ContactRequest> {
    constructor() {
        super('ContactRequest');
    }
}
