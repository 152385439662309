import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-current-loan',
  templateUrl: './current-loan.component.html',
  styleUrls: ['./current-loan.component.scss'],
})
export class CurrentLoanComponent implements OnInit {

    @Input() currentLoanEmi = 0;

  constructor() { }

  ngOnInit() {}

}
