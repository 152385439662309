import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { EChartsOption, graphic } from 'echarts';
import { MfPlatterListComponent } from '../mf-platter-list/mf-platter-list.component';
import { MfPaymentComponent } from '../mf-payment/mf-payment.component';
import { Platter } from '../../core/model';
import { PlattersService } from '../../core/service';
import { LookupService } from '../../shared/lookupService';
import { HelperService } from '../../shared/helperService';
import { PlattersBaseViewComponent as CustomComponent } from '../../pages/user/platters/platters-view/platters-view.component';
@Component({
  selector: 'app-platteramount',
  templateUrl: './platteramount.component.html',
  styleUrls: ['./platteramount.component.scss'],
  providers: [ConfirmationService],
  encapsulation: ViewEncapsulation.None
})


export class PlatteramountComponent extends CustomComponent implements OnInit {
  @Input() Id: number;
  chartOption: EChartsOption;
  echart = graphic;
  platterForm: FormGroup;
  hasUserFilter = false;
  showFundInfo = false;
  showPayment = false;
  gaugeValue = 0;
  selectedYear = '';
  selectedAmount = 0;
  selectedType = 'sip'; //'Lumpsum' ; //Sip
  platterInputValue: number = null;
  platterValue: number = null;
  platterRules: any[];
  selectedRule: any;
  feedMorningStar: any;
  loading = false;
  platterFirstRule: any;
  paymentOrderList = [];
  showMobileView: boolean = false;
  currentStep = 0;
  investmentType = 'Lumpsum';
  isTabsVisible = false;
  isInvstButtonVisible = false;
  detailHeading = 'Platter Details';
  currentRouteId: any;
  isChangeMinAmtVisible = false;
  showStep=true;
  @ViewChild('mfList') mfList: MfPlatterListComponent;
  @ViewChild('mfPayment') mfPayment: MfPaymentComponent;
  innerCurrentStep = 0;

  platters: Platter[];
  returnType = 'return3Yr';
  returnTypeText = '3Y Returns';
  listFilterDays: any[];

  isPayBtnVisible = false;
  constructor(private route: ActivatedRoute,
    public plattersService: PlattersService,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService,
    public helperService: HelperService
  ) {
    super(plattersService, router, formBuilder, confirmationService, lookupService);
  }

  ngOnInit() {
    // const idText = this.route.snapshot.paramMap.get('id');
    // console.log('idText',idText);
    // this.currentRouteId = idText;
    // let id = 0;
    // if (idText.toLowerCase() !== 'add') {
    //   id = +this.route.snapshot.paramMap.get('id');
    // }
    this.createForm(this.Id);
    this.bindChart();
    this.viewWillEnter();
    this.listFilterDays = [
      {id:'return10Yr',name: '1Y Returns' },
      {id:'return1Yr',name: '3Y Returns' },
      {id:'return3Yr',name: '5Y Returns' },
      {id:'return5Yr',name: '10Y Returns' }
    ];
  }

  viewWillEnter() {
    this.plattersService.getAll(false).subscribe(data => {
      // this.platters = data.filter(d => d.displayHome);
      const platters = data.filter( a=> a.isActive);
      this.platters = [...platters];
    });

  }
  updateGauge(amount, selectedYear) {
    this.selectedAmount = amount;
    const gaugeValue = ((amount - this.selectedRule.sampleValue) / amount * 100);
    this.gaugeValue = Math.round(gaugeValue);
    this.selectedYear = selectedYear;
  }
  onDataLoad(item: Platter) {

    this.platterInputValue = this.item.investmentType == 'SIP' ? this.item.minAmountSip : this.item.minAmount;
    this.platterValue = this.platterInputValue;
    this.selectedType = this.item.investmentType;


    this.platterRules = JSON.parse(item.ruleJson);
    this.updateRule(this.platterRules[0]);
    this.platterFirstRule = this.platterRules[0];
    this.checkPlatterAmount();
  }

  checkPlatterAmount() {
    var minValue = this.selectedType == 'SIP' ? this.platterFirstRule.minSipAmt : this.platterFirstRule.minLumpsumAmt;
    if (this.platterInputValue >= minValue) {
      this.platterValue = this.platterInputValue;
    } else {
      this.platterValue = minValue;
    }
  }

  getSchemes() {

  }

  updateRule(rule) {
    this.selectedRule = rule;
    this.updateGauge(this.selectedRule.sampleValue1Yr, 'SampleValue1Yr');
  }

  getFundInfoData(fundInfo: any) {
    fundInfo.operations = JSON.parse(fundInfo.operations);
    fundInfo.performance = JSON.parse(fundInfo.performance);
    fundInfo.top10Holdings = JSON.parse(fundInfo.top10Holdings);
    this.feedMorningStar = fundInfo;
    //console.log('this.feedMorningStar', this.feedMorningStar);
    this.showFundInfo = true;
  }

  payment(orderList) {
    this.paymentOrderList = orderList;
    this.showPayment = true;
  }

  onSelectType(val?: any): void {
    this.isTabsVisible = false;
    if (val === 'Overview') {
      this.currentStep = 0;
    } else {
      this.selectedType = this.selectedType;
    }

    if (val === undefined) {
      this.selectedType = val;
      this.currentStep = 1;
      this.platterInputValue = this.platterInputValue;
      this.checkPlatterAmount();
    }

    if (val === 'Description') {
      this.currentStep = 2;
    } else {
      this.selectedType = this.selectedType;
    }
  }

  setSipLumpsumAmt(val: any): void {
    this.platterInputValue = val;
    this.checkPlatterAmount();
    this.isTabsVisible = false;
  }

  buyPlatter(type): void {
    this.currentStep = 1;
    this.showStep=true;
    if (this.selectedType != type) {
      this.selectedType = type;
      this.platterInputValue = type === 'SIP' ? this.item.minAmountSip : this.item.minAmount;
    }
    this.checkPlatterAmount();
    this.isInvstButtonVisible = true;
    this.isPayBtnVisible = true;
    this.detailHeading = type === 'SIP' ? 'SIP Investment' : 'One Time Investment';

  }

  makePayment(): void {

    this.mfList.getPaymentInfo();
  }

  back(): void {
    this.showFundInfo = false;
    this.currentStep = 1;
    this.isPayBtnVisible = false;
    this.detailHeading = 'Platter Details';
  }

  orderMf(): void {
    this.mfPayment.orderMF();
  }



  bindChart() {
    this.chartOption = {



      // legend: {

      //   orient: "horizontal",

      //   top:"center",

      //   right:"right"

      // },

      series: [

        {

          color: ["#0F4989", "#F1A00A", "#D7EAFF", "#FFE0A5", "#A2B260"],

          animation: false,

          type: 'pie',

          radius: ['27%', '100%'],

          avoidLabelOverlap: false,

          itemStyle: {



            borderRadius: 5,

            borderColor: '#fff',

                borderWidth: 4

          },



          label: {

            show: false,

            position: 'center'

          },

          emphasis: {

            label: {

              show: false,

              fontSize: '40',

              fontWeight: 'bold'

            }

          },

          labelLine: {

            show: false

          },

          data: [

            { value: this.selectedRule?.platterAllocationEquity, name: 'Domestic Equity' },
            { value: this.selectedRule?.platterAllocationDebt, name: 'Debt' },
            { value: this.selectedRule?.platterAllocationGold, name: 'Gold' },
            { value: this.selectedRule?.platterAllocationCash, name: 'Cash' },
            { value: this.selectedRule?.platterAllocationIntEquity, name: 'International Equity' },

          ]

        }

      ]

    }
  }


  openView(item: Platter) {
    const str = item.name.trim().replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":;*?<>{}!]/g, '').toLowerCase();
    this.plattersService.helperSvc.routeNavigate('fundBox/' + str.trim());
    //this.plattersService.helperSvc.routeNavigate('platter/' + item.id);
  }
  routePlattters(): void {
    this.plattersService.helperSvc.routeNavigate('platter');
  }
  nextReturns(position: string) {

    this.returnType=position;
    switch (this.returnType) {

      case "return3Yr":
        this.returnType = 'return5Yr';
        this.returnTypeText = '5Y Returns';
        break;
      case "return5Yr":
        this.returnType = 'return10Yr';
        this.returnTypeText = '10Y Returns';
        break;
      case "return10Yr":
        this.returnType = 'return1Yr';
        this.returnTypeText = '1Y Returns';
        break;
      case "return1Yr":
        this.returnType = 'return3Yr';
        this.returnTypeText = '3Y Returns';
        break;
    }
    // setTimeout(() => {
    //   this.scrollinto = document.getElementById('top-container')
    //   if( this.scrollinto){
    //     this.scrollinto.scrollIntoView(true);
    //   }
    // }, 50);

  }
}
