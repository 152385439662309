import { Injectable } from '@angular/core';
import { ClientInvestmentSummary } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ClientInvestmentSummariesService extends BaseService<ClientInvestmentSummary> {
    constructor() {
        super('ClientInvestmentSummary');
    }
}
