import { Component, Input, OnInit } from '@angular/core';
import { BucketStrategy } from '../../core/model';

@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.scss'],
})
export class BucketComponent implements OnInit {

  @Input() name = '';
  @Input() bucket: BucketStrategy;
  @Input() property='';
  @Input() duration = '';
  @Input() riskLevel = '';
  @Input() index = 0;


  constructor() { }

  ngOnInit() {

  }
  recalculate(percValue){
    if(percValue && this.property){
      this.bucket[this.property] = parseInt(percValue);
    }
  }

}
