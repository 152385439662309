import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';

import { Role } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class RolesService extends BaseService<Role> {
  constructor() {
    super('Role');
  }
}
