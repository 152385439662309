import { Directive, Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BaseModel } from '../core/model/modelInterface';
import { SubSink } from 'subsink';
import { ConfirmationService, MessageService } from 'primeng';
import { LookupService } from './lookupService';
import { Router } from '@angular/router';
import { BaseService } from './baseService';
import * as moment from 'moment';
import { AppInjector } from '../../app-injector.service';

@Directive()
export abstract class BaseViewComponent<T extends BaseModel> implements OnDestroy {
  hasRouteId = true;
  hasUserFilter = false;
  allowNullItem = false;
  isDialog = false;
  viewId = 0;
  itemForm: FormGroup;

  item: T;
  subsink = new SubSink();
  mindate = new Date();
  navigatePath = '';
  messageService: MessageService;
  constructor(
    public service: BaseService<T>,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService
  ) {
    const injector = AppInjector.getInjector();
    this.messageService = injector.get(MessageService);
  }

  bindDropDown() {

  }

  onSelectDate(date: moment.MomentInput, controlName: string) {
    this.itemForm.get(controlName).setValue((moment(date)).format());
  }

  selectMultiData(data: { value: { id: any; }[]; }, controlName: string) {
    this.itemForm.get(controlName).setValue(data.value.map((i: { id: any; }) => i.id).join(','));
  }

  onDataLoad(item: T){

  }

  getData(id: number) {
    this.subsink.sink = this.service.get(id, this.hasUserFilter).subscribe(item => {
      if (item) {
        this.item = item;
        this.onDataLoad(item);
      } else {
        if (!this.allowNullItem) {
          this.navigateHome();
        }
      }
    });
  }


  delete() {

    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.subsink.sink = this.service.delete(this.item.id, this.hasUserFilter).subscribe(() => {
          //this.messageService.add({ key: 'crud', severity: 'success', summary: 'Success', detail: 'Removed successfully' });
          this.navigateHome();
        });
      },
      reject: () => {

      }
    });
  }


  downloadPdf() {
    //console.log('search', this.viewId);
    this.service.downloadPdf('' + this.viewId).subscribe((fileData) => {
      const blob = new Blob([fileData as any]);
      this.saveExportFile(blob, this.item.name + '.pdf');
    });

  }

  saveExportFile(blob: Blob, fileName: string) {
    if (window.navigator.msSaveOrOpenBlob) { // For IE:
      navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }

  navigateHome() {
    this.service.helperSvc.routeNavigate(this.navigatePath);
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  edit() {
    if (this.hasRouteId) {
      this.service.helperSvc.routeNavigate(this.navigatePath + '/edit/'+ this.item.id);
    } else {
      this.service.helperSvc.routeNavigate(this.navigatePath + '/edit');
    }
  }

}
