import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WatchList } from '../../../core/model';
import { WatchListsService as service } from '../../../core/service/watchLists.service';

@Injectable({
    providedIn: 'root'
})

export class WatchListsService extends service {
    getWatchlist() {
        return this.http.post(this.apiUrl + 'GetWatchlist', null)
        .pipe(
            map(data => {
                
              return data;
            }),
            catchError(this.handleError)
          );
      }

      deleteWatchlist(watchlist) {
        return this.http.post(this.apiUrl + 'Delete', watchlist)
        .pipe(
            switchMap(usr => {
                // update local store with updated item data
                // not required of course unless the store cache is needed
                // (it is for the item list component in this example)
                return this.fetchData(true);
              }),
            catchError(this.handleError)
          );
      }
}
