import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-chart-indicator',
  templateUrl: './chart-indicator.component.html',
  styleUrls: ['./chart-indicator.component.scss'],
})
export class ChartIndicatorComponent implements OnInit {
  
  @Input() isHorizontal: boolean = false;
  @Input() isVertical: boolean = false;
  @Input() bgColor1: string = '#f0a008';
  @Input() bgColor2: string = '#0F4988';
  @Input() bgColor3: string = '#C8C8C8';
  @Input() text1: string;
  @Input() text2: string;
  @Input() text3: string;
  @Input() customClass: string;
  

  constructor() { }

  ngOnInit() {}

}
