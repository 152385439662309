import { BaseModel } from '../core/model/modelInterface';
import { OnInit, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef, Input, Component, Type, Injectable, Directive, HostListener, ViewChildren } from '@angular/core';
import { Table } from 'primeng/table';
import { DialogService, ConfirmationService, MessageService } from 'primeng';
import { Router } from '@angular/router';
import { BaseService } from './baseService';
import { HelperService } from './helperService';
import { LookupService } from './lookupService';
import { AppInjector } from '../../app-injector.service';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Directive()
export abstract class ListComponent<T extends BaseModel> implements OnInit, AfterViewInit {

  //#Main begin
  title = '';
  hasUserFilter = false;
  typeName: string;
  searchKey = '';
  searchForm: FormGroup;
  itemSearch: T;
  modelSearch: T;
  tableData$: Observable<T[]>;
  @Output() rowsSelection = new EventEmitter();
  @Input() selectedColumns: string[] = []; // ['id', 'name']; //
  @Input() actions: string[] = ['add', 'edit', 'view', 'delete', 'pop_add', 'pop_view', 'export', 'pop_edit']; // []
  @Input() search: any = null; // { id: 1 }; //
  @Input() orderColumns: null; // string[] = ['name desc'];

  //#region Main
  createForm() {
    // TODO : Work on Search form.
    this.itemSearch = {} as T;
    this.modelSearch = { ...this.itemSearch };
  this.bindDropDown();
}
//#endregion Main
  //#Main end

  private _listData: T[] = [];
  selectedRows: T[] = [];
  public cols: any[];
  currentRoute = '';


  filter = '';
  showTable = false;
  messageService: MessageService;

  filteredRows: T[] = [];
  lookupService: LookupService;
  document: Document;
  @ViewChild('filter', { static: false }) filterInput: ElementRef;
  @ViewChild('dataTable', { static: false }) private dataTable: Table;

  @Output() refresh = new EventEmitter();
  @Output() dataLoadComplete = new EventEmitter();
  @Output() gridExportServer = new EventEmitter();
  @Output() gridRowsSelection = new EventEmitter();
  @Input() showSelect = 'multiple';

  @Input() get listData(): T[] {
    return this._listData;
  }
  set listData(value: T[]) {
    if (value) {
      this.filteredRows = this._listData = value;
      this.onDataLoadComplete();
    }
  }


  constructor(public dialogService: DialogService,
    public router: Router, public confirmationService: ConfirmationService,
    public service: BaseService<T>, public helperService: HelperService) {
    const injector = AppInjector.getInjector();
    this.messageService = injector.get(MessageService);
    this.lookupService = injector.get(LookupService);
  }

  ngAfterViewInit() {
    //TODO : Sandeep scrollToBottom

  }

  ionViewDidEnter(): void {

    //#region Main
    console.log('ionViewWillEnter Base Main called');
    this.searchKey = 'Search_' + this.typeName[0].toUpperCase() + this.typeName.substring(1) + 'List';
    this.createForm();

    this.getData(this.search);
    this.onInitComplete();
    //#endregion end


  }
  logScrolling(event) {
    console.log('logScrolling', event);
  }

  setSearchData() {

    console.log('ionViewWillEnter baseListComponent');
    const search = this.helperService.getSearchHistory(this.searchKey);
    if (search) {
      this.itemSearch = { ...search };
      setTimeout(() => {
        this.showTable = true;
        this.filterInput.nativeElement.value = search.filter;
        this.dataTable.filterGlobal(search.filter, 'contains');
        this.dataTable.sortField = search.sortField;
        this.dataTable.sortOrder = search.sortOrder;
        setTimeout(() => {
          this.dataTable.first = search.first;
        }, 300);
        setTimeout(() => {
         //TODO : Sandeep scrollToBottom
        }, 5000);
      }, 20);
    }



  }

  ngOnInit() {
    console.log('actions', this.actions);
    if (!this.actions.length) {
      this.actions = ['add', 'edit', 'delete', 'view', 'pop_add', 'pop_view', 'pop_edit', 'export'];
    }
    this.ionViewDidEnter();
    this.onInitComplete();

  }


  onDataLoadComplete() {

  }

  onSelectionChange(selection: any[]) {
    this.gridRowsSelection.emit(selection);
  }

  abstract getComponentForDialog(type: string);
  abstract getDialogHeaderText(item: T, type: string);

  showPopup(item: T, type: string) {
    const headerText = this.getDialogHeaderText(item, type); // idEdit > 0 ? this.editHeaderText : this.newHeaderText;
    const ref = this.dialogService.open(this.getComponentForDialog(type), {
      data: {
        id: item ? item.id : 0
      },
      header: headerText,
      width: '90%',
      height: 'auto'
    });

    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.refresh.emit('refresh');
      }
    });
  }

  openItem(item: any, action: string) {
    this.helperService.setSearchHistory(this.searchKey, this.getSearchInfo());
    //TODO : Sandeep scrollToBottom
    if (action.startsWith('pop_')) {
      this.showPopup(item, action);
    } else {
      switch (action) {
        case 'edit':
          this.helperService.routeNavigate(this.currentRoute + '/edit/' + item.id)
          break;
        case 'add':
          this.helperService.routeNavigate(this.currentRoute + '/edit/' + 0)
          break;
        case 'view':
          this.helperService.routeNavigate(this.currentRoute + '/' + item.id)
          break;
      }
    }
  }


  getSearchInfo() {


    return {
      sortField: this.dataTable.sortField,
      sortOrder: this.dataTable.sortOrder,
      first: this.dataTable.first,
      filter: this.filterInput.nativeElement.value,
      top: 0
    };
  }

  onDelete(deleteData: T) {
    //debugger;
    console.log('delete call');
    this.confirmationService.confirm({
      //message: 'Are you sure want to delete?',
      //header: 'Delete Confirmation',
      //icon: 'pi pi-info-circle',
      accept: () => {
        this.service.delete(deleteData.id).subscribe(() => {
          // this.messageService.add({ key: 'crudToast', severity: 'success', summary: 'Success', detail: 'Removed successfully' });
          this.refresh.emit('refresh');
        });
      },
      reject: () => {
        // this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  exportGridServer() {
    this.gridExportServer.emit('export-grid');
  }


  //#region Main begin
  onInitComplete() {

  }
  bindDropDown() {

  }


  selectDropdown(data: any, controlName: string) {
    this.searchForm.get(controlName).setValue(data.value.id);
    if (this.searchForm.get(controlName + 'Label')) {
      this.searchForm.get(controlName + 'Label').setValue(data.value.name);
    }

  }

  selectDropdownEnum(data: any, controlName: string) {
    this.searchForm.get(controlName).setValue(data.value.value);
    if (this.searchForm.get(controlName + 'Label')) {
      this.searchForm.get(controlName + 'Label').setValue(data.value.name);
    }
  }

  onSelectDate(date: moment.MomentInput, controlName: string) {
    this.searchForm.get(controlName).setValue((moment(date)).format());
  }

  selectMultiData(data: { value: { id: any; }[]; }, controlName: string) {
    this.searchForm.get(controlName).setValue(data.value.map((i: { id: any; }) => i.id).join(','));
  }

  getData(search) {
    if (search === null) {
      this.service.getAll(this.hasUserFilter).subscribe(data => {
        this.listData = data;
        this.setSearchData();
      });
    } else {
      this.service.searchData(search, this.orderColumns, null, this.hasUserFilter).subscribe(data => {
        this.listData = data;
        this.setSearchData();
      });
    }
  }

  submitSearch() {
    if (this.searchForm.valid) {
      const searchFormValue = { ...this.search, ...this.searchForm.value } as T;

      console.log('searchFormValue', searchFormValue);
      this.search = searchFormValue;
      this.helperService.setSearchHistory(this.searchKey, searchFormValue);
      this.getData(searchFormValue);

      // this.tableData$ = this.service.searchData(searchFormValue, this.orderColumns);
    }
  }

  reload(event: any) {
    this.getData(this.search);
  }

  onRowsSelection(selection: any) {
    this.rowsSelection.emit(selection);
  }

  downloadFile(event: any) {
    console.log('search', this.search);
    this.service.exportExcel(this.search, this.orderColumns).subscribe((fileData) => {
      const blob = new Blob([fileData as any]);
      this.saveExportFile(blob, this.typeName + '.xlsx');
    });

  }

  saveExportFile(blob: Blob, fileName: string) {
    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) { // For IE:
      nav.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }

  //#endregion Main end

}
