import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subscribeData: any = {};
  subscriptionResponseData: any;
  isChecked = true;
  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.subscribeData.isAndroid = true;
  }
  subscribe(form: any) {
    // console.log('subscribeData', this.subscribeData)
    let data = { ...this.subscribeData };
    data.Type = 'EarlyAccess';
    if(this.subscribeData.isAndroid ) {
      // this.subscribeData.isAndroid = 'Android'
     data.mobileOs = 'Android'

    }
    else if(this.subscribeData.isIos) {
      // this.subscribeData.isIos = 'iOS'
      data.mobileOs = 'iOS'
    }
    if(this.subscribeData.isIos && this.subscribeData.isAndroid) {
      data.mobileOs = 'Android' + ';' + 'iOS'
    }
    data.IsSubscribed = true;

    this.http.post<any[]>(environment.apiUrlBase +'Subscriber/Subscribe', data)
      .subscribe(data => {
        console.log('detail', data);
        this.subscriptionResponseData = data;
        this.subscribeData = {};
        document.getElementById('subscribe')?.click();
        form.reset();
        setTimeout(() => {
          const alertList = document.querySelectorAll('.alert');

          alertList.forEach((val, indx) => {
            // val.classList.remove('alert');
            val.classList.add('d-none');
          });

        }, 3000);
      });
  }
  goto(path: string): void {
    this.router.navigateByUrl(path);
  }
}
