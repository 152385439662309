import { Injectable } from '@angular/core';
import { EmailSubscriberAudit } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class EmailSubscriberAuditsService extends BaseService<EmailSubscriberAudit> {
    constructor() {
        super('EmailSubscriberAudit');
    }
}
