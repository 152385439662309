import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { FeedMorStarApi } from '../../../core/model';
import { FeedMorStarApisService as service } from '../../user/feedMorStarApis/feedMorStarApis.service';


@Injectable({
    providedIn: 'root'
})

export class FeedMorStarApisService extends service {

  searchMF(search) {
    return this.http.post<FeedMorStarApi[]>(this.apiUrl + 'SearchMF', {condition : search})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  searchFund(search: any, order: string[], condition: any) {
    return this.http.post<FeedMorStarApi[]>(this.apiUrl + 'SearchFund', {form: search, condition: condition, orderColumns: order})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  getNavHistory(search) {
    return this.http.post<FeedMorStarApi[]>(this.apiUrl + 'NavHistory', {condition : search})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }
}
