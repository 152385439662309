import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { User } from '../../../../core/model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../users.service';
import { ConfirmationService, DynamicDialogRef, DynamicDialogConfig } from 'primeng';
import { LookupService } from '../../../../shared/lookupService';
import { BaseEditComponent } from '../../../../shared/baseEditComponent';
import { UsersBaseEditComponent as CustomComponent } from '../../../user/users/users-edit/users-edit.component';
import { HelperService } from '../../../../shared/helperService';


@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss'],
  providers: [ConfirmationService],

})
export class UsersEditComponent extends CustomComponent implements OnInit {

  showConfirmation: boolean;
  loading = false;
  visiable = false;
  displayVerifyEmail: boolean = false;
  counter: number = 5;
  interval;
  otpModel: any = {}
  showCounter: boolean = false;
  show: boolean = false;
  verifyValue: any;
  otpStatus = '';

  constructor(private route: ActivatedRoute,
    public usersService: UsersService,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService,
    public helperService: HelperService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    super(usersService, router, formBuilder, confirmationService, lookupService);
  }

  ngOnInit() {


    // const id = this.usersService.helperSvc.loggedInUser.id;
    const id = +this.config.data.id;
    this.createForm(id, true);
    //const id = this.usersService.helperSvc.loggedInUser.id;

    // this.createForm(id);

  }
  confirm() {

    this.loading = true;
    this.visiable = true;
    this.formSubmit();
    this.loading = false;
  }

  formSubmit() {
    this.itemForm.form.markAllAsTouched();
    this.model.name = this.model.firstName + ' ' + this.model.lastName;

    if (this.itemForm.valid) {
      this.submit();
    }
  }

  navigateHome() {
    this.usersService.helperSvc.setUserInfo(this.model);
    this.showConfirmation = true;
    this.confirmationService.confirm({
      message: '',
      accept: () => {
        //Actual logic to perform a confirmation
        //this.showConfirmation = false;
        // if(this.helperService.redirectToOnboarding){
        //     this.helperService.redirectToOnboarding = false;
        //     this.usersService.helperSvc.routeNavigate('bseUserRegistrations');
        //  } else {
        //     this.backToProfile();
        //  }
        setTimeout(() => {
          this.ref.close('refresh');
        }, 100);
    }
    });

  }

  confirmation() {

    this.confirmationService.confirm({
      message: '',
      accept: () => {

      },
      reject: () => {
        this.loading = false;
        this.displayVerifyEmail = false;
        clearTimeout(this.interval);
        this.counter = 180;
       },
    });
  }

  backToProfile() {

    this.ref.close('refresh');
    // this.usersService.helperSvc.setRouteData({ "page": "profile" });
    // this.usersService.helperSvc.routeNavigate('profile');
  }
  backToEditUserProfile() {
    // this.usersService.helperSvc.setRouteData({ "page": "userEdit" });
    // this.usersService.helperSvc.routeNavigate('userEdit');
    this.loading = false;
    this.displayVerifyEmail = false;
    clearTimeout(this.interval);
    this.counter = 180;
  }


  verifyEmail() {
    if (this.model.emailId) {
      this.verifyValue = "email"
      this.usersService.generateOtp(null, this.model.emailId).subscribe(data => {

        this.displayVerifyEmail = true;
        this.confirmation();
        this.loading = true;
        this.showCounter = true;
        this.counter = 180;
        this.runTimer();
      })
    }
  }
  verifyButton() {
    this.otpStatus = '';
    if (this.model.emailId) {
      const otp = this.otpModel.otp1;
      // const otp = this.otpModel.otp1.toString() + this.otpModel.otp2.toString()
      //   + this.otpModel.otp3.toString() + this.otpModel.otp4.toString();
      if (otp) {
        this.usersService.verifyOtp(null, this.model.emailId, otp).subscribe(data => {

          this.otpStatus = data.toString();
          if (data == "success") {
            this.displayVerifyEmail = false;
            this.loading = true;
            this.model.emailVerified = true;
            this.submit();
          }

        })

      }
    }


  }
  verifyMobile() {
    // console.log("mobile: ", this.model.emailVerified);
    // return;
    if (this.model.mobile) {
      this.verifyValue = "mobile";
      this.usersService.generateOtp(this.model.mobile, null).subscribe(data => {

        this.displayVerifyEmail = true;
        this.confirmation();
        this.loading = true;
        this.showCounter = true;
        this.counter = 180;
        this.runTimer();

      })
    }

  }
  mobileVerifyButton() {
    this.otpStatus = '';
    if (this.model.mobile) {
      const otp = this.otpModel.otp1;
      // const otp = this.otpModel.otp1.toString() + this.otpModel.otp2.toString()
      //   + this.otpModel.otp3.toString() + this.otpModel.otp4.toString();
      if (otp) {
        this.usersService.verifyOtp(this.model.mobile, null, otp).subscribe(data => {

          this.otpStatus = data.toString();
          if (data == "success") {
            this.displayVerifyEmail = false;
            this.model.mobileVerified = true;
            this.submit();
          }
        })
      }
    }
  }
  move(event: any, nextInput: any) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;
    if (length >= maxLength) {
      nextInput.focus();
    }
  }
  runTimer() {
    this.interval = setTimeout(() => {
      if (this.counter >= 0) {
        this.counter = this.counter - 1;
        this.runTimer();
      }
    }, 1000);
  }
  onKeyUp(event, focusId) { // appending the updated value to the variable
    if (event.target.value == "") {
      const field = document.getElementById(focusId);

      if (field) {
        field.focus();
      }
    }
  }

}

@Component({
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditDialogComponent extends CustomComponent implements OnInit {
  showConfirmation: boolean;
  loading = false;
  visiable = false;
  displayVerifyEmail: boolean = false;
  counter: number = 5;
  interval;
  otpModel: any = {}
  showCounter: boolean = false;
  show: boolean = false;
  verifyValue: any;
  otpStatus = '';
  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
    public usersService: UsersService,
    public router: Router,
    public formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public lookupService: LookupService,
    public helperService: HelperService
  ) {
    super(usersService, router, formBuilder, confirmationService, lookupService);
  }
  ngOnInit() {

    const id = +this.config.data.id;
    this.createForm(id, true);
    //const id = this.usersService.helperSvc.loggedInUser.id;
    //this.createForm(id);


  }
  confirm() {

    this.loading = true;
    this.visiable = true;
    this.formSubmit();
    this.loading = false;
  }

  formSubmit() {
    this.itemForm.form.markAllAsTouched();
    this.model.name = this.model.firstName + ' ' + this.model.lastName;

    if (this.itemForm.valid) {
      this.submit();
    }
  }

  navigateHome() {
    this.usersService.helperSvc.setUserInfo(this.model);
    this.showConfirmation = true;
    this.confirmationService.confirm({
      message: '',
      accept: () => {
        //Actual logic to perform a confirmation
        //this.showConfirmation = false;
        // if(this.helperService.redirectToOnboarding){
        //     this.helperService.redirectToOnboarding = false;
        //     this.usersService.helperSvc.routeNavigate('bseUserRegistrations');
        //  } else {
        //     this.backToProfile();
        //  }
        setTimeout(() => {
          this.ref.close('refresh');
        }, 100);
    }
    });

  }

  confirmation() {

    this.confirmationService.confirm({
      message: '',
      accept: () => {
        // this.acceptStopSips=true;this.acceptStopSip=true;this.displayStopSIPConfirmBox = false;
        //alert('Working');
      },
      reject: () => {
        this.loading = false;
        this.displayVerifyEmail = false;
        clearTimeout(this.interval);
        this.counter = 180;
      },
    });
  }

  backToProfile() {
    // this.usersService.helperSvc.setRouteData({ "page": "profile" });
    // this.usersService.helperSvc.routeNavigate('profile');
    this.ref.close('refresh');
  }
  backToEditUserProfile() {
    // this.usersService.helperSvc.setRouteData({ "page": "userEdit" });
    // this.usersService.helperSvc.routeNavigate('userEdit');
    this.loading = false;
    this.displayVerifyEmail = false;
    clearTimeout(this.interval);
    this.counter = 180;
  }


  verifyEmail() {
    if (this.model.emailId) {
      this.verifyValue = "email"
      this.usersService.generateOtp(null, this.model.emailId).subscribe(data => {

        this.displayVerifyEmail = true;
        this.confirmation();
        this.loading = true;
        this.showCounter = true;
        this.counter = 180;
        this.runTimer();
      })
    }
  }
  verifyButton() {
    this.otpStatus = '';
    if (this.model.emailId) {
      const otp = this.otpModel.otp1;
      // const otp = this.otpModel.otp1.toString() + this.otpModel.otp2.toString()
      //     + this.otpModel.otp3.toString() + this.otpModel.otp4.toString();
      if (otp) {
        this.usersService.verifyOtp(null, this.model.emailId, otp).subscribe(data => {

          this.otpStatus = data.toString();
          if (data == "success") {
            this.displayVerifyEmail = false;
            this.loading = true;
            this.model.emailVerified = true;
            this.submit();
          }

        })

      }
    }


  }
  verifyMobile() {
    // console.log("mobile: ", this.model.emailVerified);
    // return;
    if (this.model.mobile) {
      this.verifyValue = "mobile";
      this.usersService.generateOtp(this.model.mobile, null).subscribe(data => {

        this.displayVerifyEmail = true;
        this.confirmation();
        this.loading = true;
        this.showCounter = true;
        this.counter = 180;
        this.runTimer();

      })
    }

  }
  mobileVerifyButton() {
    this.otpStatus = '';
    if (this.model.mobile) {
      const otp = this.otpModel.otp1;
      // const otp = this.otpModel.otp1.toString() + this.otpModel.otp2.toString()
      //     + this.otpModel.otp3.toString() + this.otpModel.otp4.toString();
      if (otp) {
        this.usersService.verifyOtp(this.model.mobile, null, otp).subscribe(data => {

          this.otpStatus = data.toString();
          if (data == "success") {
            this.displayVerifyEmail = false;
            this.model.mobileVerified = true;
            this.submit();
          }
        })
      }
    }
  }
  move(event: any, nextInput: any) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;
    if (length >= maxLength) {
      nextInput.focus();
    }
  }
  runTimer() {
    this.interval = setTimeout(() => {
      if (this.counter >= 0) {
        this.counter = this.counter - 1;
        this.runTimer();
      }
    }, 1000);
  }
  onKeyUp(event, focusId) { // appending the updated value to the variable
    if (event.target.value == "") {
      const field = document.getElementById(focusId);

      if (field) {
        field.focus();
      }
    }
  }

}


