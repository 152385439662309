import { Injectable } from '@angular/core';
import { Misc } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class MiscsService extends BaseService<Misc> {
    constructor() {
        super('Misc');
    }
}
