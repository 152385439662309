import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet, MultiDataSet } from 'ng2-charts';

@Component({
  selector: 'app-chart-info',
  templateUrl: './chart-info.component.html',
  styleUrls: ['./chart-info.component.scss'],
})
export class ChartInfoComponent implements OnInit {
  @Input() type: ChartType = 'pie';
  @Input() labels: Label[] = [];
  @Input() data: SingleDataSet = [];
  @Input() pieChartData1: MultiDataSet = [];
  @Input() legend = false;
  @Input() plugins = [];
  @Output() selectedItem  = new EventEmitter();
  public options: ChartOptions = {
    responsive: true,
    // rotation: 1 * Math.PI,
    // circumference: 1 * Math.PI
  };


  public value = this.data[0];
  public canvasWidth = 400;
  public needleValue = 65;
  public centralLabel = '';
  public name = '';
  public bottomLabel = '';
  public optionsGauge = {
    hasNeedle: true,
    needleColor: 'orange',
    needleUpdateSpeed: 1000,
    rangeLabel: ['0', '100'],
    needleStartValue: 50,
    arcColors: ['rgb(211, 52, 52)', 'rgb(145, 40, 110)', 'rgb(47, 145, 40)', 'rgb(71, 130, 209)', 'rgb(71, 209, 89)'],
    arcDelimiters: [10, 30, 60, 90],
    arcPadding: 6,
    arcPaddingColor: 'white',
    arcLabels: ['10', '30', '60', '90'],
    arcLabelFontSize: false,
  };
  constructor() { }

  ngOnInit() { }

  onItemSelect(data: any) {
    this.selectedItem.emit(data);
  }

}
