import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../../../shared/baseService';
import { Mandate } from '../../../core/model';
import { MandatesService as service } from '../../../core/service/mandates.service';


@Injectable({
    providedIn: 'root'
})

export class MandatesService extends service {

    insert(item: Mandate, userFilter: boolean = false) {

        return this.http.post<Mandate>(this.apiUrl + 'Add', item)
        .pipe(
          map(dataList => {
            this.fetchData(userFilter);
            return dataList;
          }),
          catchError(this.handleError)
        );

      }

      mandateAuthUrl(item: Mandate) {

        return this.http.post<Mandate>(this.apiUrl + 'BseMandateAuthUrl', item)
        .pipe(
          map(dataList => {
            return dataList;
          }),
          catchError(this.handleError)
        );

      }

      uploadNachFile( nachFile: string, mandateCode: string, clientCode: string) {
        const request = {  nachFile: nachFile, mandateCode: mandateCode,clientCode:clientCode}
        return this.http.post<any>(this.apiUrl + 'UploadNachFile', request)
          .pipe(
            map(response => {
              return response;
            }),
            catchError(this.handleError)
          );
      }

      sendNachMandateFile(mandateId: string) {
        const request = { mandateId: mandateId}
        return this.http.post<any>(this.apiUrl + 'SendNachMandateFile', request)
          .pipe(
            map(response => {
              //console.log('payment',response);
              return response;
            }),
            catchError(this.handleError)
          );
      }
}
