import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bank-purchase',
  templateUrl: './bank-purchase.component.html',
  styleUrls: ['./bank-purchase.component.scss'],
})
export class BankPurchaseComponent implements OnInit {
  @Input() bankPurchase: any[] = [];
  @Input() protectText: '';
  @Input() linkText = '';
  constructor() { }

  ngOnInit() {}

}
