import { Injectable } from '@angular/core';
import { EmailBulkAudit } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class EmailBulkAuditsService extends BaseService<EmailBulkAudit> {
    constructor() {
        super('EmailBulkAudit');
    }
}
