import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet, MultiDataSet } from 'ng2-charts';
import { Platter } from '../../core/model';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-platter-home-info',
  templateUrl: './platter-home-info.component.html',
  styleUrls: ['./platter-home-info.component.scss'],
})
export class PlatterHomeInfoComponent implements OnInit {
  @Input() platter: Platter;
  platterDetail: any;


  constructor(private  helperService:HelperService) {  }

  ngOnInit() {
    if(this.platter && this.platter.ruleJson){
      var platterList = JSON.parse(this.platter.ruleJson);
      this.platterDetail = platterList[0];
    }
    //console.log('Platter Result',this.platter);
   }

   openView(item: Platter) {
    const str = item.name.trim().replace(/ /g, '-').replace(/[&\/\\#,+()$~%.'":;*?<>{}!]/g, '').toLowerCase();
    this.helperService.routeNavigate('platters/' + str.trim());
   // this.helperService.routeNavigate('platters/' + item.id);
  }


}
