import { Injectable } from '@angular/core';
import { RazorPay } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class RazorPaysService extends BaseService<RazorPay> {
    constructor() {
        super('RazorPay');
    }
}
