import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { UsersEditComponent, UsersEditDialogComponent } from './users-edit/users-edit.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [CommonModule, SharedModule, UsersRoutingModule,
    TableModule, DynamicDialogModule, ConfirmDialogModule, FormsModule],
  declarations: [UsersEditComponent, UsersEditDialogComponent],
  entryComponents: [UsersEditDialogComponent]
})
export class UsersModule { }
