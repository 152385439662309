import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { HelperService } from '../../shared/helperService';


@Component({
  selector: 'app-wb-button',
  templateUrl: './wb-button.component.html',
  styleUrls: ['./wb-button.component.scss'],
})
export class WbButtonComponent implements OnInit {
  @ViewChild('owlCar', {static: false}) carousel: CarouselComponent;
  pageType = '';
  index = 0;
  isHeadingVisible: boolean = true;
  headingText = 'Health Insurance';

  wealthBag: any[];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    margin: 20,
    items: 3,
    nav: true,
    //startPosition:4,
    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
  }
  loadCarousel = false;
  constructor(private helperSvc: HelperService, route: ActivatedRoute) {
    route.url.subscribe(() => {
      //console.log('ActivatedRoute', route.snapshot.firstChild);
      //console.log('ActivatedRoute type', route.snapshot.firstChild.routeConfig.path);
      var type = route.snapshot.firstChild.routeConfig.path;
      this.pageType = type;
      if(type == 'liability' || type == 'accident' || type == 'vehicle' ){
        this.index = 1;
      }
     });
   }

  ngOnInit() {
    // this.loadCarousel = false;
    this.loadWealthBag();
    //console.log(this.wealthBag);


  }

  getOwlData(data: SlidesOutputData) {
    //console.log('data',data);
    this.loadCarousel = false;
    // data.startPosition = 1;
    // //console.log(data);
    setTimeout(() => {
      if(this.index > 0){
        this.carousel.to('4');
      }
      setTimeout(() => {
        this.customOptions.startPosition = this.index;
        if(this.index > 3)
          this.carousel.to('' + this.index);
        // this.loadCarousel = true;
      }, 200);

      // this.loadCarousel = true;
    }, 200);

  }

  loadWealthBag(): void {
    this.wealthBag = [
      {
        id: 1,
        heading: 'Health Insurance',
        imgUrl: 'health.png',
        imgText: 'health',
        text:'Health Insurance',
        url: 'wbHealths'
      },
      {
        id: 2,
        heading: 'Life Insurance',
        imgUrl: 'insurance.png',
        imgText: 'insurance',
        text:'Life Insurance',
        url: 'wbInsurances'
      },
      {
        id: 3,
        heading: 'Home Insurance',
        imgUrl: 'home.png',
        imgText: 'home',
        text:'Home Insurance',
        url: 'wbHomes'
      },
      {
        id: 4,
        heading: 'Liability',
        imgUrl: 'liability.png',
        imgText: 'liability',
        text:'liability',
        url: 'wbLiabilities'
      },
      {
        id: 5,
        heading: 'Accidental Insurance',
        imgUrl: 'accident.png',
        imgText: 'accident',
        text:'Accident Insurance',
        url: 'wbPersonalAccidents'
      },
      {
        id: 6,
        heading: 'Vehicle Insurance',
        imgUrl: 'vehicle.png',
        imgText: 'vehicle',
        text:'Vehicle Insurance',
        url: 'wbVehicles'
      }
    ];
  }

  routeCarousel(type: any): void {
    this.pageType = type.text;
    this.helperSvc.routeNavigate(type.url);
    this.headingText = type.heading;
  }



}
