import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedMorStarApi } from '../../core/model';
import { FeedMorStarApiService } from '../../pages/custom/decide-mutual-fund/feedMorStarApis.service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-home-mf-search',
  templateUrl: './home-mf-search.component.html',
  styleUrls: ['./home-mf-search.component.scss'],
})
export class HomeMfSearchComponent implements OnInit {
  searchMfValue='';
  searchList: FeedMorStarApi[] = [];

  loadComplete = false;
  isTooltipVisible = true;


  constructor(private helperService: HelperService,
    private feedMorStarApiService: FeedMorStarApiService,
    private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.searchMfValue = '';
      this.searchList = [];
    });
  }

  navigate(item) {
    this.helperService.routeNavigate('search/'+item.isin);
    this.searchMfValue = '';
    this.searchList=[];
    this.loadComplete = false;
  }

  hideTooltip():void {
    this.isTooltipVisible = false;
  }

  searchMf(){
    const search = {};
    search['Name'] = this.searchMfValue;
    search['Category'] =  null;
    search['Labels'] = null;

    this.feedMorStarApiService.searchMF(search).subscribe((data) => {
      this.searchList = data;
      this.loadComplete = true;
    });
  }


}
