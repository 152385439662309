export * from './logins.service';
export * from './roles.service';
export * from './dependents.service';
export * from './enumDetails.service';
// // export * from '../../pages/admin/faqs/faqs.service';
// export * from '../../pages/admin/insurances/insurances.service';
// export * from '../../pages/admin/financeProfiles/financeProfiles.service';
// export * from '../../pages/admin/foundations/foundations.service';
// // export * from '../../pages/admin/users/users.service';
// export * from '../../pages/admin/personalDetails/personalDetails.service';
export * from '../../pages/admin/bankDetails/bankDetails.service';
// export * from '../../pages/admin/communicationDetails/communicationDetails.service';
// // export * from '../../pages/admin/bseRegistrations/bseRegistrations.service';
// export * from '../../pages/admin/bseMFDetails/bseMFDetails.service';
// export * from '../../pages/admin/mfSchemes/mfSchemes.service';
// // export * from '../../pages/admin/mfOrders/mfOrders.service';
export * from './wbHealths.service';
export * from './wbInsurances.service';
export * from './documents.service';
export * from './wbHomes.service';
export * from './wbLiabilities.service';
export * from './wbLifeInsurances.service';
export * from './wbPersonalAccidents.service';
export * from './wbVehicles.service';
export * from './edExpenses.service';
export * from './edIncomes.service';
export * from './feedFolioDetails.service';
export * from './feedSipDetails.service';
export * from './feedTransactions.service';
export * from './feedUploads.service';
export * from './configurations.service';
export * from './clientInvestments.service';
export * from './clientInvestmentSummaries.service';
export * from './feedNavs.service';
export * from './feedNavHistories.service';
export * from './feedMorStarApis.service';
export * from './feedMfDetailMasters.service';
export * from './mandates.service';
export * from './goalCalculators.service';
export * from './bucketStrategies.service';
export * from './platters.service';
export * from './bseFatcas.service';
export * from './miscs.service';
export * from './blogs.service';
export * from './globalNotifications.service';
export * from './bseRegistrations.service';
export * from './mfOrders.service';
export * from './sipDetails.service';
export * from './users.service';
export * from './kycs.service';
export * from './contactRequests.service';
export * from './faqs.service';
export * from './requestContacts.service';
export * from './gravytSeos.service';
export * from './magzines.service';
export * from './audits.service';
export * from './subscribers.service';
export * from './distributorEnrollments.service';
export * from './clientInvestmentPlatters.service';
export * from './clientInvestmentPortfolios.service';
export * from './commonDatas.service';
export * from './distributors.service';
export * from './gravytSiteMaps.service';
export * from './mfSchemes.service';
export * from './bseMFDetails.service';
export * from './communicationDetails.service';
export * from './otps.service';
export * from './personalDetails.service';
export * from './foundations.service';
export * from './financeProfiles.service';
export * from './insurances.service';
export * from './clients.service';
export * from './watchLists.service';
export * from './emailSubscriberAudits.service';
export * from './googleDriveBackups.service';
export * from './unsubscribers.service';
export * from './fundFilterDatas.service';
export * from './emailBulkAudits.service';
export * from './instantEmails.service';
export * from './pushNotificationMappers.service';
export * from './pushNotificationMessages.service';
export * from './razorPays.service';
export * from './distributorRiskProfiles.service';
export * from './distributorFees.service';
export * from './influencers.service';
export * from './influencerPromoCodes.service';
export * from './portfolioUserInfoes.service';
export * from './portfolioUserInvestmentInfoes.service';
export * from './allDocumentUploads.service';
// <<export-service>>
