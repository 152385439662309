import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { FeedMorStarApi } from '../../../core/model';
import { FeedMorStarApisService as service } from '../../../core/service/feedMorStarApis.service';

@Injectable({
    providedIn: 'root'
})

export class FeedMorStarApisService extends service {
    
    
}
