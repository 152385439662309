import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, HostListener, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../shared/helperService';


@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit, AfterViewInit {

  @Input() value: number = 0;
  @Input() meterText;
  @Input() subTitle;
  @Input() legends : any[] = [];
  @Input() isProfileBtn: false;
  @Input() isRiskBtn: false;
  @Input() gaugeSize: number = 200;
  @Input() fgColor = '#dc8802';
  @Input() bgColor = 'rgba(0, 0, 0, 0.1)';
  @Input() imgSrc = ''
  @Input() foundation= true;
  @Input() riskFund= false;
  @Output() goBack = new EventEmitter();

  gaugeType = "full";
  gaugeAppendText = "%";

  public canvasWidth = 400;
  public needleValue = 65;
  public centralLabel = '';
  public name = '';
  public bottomLabel = '';
  meterAnimation:any = null;
  meterAnimationByArch:any = null;
  public options = {
    hasNeedle: true,
    needleColor: 'orange',
    needleUpdateSpeed: 1000,
    rangeLabel: ['0', '100'],
    needleStartValue: 50,
    arcColors: ['rgb(211, 52, 52)', 'rgb(145, 40, 110)', 'rgb(47, 145, 40)', 'rgb(71, 130, 209)', 'rgb(71, 209, 89)'],
    arcDelimiters: [10, 30, 60, 90],
    arcPadding: 6,
    arcPaddingColor: 'white',
    arcLabels: ['10', '30', '60', '90'],
    arcLabelFontSize: false,
  };

  constructor(private helperService: HelperService) { }



  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.meterAnimation = -90;
      this.meterAnimationByArch = -90;
      setTimeout(() => {
        this.meterAnimation = this.getDegreeValue(this.value);
        this.meterAnimationByArch = this.getDegreeValueArc(this.value);
      }, 0);
    }
  }
  ngAfterViewInit() {
    this.meterAnimation = -90;
    this.meterAnimationByArch = -90;
    setTimeout(() => {
      this.meterAnimation = this.getDegreeValue(this.value);
      this.meterAnimationByArch = this.getDegreeValueArc(this.value);
    }, 0);

  }

  navigateSubType(path) {

    this.helperService.routeNavigate('foundation/' + path);
  }

  getRoundValue(value){
    return value > 0 ? Math.round(value) : 0;
  }

  getDegreeValue(value) {
    return value > 0 ? (180 / 100 *  Math.round(value)) -90 : -90;
  }

  getDegreeValueArc(value){
    return value > 0 ? (210 / 100 *  Math.round(value)) -105 : -105;
  }

  back(): void {
      this.goBack.emit();
  }
}
