import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[libScrollIntoView]'
})
export class ScrollIntoViewDirective {
  @Input() libScrollIntoView : 'top' | '';
  @Input() scrollTop : number;
  constructor(private _elementRef: ElementRef) {
    setTimeout(() => {
      if(this.libScrollIntoView == "top" ){
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else{
        //console.log("scroll control", this._elementRef.nativeElement)
        let elem = this._elementRef.nativeElement;
        let pos = elem.style.position;
        let top = elem.style.top;
        elem.style.position = 'relative';
        elem.style.top = '-105px';
        elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
        elem.style.top = top;
        elem.style.position = pos;
       // this._elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: "start" });
      }

    }, 50)
  }

}
