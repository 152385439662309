import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showImg'
})
export class ShowImgPipe implements PipeTransform {

  apiBaseUrl = '';

  constructor() {
    this.apiBaseUrl = 'https://api.bonvista.in/';
  }

  transform(value: any, index: number = 0): any {
    const notFoundImgPath = '';
    try{
      if (value) {
        const files = JSON.parse(value);
        return this.getImgUrl(files[index]);
      }
    }
    catch {

    }

    return notFoundImgPath;
  }


  getImgUrl(file: any) {

    return this.apiBaseUrl + 'ImportFiles/' + file.filePath;
  }

}
