import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppInjector } from '../../../app-injector.service';
import { CommonDatasService, LoginsService } from '../../core/service';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-mf-explore-type',
  templateUrl: './mf-explore-type.component.html',
  styleUrls: ['./mf-explore-type.component.scss'],
})
export class MfExploreTypeComponent implements OnInit {
  gravytFunds = [];

  constructor(private helperService: HelperService,
    private commonDatasService: CommonDatasService,) {

  }

  ngOnInit() {
    this.loadGravytFunds();
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    items: this.helperService.isMobile ? 3 : 5,
    nav: true,
    // startPosition: 4,
    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
  }

  // loadGravytFunds(): void {
  //   this.gravytFunds = [
  //     {
  //       id: 1,
  //       imgUrl: 'low-risk.png',
  //       imgText: 'low-risk',
  //       text: 'Low Risk',
  //       value: 'low-risk',
  //       list: [
  //         {
  //           text: "Short Duration",
  //           category: "Short Duration",
  //           group: "Debt"

  //         },
  //         {
  //           text: "Credit Risk",
  //           category: "Credit Risk",
  //           group: "Debt"
  //         },
  //         {
  //           text: "Medium Duration",
  //           category: "Medium Duration",
  //           group: "Debt"
  //         },
  //         {
  //           text: "Corporate Bond",
  //           category: "Corporate Bond",
  //           group: "Debt"
  //         }
  //       ]
  //     },
  //     {
  //       id: 2,
  //       imgUrl: 'grow-cautiously.png',
  //       imgText: 'Grow Cautiously',
  //       text: 'Grow Cautiously',
  //       value: 'grow-cautiously',
  //       list: [
  //         {
  //           text: "Aggressive Allocation",
  //           category: "Aggressive Allocation",
  //           group: "Hybrid"

  //         },
  //         {
  //           text: "Dynamic Asset Allocation",
  //           category: "Dynamic Asset Allocation",
  //           group: "Hybrid"
  //         },
  //         {
  //           text: "Conservative Allocation",
  //           category: "Conservative Allocation",
  //           group: "Hybrid"
  //         }
  //       ]
  //     },
  //     {
  //       id: 3,
  //       imgUrl: 'grow-steady.png',
  //       imgText: 'Grow Steady',
  //       text: 'Grow Steady',
  //       value: 'grow-steady',
  //       list: [
  //         {
  //           text: "Large Cap",
  //           category: "Large-Cap",
  //           group: "Equity"

  //         }
  //       ]
  //     },
  //     {
  //       id: 4,
  //       imgUrl: 'diversity.png',
  //       imgText: 'Diversify',
  //       text: 'Diversify',
  //       value: 'diversify',
  //       list: [
  //         {
  //           text: "Multi Cap",
  //           category: "Multi-Cap",
  //           group: "Equity"
  //         }
  //       ]
  //     },
  //     {
  //       id: 5,
  //       imgUrl: 'grow-fast.png',
  //       imgText: 'Grow Fast',
  //       text: 'Grow Fast',
  //       value: 'grow-fast',
  //       list: [
  //         {
  //           text: "Mid Cap",
  //           category: "Mid-Cap",
  //           group: "Equity"
  //         },
  //         {
  //           text: "Small Cap",
  //           category: "Small-Cap",
  //           group: "Equity"
  //         }
  //       ]
  //     },
  //     {
  //       id: 6,
  //       imgUrl: 'Retirement.png',
  //       imgText: 'Retirement',
  //       text: 'Retirement',
  //       value: 'retirement',
  //       list: [
  //         {
  //           text: "Mid Cap",
  //           category: "Mid-Cap",
  //           group: "Equity"
  //         }
  //       ]
  //     },
  //     {
  //       id: 7,
  //       imgUrl: 'ELSS.png',
  //       imgText: 'Tax Saving',
  //       text: 'Tax Saving',
  //       value: 'tax-saving',
  //       list: [
  //         {
  //           text: "Mid Cap",
  //           category: "Mid-Cap",
  //           group: "Equity"
  //         }
  //       ]
  //     }
  //   ];
  // }


  loadGravytFunds(): void {
    this.commonDatasService.getAll().subscribe((data) => {
      this.gravytFunds = JSON.parse((data as any).mfGravytFunds[0].value);
    });
    // this.gravytFunds = [
    //   {
    //     id: 1,
    //     imgUrl: 'grow-fast.svg',
    //     imgText: 'Grow Fast',
    //     text: 'Grow <br/> Fast',
    //     value: 'grow-fast',
    //     list: [
    //       {
    //         text: "Mid Cap",
    //         category: "Mid-Cap",
    //         group: "Equity"
    //       },
    //       {
    //         text: "Small Cap",
    //         category: "Small-Cap",
    //         group: "Equity"
    //       }
    //     ]
    //   },
    //   {
    //     id: 2,
    //     imgUrl: 'tax-saving.svg',
    //     imgText: 'Tax Saving',
    //     text: 'Tax <br/> Saving',
    //     value: 'tax-saving',
    //     list: [
    //       {
    //         text: "Mid Cap",
    //         category: "Mid-Cap",
    //         group: "Equity"
    //       },
    //       {
    //         text: "Small Cap",
    //         category: "Small-Cap",
    //         group: "Equity"
    //       }
    //     ]
    //   },
    //   {
    //     id: 3,
    //     imgUrl: 'grow-cautiously.svg',
    //     imgText: 'Grow Cautiously',
    //     text: 'Grow <br/> Cautiously',
    //     value: 'grow-cautiously',
    //     list: [
    //       {
    //         text: "Aggressive Allocation",
    //         category: "Aggressive Allocation",
    //         group: "Hybrid"

    //       },
    //       {
    //         text: "Balanced Allocation",
    //         category: "Balanced Allocation",
    //         group: "Hybrid"
    //       },
    //       {
    //         text: "Dynamic Asset Allocation",
    //         category: "Dynamic Asset Allocation",
    //         group: "Hybrid"
    //       },
    //       {
    //         text: "Conservative Allocation",
    //         category: "Conservative Allocation",
    //         group: "Hybrid"
    //       }
    //     ]
    //   },
    //   {
    //     id: 4,
    //     imgUrl: 'grow-steady.svg',
    //     imgText: 'Grow Steady',
    //     text: 'Grow <br/> Steady',
    //     value: 'grow-steady',
    //     list: [
    //       {
    //         text: "Large Cap",
    //         category: "Large-Cap",
    //         group: "Equity"

    //       }
    //     ]
    //   },
    //   {
    //     id: 5,
    //     imgUrl: 'low-risk.svg',
    //     imgText: 'low-risk',
    //     text: 'Low Risk',
    //     value: 'low-risk',
    //     list: [
    //       {
    //         text: "Short Duration",
    //         category: "Short Duration",
    //         group: "Debt"

    //       },
    //       {
    //         text: "Credit Risk",
    //         category: "Credit Risk",
    //         group: "Debt"
    //       },
    //       {
    //         text: "Medium Duration",
    //         category: "Medium Duration",
    //         group: "Debt"
    //       },
    //       {
    //         text: "Corporate Bond",
    //         category: "Corporate Bond",
    //         group: "Debt"
    //       }
    //     ]
    //   },
    //   {
    //     id: 6,
    //     imgUrl: 'international.svg',
    //     imgText: 'International',
    //     text: 'International',
    //     value: 'international',
    //     list: [
    //       {
    //         text: "Mid Cap",
    //         category: "Mid-Cap",
    //         group: "Equity"
    //       },
    //       {
    //         text: "Small Cap",
    //         category: "Small-Cap",
    //         group: "Equity"
    //       }
    //     ]
    //   },
    //   {
    //     id: 7,
    //     imgUrl: 'retirement.svg',
    //     imgText: 'Retirement',
    //     text: 'Retirement',
    //     value: 'retirement',
    //     list: [
    //       {
    //         text: "Mid Cap",
    //         category: "Mid-Cap",
    //         group: "Equity"
    //       },
    //       {
    //         text: "Small Cap",
    //         category: "Small-Cap",
    //         group: "Equity"
    //       }
    //     ]
    //   },
    //   {
    //     id: 8,
    //     imgUrl: 'diversity.svg',
    //     imgText: 'Diversify',
    //     text: 'Diversify',
    //     value: 'diversify',
    //     list: [
    //       {
    //         text: "Multi Cap",
    //         category: "Multi-Cap",
    //         group: "Equity"
    //       }
    //     ]
    //   }
    // ];
  }
  showFunds() {
    this.helperService.routeNavigate('funds');
  }
  navigate(type) {
    this.helperService.setRouteData({ type: type.value, recommended: true, data: type });

    //this.helperService.routeNavigate('mfTop');

    // this.helperService.routeNavigate('funds#type:'+ type.value);

    this.helperService.routeNavigate('funds');
    // if(setHistory){
    //   this.helperService.setHistoryBackDataType(type.value);
    //  }
  }

}
