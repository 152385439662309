import { Injectable } from '@angular/core';
import { FeedMfDetailMaster } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class FeedMfDetailMastersService extends BaseService<FeedMfDetailMaster> {
    constructor() {
        super('FeedMfDetailMaster');
    }
}
